import React, { Component } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  TextInput,
  ImageBackground,
  TouchableOpacity,
  AsyncStorage,
  ActivityIndicator,
  ScrollView,
  Alert,
  Modal,
  TouchableHighlight
} from 'react-native';
import {  Button, CheckBox, Avatar } from 'react-native-elements'
import UserComponent from '../../../Components/UserComponent'
import UserDetail from '../../../Components/UserDetail'
import CustomButton from '../../../Components/CustomButton'
import ACDfooter from '../../../Components/ACDfooter'
import global from '../../../Styles/global';
import DatePicker from 'react-native-datepicker'
import * as Permissions from 'expo-permissions';
import * as ImagePicker from 'expo-image-picker';
import Constants from 'expo-constants';
import * as DocumentPicker from 'expo-document-picker';
import AlertComponent from '../../../Components/AlertComponent'
import {
  ActionSheetProvider,
  connectActionSheet,
  ActionSheetProps,
} from '@expo/react-native-action-sheet';

export default class ACDoverview4 extends Component {
  constructor(props) {
    super(props);

    this.state={
      UserComponent:<ActivityIndicator size="small" color="#9126fb" />,
    data:'',
    image:"https://res.cloudinary.com/hqepnl8tg/image/upload/v1571642768/cam_pnlffd.png",
    modalVisible: false,
    alertStatus:false
  }

  global.jsonData.forEach((i,idx,x)=>{
    if (i.title == 'ACD Overview - 4') {
      this.jsonData = i
    }
  })
  if(global.userData.acd_images[0] !== null && global.userData.acd_images.length > 0){
    console.log("check length ::",global.userData.acd_images)
    this.props.navigation.navigate("ACDoverview6", {image:global.userData.acd_images[0]})

  }
  this.getLocalKeys()
}

componentDidMount() {
  this.getPermissionAsync();
}

getPermissionAsync = async () => {
  if (Constants.platform.ios) {

    const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL, Permissions.CAMERA);
    if (status !== 'granted') {
      alert('Sorry, we need camera roll permissions to make this work!');
    }
  }
}

getLocalKeys = async () =>{
  const value = await AsyncStorage.getItem('@UserData');
  if (value !== null) {
    var data = JSON.parse(value)
    console.log("UserData :: ", data);
    if (data.accountStatus == "pending") {
      this.props.navigation.navigate('SignedUp', {comingFrom:"login", data:data});
    }
    else {
      this.setState({data:data})

      this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})
    }
  }
}

ACDfilledout = () =>{
  console.log("ACDfilledout");
}

handleNext = () => {
  // this.props.navigation.navigate("ACDoverview3")
}

pickImage = async () => {
  console.log("Alert");
  if (Platform.OS !== 'web') {
      this.setState({alertStatus:true})
  }
  else {
    this.uploadpdfFile()
  }
}
uploadpdfFile = async () => {
  const file = await DocumentPicker.getDocumentAsync();
  if (file.type === 'success') {
    console.warn("File content",file);
    
    let extension = file.name.substring(
      file.name.lastIndexOf(".") + 1)
      if(
        extension === "pdf" ||
        extension === "jpg" ||
        extension === "jpeg" ||
        extension === "png"){
        this.setState({ image:file.uri ,alertStatus:false});
        this.props.navigation.navigate("ACDoverview5", {image:file.uri,name:file.name})    
      }
      else{
        alert("Sorry this file format is not acceptable")
      }
  } else if (file.type === 'cancel') {
    this.setState({alertStatus:true})
    console.warn('Select canceled');
  } else {
    console.warn(`Some other type ${file.type}`);
  }
}
uploadFromGallery = async () => {
  let result = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.All,
    allowsEditing: true,
    // aspect: [4, 3],
    base64: false

  });

  console.log(result);


  if (!result.cancelled) {
    this.setState({ image:result.uri ,alertStatus:false });

    this.props.navigation.navigate("ACDoverview5", {image:result.uri,name:result.name})

  }

}

uploadFromCamera = async () => {
  console.log("camera pressed")
  let result = await ImagePicker.launchCameraAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.All,
    allowsEditing: true,
    // aspect: [4, 3],
    base64: false

  });

  console.log("camera result ::",result);
  this.formArray(result.uri);

  if (!result.cancelled) {
    this.setState({ image:result.uri ,alertStatus:false });
    this.props.navigation.navigate("ACDoverview5", {image:result.uri,name:result.name})
  }
  else{
    console.log("errror")
  }
}



formArray = async (uri) =>{
  console.log('imag uri', uri);
}

render() {
  return (
    <View style={global.container}>
      <ScrollView style={{width:'100%'}} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center', marginBottom:80}}>
        <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:80}]:[global.ItemsContainerWeb,{minHeight:675}]}>

          {this.state.UserComponent}

          <View style={{width:"100%", alignItems: 'center', justifyContent: 'center'}}>
            <Text style={{fontSize:14, color:"#1D2195", width:"90%", marginBottom:100}}>
              {this.jsonData.description[0]}
            </Text>
            <TouchableOpacity onPress={() => this.pickImage()}>
              <ImageBackground
                source={require('../../../../assets/cam.png')}
                style={{height:160, width:200}}/>
            </TouchableOpacity>


          </View>

        </View>

      </ScrollView>

        {/* <AlertComponent 
        visible={this.state.alertStatus}
        onCameraPress={() => this.uploadFromCamera()}
        onGalleryPress={() => this.uploadFromGallery()}
        onPDFPress={() => this.uploadpdfFile()}
        onCancelPress={()=>{this.setState({alertStatus:false})}}
        /> */}

    </View>
  );

}
}

const styles = StyleSheet.create({
  buttonContainer:{
    alignItems:'center',
    marginTop:50,
    marginBottom:10,
    width:'90%',
    // justifyContent:'center',

  },
  loginButton:{
    borderRadius:5,

  },
  arrow:{
    fontSize: 20,
  },
  inputField:{
    width:'100%',
    flexDirection:'row',
    height:50,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    marginBottom:8,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'
  },
  textInputStyle:{
    height: 40,
    width:'78%',
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',
  },
  textstyle:{
    alignItems:'center',
    justifyContent:'center',
    color:'#1D2195',
    fontSize:12,
    textAlign:'center'
  },
  CheckBoxTitle:{
    color:"#707070",
    fontSize:14,
    fontWeight:"bold",
    width:"90%"
  },
  CheckBoxContainerStyle:{
    backgroundColor:"transparent",
    borderWidth:0,
    width:"100%"
  },
  alertContainer:{
    backgroundColor:'#FFFFFF',
    borderRadius:10,
    paddingVertical:10,
    position:'absolute',
    alignSelf:'center',
    width:'70%',
    shadowColor: "#000",
shadowOffset: {
	width: 0,
	height: 2,
},
shadowOpacity: 0.25,
shadowRadius: 3.84,

elevation: 5,
  },
  seperatorContainer:{
    width:'80%',
    height:1,
    backgroundColor:'#D3D3D3',
    alignSelf:'center'
  },
  alertText:{
    fontWeight:'bold',
    fontSize:18,
    marginBottom:10
  },
  btnContainer:{
    alignItems:'center',
    paddingTop:10
  },
  buttonStyle:{
    paddingVertical:12,
  }
})
