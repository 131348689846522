import React, { Component } from 'react';
import { View,Text,StyleSheet, AsyncStorage,ScrollView, TextInput, Platform, ActivityIndicator, TouchableOpacity, Dimensions, Image } from 'react-native';
import global from "../../../Styles/global";
import UserComponent from '../UserComponent'
import {Button, Icon} from 'react-native-elements'
import CustomMainScreenItem from '../CustomMainScreenItem';
import PurpleCircle from '../PurpleCircle';
import { NavigationEvents } from 'react-navigation';
import CustomButton from '../CustomButton'
import ACDfooter from '../ACDfooter';
import {  CheckBox } from 'react-native-elements'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import DatePicker from 'react-native-datepicker'


export default class AppointingSubstitutePreference extends Component {
  constructor(){
    super()
    this.state = {
      search:'',
      winHeight:Dimensions.get("window").height,

      UserComponent:<ActivityIndicator size="small" color="#9126fb" />
  }
  // require('../../assets/Profile.png')
  console.log('global.userData in advance_care_statement', global.userData.userData.acd);
  this.jsonUserMain = global.userData.userData.acd.appointing_substitute_decision_makers.decisions_made
  this.jsonUser = global.userData.userData.acd.appointing_substitute_decision_makers.decisions_made

  global.jsonData.forEach((i,idx,x)=>{
    if (i.title == "North Substitute decision-Maker 3-1") {
      this.jsonData = i
    }
  })
  this.getLocalKeys()
}

getLocalKeys = async () =>{
  const value = await AsyncStorage.getItem('@UserData');
  if (value !== null) {
    var data = JSON.parse(value)
    console.log("UserData :: ", data);


    this.setState({userName:data.name})
    this.setState({userAddress:data.address})
    console.log('====================================');
    console.log("Data::::::", data);
    console.log('====================================');
    this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})

    console.log('====================================');
    console.log("Data:::::: after setstate", data);
    console.log('====================================');

    this.setValues()

  }
}

componentDidMount(){
  this.setValues()
}

setValues =()=>{

  console.log('this.jsonUser', this.jsonUser);
  this.setState(this.jsonUser)
  this.setState({checkbox1:this.jsonUser.checkBox1.isChecked, checkbox2:this.jsonUser.checkBox2.isChecked, checkbox3:this.jsonUser.checkBox3.isChecked})

  setTimeout( () => {
    console.log('this.state', this.state);

  }, 100);
}

navigateProfile = () =>{
  // console.log('in UploadImage');
  // this.props.navigation.navigate("UploadImage")
}
onWillFocus = ()=>{
  console.log('in onWillFocus');

}

handlelogout = () =>{
  AsyncStorage.clear()
  this.props.navigation.navigate("Login")
}
handleacd= () =>{
  this.props.navigation.navigate("AcdHome")
}
handleItemPress= ()=>{
  this.props.navigation.navigate('AcdSignUp')
}

componentWillMount(){
  Dimensions.addEventListener("change", this.handler);
}

componentWillUnmount(){
  Dimensions.removeEventListener("change", this.handler);
}
handler =(e) =>{
  console.log('eee', e);
  this.setState({winHeight: e.window.height})
}
UploadImage = () =>{
  this.props.navigation.navigate("UploadImage")

}
handleAcdOverview = () =>{
  this.props.navigation.navigate("AdvanceCare1")

}

handle2Next = () =>{
  this.props.navigation.navigate("Witnessing")
}

handleSubstitutionMaker = (e) =>{

  if(this.state.checkbox3 === true){
    if(this.state.other){
      console.log("handle2Next", this.jsonUser);
      this.jsonUser.other = this.state.other
      this.jsonUser.isComplete = true
      console.log('this.jsonUser', this.jsonUser);
      this.jsonUser.checkBox1.isChecked = this.state.checkbox1
      this.jsonUser.checkBox2.isChecked = this.state.checkbox2
      this.jsonUser.checkBox3.isChecked = this.state.checkbox3
      global.userData.userData.acd.appointing_substitute_decision_makers.decisions_made = this.jsonUser
      AsyncStorage.setItem("@unSyncedUserData", JSON.stringify(global.userData) )

      console.log('global.userData.userData.acd.decisions_made[0]', global.userData.userData.acd.appointing_substitute_decision_makers.decisions_made);
      console.log('global.userData.userData.acd', global.userData.userData.acd);

      this.props.navigation.navigate("Witnessing")
    }else{
      alert('Please fill in details')
    }
  }else{
    console.log(this.jsonUser);

    if(this.state.checkbox3 === true || this.state.checkbox2 === true ||this.state.checkbox1 === true){
      this.jsonUser.other = this.state.other
      this.jsonUser.isComplete = true
      this.jsonUser.checkBox1.isChecked = this.state.checkbox1
      this.jsonUser.checkBox2.isChecked = this.state.checkbox2
      this.jsonUser.checkBox3.isChecked = this.state.checkbox3
      global.userData.userData.acd.appointing_substitute_decision_makers.decisions_made = this.jsonUser
      AsyncStorage.setItem("@unSyncedUserData", JSON.stringify(global.userData) )
      this.props.navigation.navigate("Witnessing")
    }else{
      alert('Please Check One')
    }

  }

}

render() {
  return (
    <KeyboardAwareScrollView
      enableOnAndroid
      >
      <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>
        <NavigationEvents
          onWillFocus={() => this.onWillFocus()}
          onDidFocus={payload => console.log('did focus', payload)}
          onWillBlur={payload => console.log('will blur', payload)}
          onDidBlur={payload => console.log('did blur', payload)}
          />
        <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'75%',width:'100%'}]:[{height:'70%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
          <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>

            {this.state.UserComponent}

            <View style={{width:"90%", alignItems: 'center', justifyContent: 'center'}}>

              <View style={{paddingTop: 10, display:"flex", flex:1, width:'100%',minHeight:30, flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                <Text style={{
                    color:"#1D2195", fontWeight:'bold',fontSize:14, width:'90%'}}>
                    {this.jsonData.instructions[0]}

                  </Text>
                </View>

                <CheckBox
                  title={this.jsonData.instructions[1]}
                  textStyle={styles.CheckBoxTitle}
                  size={38}
                  containerStyle={styles.CheckBoxContainerStyle}
                  checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_pressed.png')} />}
                  uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_notpressed.png')} />}
                  checked={this.state.checkbox1}
                  onPress={() => this.setState({checkbox1: !this.state.checkbox1, checkbox3:false, checkbox2:false})}
                  />

                <CheckBox
                  title={this.jsonData.instructions[2]}

                  textStyle={styles.CheckBoxTitle}
                  size={38}
                  containerStyle={styles.CheckBoxContainerStyle}
                  checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_pressed.png')} />}
                  uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_notpressed.png')} />}
                  checked={this.state.checkbox2}
                  onPress={() => this.setState({checkbox2: !this.state.checkbox2, checkbox3:false, checkbox1:false})}
                  />

                <CheckBox
                  title={this.jsonData.instructions[3]}
                  textStyle={styles.CheckBoxTitle}
                  size={38}
                  containerStyle={styles.CheckBoxContainerStyle}
                  checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_pressed.png')} />}
                  uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_notpressed.png')} />}
                  checked={this.state.checkbox3}
                  onPress={() => this.setState({checkbox3: !this.state.checkbox3, checkbox2:false, checkbox1:false})}
                  />

                <View style={{  minHeight:200 , borderRadius:5,width:'90%',marginTop:20, marginBottom:20 ,borderColor: '#1D2195',borderWidth: 1, paddingTop:5}}>
                  <TextInput
maxLength={(this.jsonData)?(this.jsonData.wordLimit != undefined) ? this.jsonData.wordLimit : 999:null}
                    multiline
                    onChangeText={(other) => {this.setState({other})}}
                    value={this.state.other}
                    style={{paddingLeft:10, paddingTop:4, paddingRight:10, minHeight:200, textAlignVertical: "top"}}
                    />
                </View>

              </View>
            </View>

          </ScrollView>
          <ScrollView scrollEnabled={false} style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'25%', width:'100%'}]:[{height:'30%', width:'100%', maxWidth:800, minWidth:800}]}
            contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
            <CustomButton title = "➔" navigation= {this.props.navigation} onPress = {this.handleSubstitutionMaker}/>
              <ACDfooter title="D" marginLeft="75%" />

          </ScrollView>
        </View>
      </KeyboardAwareScrollView>
    );
  }
}



const styles = StyleSheet.create({
  container: {
    flex:1,
    width:'100%',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor:'#501D95',
    //     paddingTop:50,
  },


  inputField:{
    width:'90%',
    flexDirection:'row',
    height:50,
    //     marginTop:16,
    marginBottom:16,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'


  },
  textInputStyle:{
    height: 40,
    width:'78%',
    marginBottom:5,
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',

  },
  descriptionText:{
    marginBottom:10,
    marginLeft:15,
    textAlign:'left',
    color:'#1D2195',
  },
  CheckBoxTitle:{
    color:"#707070",
    fontSize:14,
    fontWeight:"bold",
    width:"90%",
  },
  CheckBoxContainerStyle:{
    backgroundColor:"transparent",
    borderWidth:0,
    width:"100%",
    marginBottom:-10,
  },

})
