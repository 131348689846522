import React, { Component } from 'react';
import { View, Text, StyleSheet, Platform, Button,TextInput,TouchableOpacity, ImageBackground, ScrollView , ActivityIndicator, Dimensions, } from 'react-native';
import ACDfooter from '../../../Components/ACDfooter'
import CustomButton from '../../../Components/CustomButton';
import UserComponentProvider from '../../../Components/UserComponentProvider'
import { AsyncStorage } from 'react-native';
import global from "../../../Styles/global"
import axios from 'axios'
import LogBookComponent from '../../../Components/LogBookComponent'
import EventBus from 'eventing-bus'
import * as FileSystem from "expo-file-system";
import * as Print from 'expo-print';
import * as WebBrowser from 'expo-web-browser';
import { WebView } from 'react-native-webview';
// import PDFReader from 'rn-pdf-reader-js'

var moment = require('moment');

export default class LogBookProvider extends Component {
  constructor(){
    super()
    this.state={
      providerText :'You have now applied for a ACDcare account. When your account has been approved you will be able to login.',
      patientText : 'You have now signed up for ACDcare You will receive an email to confirm your email and sign-up.',
      verificationPending: 'Email verification is pending, Confirm your email then you will be able to login.',
      code:'',
      winHeight:Dimensions.get("window").height,

      isLoading:false,
      AcdGenerated:false,
      AcdWitnessedprocess:false,
      ActivityProcessing:true,
      downloading:true,
      winHeight:Dimensions.get("window").height,
      showButton:true,
      UserComponent:<ActivityIndicator size="small" color="#9126fb" />
  }
  this.getLocalKeys()
  this.jsonUserMain = global.userData
}


getLocalKeys = async () =>{
  const value = await AsyncStorage.getItem('@UserData');
  this.token = await AsyncStorage.getItem('@token');
  this.user = await AsyncStorage.getItem('@UserData');
  let userSingle = JSON.parse(this.user)
  if (value !== null) {
    var data = JSON.parse(value)
    console.log("UserData :: ", data);

    this.setState({userName:data.name})
    this.setState({userAddress:data.address})

    this.setState({UserComponent:<UserComponentProvider isComplete={this.props.navigation.state.params.allData.patientsWithAcds[0].userData.acd.isComplete} name={this.props.navigation.state.params.allData.name} address={this.props.navigation.state.params.allData.address} profileImg = {this.props.navigation.state.params.allData.profileImg}/>})


    this.getLogBook()
    // this.getVerifiedLogBook()

  }
}


getLogBook =(e) =>{
  console.log('patientId', this.props.navigation.state.params.patientId);
      this.setState({downloading: true})
      let optionsAxios = ''
      let user = JSON.parse(this.user)

      optionsAxios = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization': JSON.parse(this.token)},
        url: global.url+'/api/getLogBook',
        data: {user_id: this.props.navigation.state.params.patientId}
      }

      console.log(" logbook Options axios ", optionsAxios );
      axios(optionsAxios)
      .then((response)=> {
        this.setState({downloading:false})

        console.log("/logbook Acd Response recieved ", response);
        this.setState({ActivityProcessing: false})

        if(response.data.success){
          // if(response.data.result[0].acdPdfCreated.length !== 0 ){
          //   this.setState({AcdGenerated:true})
          // }
          //
          // if(response.data.result[0].acdPdfWitnessed.length !== 0 ){
          //   this.setState({AcdWitnessedprocess:true})
          // }


          if(response.data.acdCreated.length > 0){
            let acdCrr = response.data.acdCreated
            let reversedArray = acdCrr
              this.setState({AcdGenerated:true})

            reversedArray.map((x,idx,z) =>{
              console.log(reversedArray, x, idx, z);
              if(idx == z.length-1){
                this.getPrintable(x.url)
                let splitted = x.url.split("//");
                console.log('splitted', splitted);
                let joinedString = splitted[0] + '//www.' + splitted[1]
                console.log('splitted', joinedString);

                this.setState({pdf: joinedString})
                this.setState({pdfAndroid: x.url})
                console.log("pdf is ", x);
                console.log("date", moment(x.createdOn).format('DD,MM,YYYY'));
                let lastDate = moment(x.createdOn).format('D');
                // var j = i % 10,
                // k = i % 100;
                if (lastDate == 1 && lastDate != 11) {
                  lastDate = lastDate + "st";
                }
                if (lastDate == 2 && lastDate != 12) {
                  lastDate = lastDate + "nd";
                }
                if (lastDate == 3 && lastDate != 13) {
                  lastDate = lastDate + "rd";
                }else{
                  lastDate = lastDate + "th";
                }
                this.setState({lastDate:lastDate})
                let fullDate = moment(x.createdOn).format('Do') + ' of ' + moment(x.createdOn).format('MMMM YYYY')

                // let fullDate = lastDate + ' of ' + moment(x.createdOn).format('MMMM YYYY')
                this.setState({date:fullDate})

              }

            })
          }

          // global.userData.userData.acd.isComplete = true

          // WebBrowser.openBrowserAsync(response.data.pdf)


        }else{
          console.log(response.data.message);
        }
      })
      .catch((error)=>{
        console.log("error", error);
      })
  //   }
  // });

}

getPrintable = async (e) =>{
  if(Platform.OS === 'ios' || Platform.OS ==='android'){
    console.log('getPrintable');


      this.setState({downloading: true})
      let optionsAxios = ''

      optionsAxios = {
        method: 'POST',
        headers: {'Content-Type': 'application/json' , 'Authorization': JSON.parse(this.token)},
        url: global.url+'/api/getPdfBase64/',
        data:{url: e}
      }

      console.log(" getPdfBase64 Options axios ", optionsAxios );
      axios(optionsAxios)
      .then((response)=> {
        this.setState({downloading:false})

        console.log("/getPdfBase64 Acd Response recieved ", response);
        if(response.data.success){
          this.base64String = response.data.pdfBase64
          this.setState({base64String:true})
        }else{
          console.log(response.data.message);
        }
      })
      .catch((error)=>{
        console.log("error", error);
      })
  }else{
    this.setState({downloading:false})

  }

}

handlePrint =async (e) => {
  console.log("print");
  if(Platform.OS === 'ios' || Platform.OS ==='android'){
    // this.donwloadFile()
    if(this.state.base64String){
      let uri = 'data:application/pdf;base64,' + this.base64String

      if(Platform.OS === 'ios' || Platform.OS ==='android'){
        console.log("print mobile inactive");
        const html = '<html><body><p>test</p></body></html>';
        await Print.printAsync({ uri, width: 595, height: 842 });

      }else{
        console.log("print webactive");
        const html = '<html><body><p>test</p></body></html>';

        await Print.printAsync({ uri, width: 595, height: 842 });
      }
    }else{
      alert('No Pdf To Print')
    }
  }else{
    this.handleDownload()
  }
}


getVerifiedLogBook = async (e) =>{
  this.token = await AsyncStorage.getItem('@token');

  console.log('getVerifiedLogBook');

      this.setState({downloading: true})
      let optionsAxios = ''
      let user = JSON.parse(this.user)

      optionsAxios = {
        method: 'POST',
        headers: {'Content-Type': 'application/json' , 'Authorization': JSON.parse(this.token)},
        url: global.url+'/getVerifiedAcdPdf/' + this.jsonUserMain._id,
      }

      console.log(" getVerifiedLogBook Options axios ", optionsAxios );
      axios(optionsAxios)
      .then((response)=> {
        this.setState({downloading:false})

        console.log("/getVerifiedLogBook Acd Response recieved ", response);
        if(response.data.success){

          // if(response.data.acdPdfWitnessed.length > 0 ){
          //   this.setState({AcdGenerated:true})
          // }
          this.setState({ActivityProcessing: false})
          response.data.result.acdPdfWitnessed.map((x,idx,z) =>{
            if(idx == z.length-1){
              this.setState({pdfWitnessed:x.url})
              console.log("pdf is ", x);
              console.log("date", moment(x.submittedOn).format('DD,MM,YYYY'));
              let lastDate = moment(x.submittedOn).format('D');
              // var j = i % 10,
              // k = i % 100;
              if (lastDate == 1 && lastDate != 11) {
                lastDate = lastDate + "st";
              }
              if (lastDate == 2 && lastDate != 12) {
                lastDate = lastDate + "nd";
              }
              if (lastDate == 3 && lastDate != 13) {
                lastDate = lastDate + "rd";
              }else{
                lastDate = lastDate + "th";
              }
              this.setState({lastDate:lastDate})
              let fullDate = moment(x.createdOn).format('Do') + ' of ' + moment(x.createdOn).format('MMMM YYYY')

              // let fullDate = lastDate + ' of ' + moment(x.createdOn).format('MMMM YYYY')
              this.setState({dateWitnessed:fullDate})

            }

          })

        }else{
          console.log(response.data.message);
        }
      })
      .catch((error)=>{
        console.log("error", error);
      })
  //   }
  // });

}



handleHome = (e) =>{
  this.props.navigation.navigate('AcdHome')
}
handleAcdOverview = (e) =>{
  console.log("overview clicked ");
  this.props.navigation.navigate('ACDOverview18')
}
componentWillMount(){
  Dimensions.addEventListener("change", this.handler);
}

componentWillUnmount(){
  Dimensions.removeEventListener("change", this.handler);
}
handler =(e) =>{
  console.log('eee', e);
  this.setState({winHeight: e.window.height})
}

handleDownloadWitnessed = ()=>{
  console.log("downloading ", FileSystem.documentDirectory);

  if (Platform.OS == 'web' ) {
    WebBrowser.openBrowserAsync(this.state.pdfWitnessed)

  }
  else {

    console.log('in else download');
    WebBrowser.openBrowserAsync(this.state.pdfWitnessed)

  }

}
handleNext = () =>{
  console.log('in handlenex MainScreenProvider');
  this.props.navigation.navigate("MainScreenProvider")
}

handleDownload=(pdf)=>{
  console.log("downloading ", FileSystem.documentDirectory);

  if (Platform.OS == 'web' ) {
    WebBrowser.openBrowserAsync(this.state.pdf)

    // Linking.openURL('https://res.cloudinary.com/qmr-cloudinary/image/upload/v1570443564/ACD_PDF_isyvyx.pdf')
  }
  else {
    // WebBrowser.openBrowserAsync('https://res.cloudinary.com/qmr-cloudinary/image/upload/v1570443564/ACD_PDF_isyvyx.pdf')

    //   FileSystem.downloadAsync(
    //   this.state.pdf,
    //   FileSystem.documentDirectory + "acd.pdf"
    // )
    //   .then(({ uri }) => {
    //     console.log('Finished downloading to ', uri);
    //   })
    //   .catch(error => {
    //     console.error("error occured: ",error);
    //   });
    console.log('in else download');
    WebBrowser.openBrowserAsync(this.state.pdf)

  }

}
handleWebViewNavigationStateChange = newNavState => {
  const { url } = newNavState;


  if (url.includes('expo')) {
    this.setState({showButton:false})
  }
};
onWebLinkPress = async () => {
  const {pdf} = this.state
  await WebBrowser.openBrowserAsync(pdf);
}

render() {
  console.log("props :: ", this.props);

  const {pdf} = this.state;
  return (
    <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>
      <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'80%',width:'100%'}]:[{height:'80%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
        <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>

          <TouchableOpacity style={{width:'100%'}} >
            {this.state.UserComponent}
          </TouchableOpacity>

          <View style={{display:"flex", justifyContent:"space-between", flexDirection:"row",backgroundColor:"#F9F1FF", width:"95%", borderWidth:2, borderColor:"#1D2195" , borderRadius:4 , padding:8}}>
            <View>
              <Text style={{fontSize:14, color:"#1D2195"}} onPress={this.handleDownload}>Download</Text>
              <Text style={{fontSize:14, color:"#1D2195"}} onPress={this.handlePrint}>Print</Text>
            </View>

            <View style={{display:"flex", flexDirection:"row"}}>
              <TouchableOpacity onPress={this.handleDownload}>
                <ImageBackground source={require('../../../../assets/download.png')} style={{width: 43, height:35, marginRight:20}}/>
              </TouchableOpacity>
              <TouchableOpacity onPress={this.handlePrint}>
                <ImageBackground source={require('../../../../assets/printer.png')} style={{width: 37, height:35, marginLeft:5 ,marginRight:15}}/>
              </TouchableOpacity>
            </View>
          </View>

          {
            (this.state.ActivityProcessing || this.state.downloading)
            ?
            <View style={{width:"100%", marginTop: 20}}>

            <ActivityIndicator size="large" color="#9126fb" />
            </View>
            :
            <View style={{width:"100%"}}>
              {
                (this.state.AcdGenerated)
                ?
                <View style={{width:"100%", height:this.state.winHeight-100, paddingBottom:50}}>

                  <Text style={{marginLeft:20, color:'#1D2195', fontSize:20, marginTop:10,  marginBottom:10,fontWeight:'bold'}}>{this.state.date}</Text>
                  <Text style={{marginLeft:20,color:'#7F7F7F', fontSize:25, fontWeight:'700', marginBottom:10}}>ACD Created</Text>

                  {
                    Platform.OS === 'web'
                    ?
                    <View style={{height:"100%", width:"100%", scrolling:"auto"}}>
                    {pdf && 
                    <Button title="Check your PDF" onPress={() => this.onWebLinkPress()} />
                    }
                    </View> 
                    :
                    (Platform.OS === 'android')
                    ?
                    <PDFReader
                      source={{
                        uri: this.state.pdfAndroid,
                      }}
                      />
                    :
                    <WebView
                      originWhitelist={['*']}
                      source={{ uri: this.state.pdf }}
                      style={{flex:1 }}
                      onNavigationStateChange={this.handleWebViewNavigationStateChange}/>
                  }

                </View>
                :
                <Text style={{marginLeft:20, marginTop:10, color:'#7F7F7F', fontSize:25, fontWeight:'700', marginBottom:10}}>No Record Found</Text>
              }

              {/*
                {
                (this.state.AcdWitnessedprocess)
                ?
                <View style={{width:"100%", height:this.state.winHeight-100, paddingBottom:50}}>

                <Text style={{marginLeft:20, color:'#1D2195', fontSize:20, marginBottom:10,fontWeight:'bold'}}>{this.state.dateWitnessed}</Text>
                <Text style={{marginLeft:20,color:'#7F7F7F', fontSize:25, fontWeight:'700', marginBottom:10}}>ACD Witnessed</Text>

                {Platform.OS === 'web'
                ?<iframe src={this.state.pdfWitnessed} height="100%" width="100%"/>
                :<WebView
                originWhitelist={['*']}
                source={{ uri: this.state.pdfWitnessed }}
                style={{flex:1 }}
                onNavigationStateChange={this.handleWebViewNavigationStateChange}/>}
                </View>
                :
                <Text style={styles.textstyle}>
                No ACD Witnessed by user
                </Text>
                }
                */}

              </View>
            }

          </View>

        </ScrollView>
        <ScrollView scrollEnabled={false} style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'20%', width:'100%'}]:[{height:'20%', width:'100%', maxWidth:800, minWidth:800}]}
          contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
          <CustomButton title="HomeProvider" type="multiples" color='#F9F1FF' navigation= {this.props.navigation} onPress = {this.handleNext}/>
        </ScrollView>

      </View>
    );
  }
}



const styles = StyleSheet.create({
  container: {
    flex:1,
    width:'100%',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor:'#501D95',
  },

  inputField:{
    width:'90%',
    flexDirection:'row',
    height:50,
    marginTop:16,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'


  },
  textInputStyle:{
    height: 40,
    width:'78%',
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',
  },

  textstyle:{
    alignItems:'center',
    justifyContent:'center',
    color:'#1D2195',
    fontSize:20,
    textAlign:'center'
  },
  congratulationsweb:{
    width:'75%',
    height:500,
    alignItems:'center',
    // justifyContent:'center',
    backgroundColor:'#F9F1FF',
  },
  congratulations:{
    width:'75%',
    height:'77%',
    alignItems:'center',
    // justifyContent:'center',
    backgroundColor:'#F9F1FF',
  },
})
