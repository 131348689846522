import React, { Component } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  TextInput,
  ImageBackground,
  TouchableOpacity,
  AsyncStorage,
  ActivityIndicator,
  ScrollView,
  Dimensions,
  KeyboardAvoidingView
} from 'react-native';
import {  CheckBox } from 'react-native-elements'
import UserComponent from '../UserComponent'
import UserDetail from '../UserDetail'
import CustomButton from '../CustomButton'
import CustomMainScreenItem from '../CustomMainScreenItem'
import ACDfooter from '../ACDfooter'
import global from '../../../Styles/global';
import DatePicker from 'react-native-datepicker'
import PurpleCircle from '../PurpleCircle';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

export default class AdvanceCare3 extends Component {
  constructor(props) {
    super(props);

    this.state={
      UserComponent:<ActivityIndicator size="small" color="#9126fb" />,
    data:'',
    winHeight:Dimensions.get("window").height,

    name: '',
    email: '',
    address: '',
    birthday:'1999-05-15',
    text:'',
    enableHelpText:false,

    mainSubstitute:false,
    skipSubstitute:false
  }

  console.log('global.userData in advance_care_statement', global.userData.userData.acd);
  this.jsonUserMain = global.userData.userData.acd.advance_care_statement
  this.jsonUser = global.userData.userData.acd.advance_care_statement.questions[4]

  global.jsonData.forEach((i,idx,x)=>{
    if (i.title == 'North What is important to me - A (example) – 6') {
      this.jsonData = i
    }
  })

  this.getLocalKeys()
}


getLocalKeys = async () =>{
  const value = await AsyncStorage.getItem('@UserData');
  if (value !== null) {
    var data = JSON.parse(value)
    this.data = JSON.parse(value)
    console.log("UserData :: ", data);
    if (data.accountStatus == "pending") {
      this.props.navigation.navigate('SignedUp', {comingFrom:"login", data:data});
    }
    else {
      this.setState({data:data})
      this.setState({UserComponent:
        <UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>
      })
      this.setValues()
    }
  }
}

componentDidMount () {
  this.setValues();
}
setValues =()=>{
  console.log('this.jsonUser', this.jsonUser);
  this.setState(this.jsonUser)
  this.setState({checkBox1:this.jsonUser.checkBox1.isChecked, checkBox2:this.jsonUser.checkBox2.isChecked, checkBox3:this.jsonUser.checkBox3.isChecked})

  setTimeout( () => {
    console.log('this.state', this.state);

  }, 100);
}

handlelogout = () =>{
  AsyncStorage.clear()
  this.props.navigation.navigate("Login")
}

handleSubstitutionMaker = (e) =>{
  console.log(this.state.checkBox3, this.state.checkBox1, this.state.checkBox2);
  if(this.state.checkBox3 === true){
    if(this.state.answer){
      console.log("handle2Next", this.jsonUser);
      this.jsonUser.answer = this.state.answer
      this.jsonUser.checkBox3.isChecked = this.state.checkBox3
      this.jsonUser.isComplete = true
      console.log('this.jsonUser', this.jsonUser);
      global.userData.userData.acd.advance_care_statement.isComplete = true

      global.userData.userData.acd.advance_care_statement.questions[4] = this.jsonUser
      AsyncStorage.setItem("@unSyncedUserData", JSON.stringify(global.userData) )

      console.log('global.userData.userData.acd.advance_care_statement.questions', global.userData.userData.acd.advance_care_statement.questions[4]);
      console.log('global.userData.userData.acd', global.userData.userData.acd);

      this.props.navigation.navigate("AdvanceCare4")
    }else{
      alert('Please fill in details')
    }
  }else{
    this.jsonUser.answer = ''
    this.jsonUser.checkBox1.isChecked = this.state.checkBox1
    this.jsonUser.checkBox2.isChecked = this.state.checkBox2
    this.jsonUser.checkBox3.isChecked = this.state.checkBox3

    this.jsonUser.isComplete = true

    global.userData.userData.acd.advance_care_statement.questions[4] = this.jsonUser
    AsyncStorage.setItem("@unSyncedUserData", JSON.stringify(global.userData) )
    this.props.navigation.navigate("AdvanceCare4")
  }
}

handleDetail=(e)=>{
  console.log("this.state :: ", this.state.enableHelpText);
  // if (!this.state.enableHelpText) {
  //   this.setState({answer:this.jsonData.description[0]})
  // }
  // else {
  //   this.setState({answer:global.userData.userData.acd.values_and_wishes.questions[0].answer})
  // }

  this.setState({enableHelpText: !this.state.enableHelpText})

}
componentWillMount(){
  Dimensions.addEventListener("change", this.handler);
}

componentWillUnmount(){
  Dimensions.removeEventListener("change", this.handler);
}
handler =(e) =>{
  console.log('eee', e);
  this.setState({winHeight: e.window.height})
}

handleAcdOverview = () =>{
  this.props.navigation.navigate("AdvanceCare4")
}

render() {
  return (
    <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>

      <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'75%',width:'100%'}]:[{height:'70%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
        {
          (Platform.OS === 'ios' || Platform.OS ==='android')
          ?
          <KeyboardAwareScrollView style={global.scrollContainer}
            enableOnAndroid
            >

            <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>

              {this.state.UserComponent}

              <View style={{width:"90%", alignItems: 'center', justifyContent: 'center'}}>
                <View style={{width:'100%', alignItems:'center', justifyContent:'center'}}>
                  <TouchableOpacity style={{width:'100%'}}>
                    <View style={{width:'100%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
                      <CustomMainScreenItem circleValue='3' color='#1D2195' title = "Where would you like to die/finish up?" fontSize={14} />
                    </View>
                  </TouchableOpacity>
                  <View style={{paddingTop: 10, display:"flex", flex:1, width:'100%',minHeight:30, flexDirection:'row', alignItems:'center', justifyContent:'center'}}>

                    <Text style={{
                        color:"#1D2195", fontWeight:'bold',fontSize:14, width:'90%'}}>
                        {this.jsonData.instructions[0]}

                      </Text>
                    </View>

                    <View style={{paddingTop:15, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
                    </View>
                    <CheckBox
                      title='at home / on country (list location)'
                      containerStyle={styles.CheckBoxContainerStyle}
                      textStyle={styles.CheckBoxTitle}
                      size={38}
                      checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_pressed.png')} />}
                      uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_notpressed.png')} />}
                      checked={this.state.checkBox1}
                      onPress={() => this.setState({checkBox1: !this.state.checkBox1, checkBox2:false, checkBox3:false})}
                      />

                    <CheckBox
                      title='in hospital or hospice.'
                      containerStyle={styles.CheckBoxContainerStyle}
                      textStyle={styles.CheckBoxTitle}
                      size={38}
                      checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_pressed.png')} />}
                      uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_notpressed.png')} />}
                      checked={this.state.checkBox2}
                      onPress={() => this.setState({checkBox2: !this.state.checkBox2, checkBox1:false, checkBox3:false})}
                      />

                    <CheckBox
                      title='other (please provide details).'
                      containerStyle={styles.CheckBoxContainerStyle}
                      textStyle={styles.CheckBoxTitle}
                      size={38}
                      checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_pressed.png')} />}
                      uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_notpressed.png')} />}
                      checked={this.state.checkBox3}
                      onPress={() => this.setState({checkBox3: !this.state.checkBox3, checkBox2:false, checkBox1:false})}
                      />

                    <View style={{  minHeight:200 , borderRadius:5,width:'90%',marginTop:20, marginBottom:20 ,borderColor: '#1D2195',borderWidth: 1, paddingTop:5}}>
                      <TextInput
maxLength={(this.jsonData)?(this.jsonData.wordLimit != undefined) ? this.jsonData.wordLimit : 999:null}
                        multiline
                        onChangeText={(answer) => {this.setState({answer})}}
                        value={this.state.answer}
                        style={{paddingLeft:10, paddingTop:4, paddingRight:10, minHeight:200, textAlignVertical: "top"}}
                        />
                    </View>

                  </View>
                </View>
              </View>
            </KeyboardAwareScrollView>
            :
            <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>

              {this.state.UserComponent}

              <View style={{width:"90%", alignItems: 'center', justifyContent: 'center'}}>
                <View style={{width:'100%', alignItems:'center', justifyContent:'center'}}>
                  <TouchableOpacity style={{width:'100%'}}>
                    <View style={{width:'100%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
                      <CustomMainScreenItem circleValue='3' color='#1D2195' title = "Where would you like to die/finish up?" fontSize={14} />
                    </View>
                  </TouchableOpacity>
                  <View style={{paddingTop: 10, display:"flex", flex:1, width:'100%',minHeight:30, flexDirection:'row', alignItems:'center', justifyContent:'center'}}>

                    <Text style={{
                        color:"#1D2195", fontWeight:'bold',fontSize:14, width:'90%'}}>
                        {this.jsonData.instructions[0]}

                      </Text>
                    </View>

                    <View style={{paddingTop:15, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
                    </View>
                    <CheckBox
                      title='at home / on country (list location)'
                      containerStyle={styles.CheckBoxContainerStyle}
                      textStyle={styles.CheckBoxTitle}
                      size={38}
                      checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_pressed.png')} />}
                      uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_notpressed.png')} />}
                      checked={this.state.checkBox1}
                      onPress={() => this.setState({checkBox1: !this.state.checkBox1, checkBox2:false, checkBox3:false})}
                      />

                    <CheckBox
                      title='in hospital or hospice.'
                      containerStyle={styles.CheckBoxContainerStyle}
                      textStyle={styles.CheckBoxTitle}
                      size={38}
                      checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_pressed.png')} />}
                      uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_notpressed.png')} />}
                      checked={this.state.checkBox2}
                      onPress={() => this.setState({checkBox2: !this.state.checkBox2, checkBox1:false, checkBox3:false})}
                      />

                    <CheckBox
                      title='other (please provide details).'
                      containerStyle={styles.CheckBoxContainerStyle}
                      textStyle={styles.CheckBoxTitle}
                      size={38}
                      checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_pressed.png')} />}
                      uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_notpressed.png')} />}
                      checked={this.state.checkBox3}
                      onPress={() => this.setState({checkBox3: !this.state.checkBox3, checkBox2:false, checkBox1:false})}
                      />

                    <View style={{  minHeight:200 , borderRadius:5,width:'90%',marginTop:20, marginBottom:20 ,borderColor: '#1D2195',borderWidth: 1, paddingTop:5}}>
                      <TextInput
maxLength={(this.jsonData)?(this.jsonData.wordLimit != undefined) ? this.jsonData.wordLimit : 999:null}
                        multiline
                        onChangeText={(answer) => {this.setState({answer})}}
                        value={this.state.answer}
                        style={{paddingLeft:10, paddingTop:4, paddingRight:10, minHeight:200, textAlignVertical: "top"}}
                        />
                    </View>

                  </View>
                </View>
              </View>
            }


          </ScrollView>

          <ScrollView scrollEnabled={false} style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'25%', width:'100%'}]:[{height:'30%', width:'100%', maxWidth:800, minWidth:800}]}
            contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
            <CustomButton title = "➔" navigation= {this.props.navigation} onPress = {this.handleSubstitutionMaker}/>
            <ACDfooter  title="B" marginLeft="25%" />
          </ScrollView>

        </View>

      );

    }
  }

  const styles = StyleSheet.create({

    loginButton:{
      borderRadius:5,

    },
    arrow:{
      fontSize: 20,
    },
    inputField:{
      width:'100%',
      flexDirection:'row',
      height:50,
      alignItems:'center',
      paddingLeft:10,
      paddingRight:10,
      marginBottom:8,
      justifyContent:'space-between',
      borderWidth:.5,
      borderColor:'#1D2195'
    },
    textInputStyle:{
      height: 40,
      width:'78%',
      alignSelf:'flex-end',
      // outline:'none',
      borderColor:'transparent',
    },
    textstyle:{
      alignItems:'center',
      justifyContent:'center',
      color:'#1D2195',
      fontSize:12,
      textAlign:'center'
    },
    CheckBoxTitle:{
      color:"#1D2195",
      fontSize:14,
      fontWeight:"bold",
      width:"90%"
    },
    CheckBoxContainerStyle:{
      backgroundColor:"transparent",
      borderWidth:0,
      width:"100%"
    },
    descriptionText:{
      marginBottom:10,
      marginLeft:15,
      textAlign:'left',
      color:'#1D2195',
    },


  })
