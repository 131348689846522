import React, { Component } from 'react';
import { View, Text, StyleSheet, Platform, TextInput,TouchableOpacity, ImageBackground, ScrollView , ActivityIndicator} from 'react-native';
import ACDfooter from '../../../Components/ACDfooter'
import CustomButton from '../../../Components/CustomButton';
import UserComponent from '../../../Components/UserComponent'
import { AsyncStorage } from 'react-native';
import global from "../../../Styles/global"
import axios from 'axios'
import EventBus from 'eventing-bus'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

export default class PopOver5Northern extends Component {
  constructor(){
    super()
    this.state={
      providerText :'You have now applied for a ACDcare account. When your account has been approved you will be able to login.',
      patientText : 'You have now signed up for ACDcare You will receive an email to confirm your email and sign-up.',
      verificationPending: 'Email verification is pending, Confirm your email then you will be able to login.',
      code:'',
      isLoading:false,
      UserComponent:<ActivityIndicator size="small" color="#9126fb" />
  }
  this.getLocalKeys()
}


getLocalKeys = async () =>{
  const value = await AsyncStorage.getItem('@UserData');
  if (value !== null) {
    var data = JSON.parse(value)
    console.log("UserData :: ", data);


    this.setState({userName:data.name})
    this.setState({userAddress:data.address})

    this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})
  }
}

handleAcdOverview = (e) =>{
  console.log("overview clicked ");
  this.props.navigation.navigate('ACDOverview18Northern')
}


render() {
  console.log("props :: ", this.props);
  return (

    <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>
      <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'75%',width:'100%'}]:[{height:'70%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
        <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>

          <TouchableOpacity style={{width:'100%', marginTop:13}} >
            {this.state.UserComponent}
          </TouchableOpacity>
          <View style = {Platform.OS == 'web'?styles.congratulationsweb: styles.congratulations}>
            <ImageBackground source={require('../../../../assets/CheckboxPurple.png')} style={{width: 80, height: 60, marginTop:100,marginBottom:10}}/>
            <Text style= {{width:'80%',lineHeight: 30, textAlign: 'center',paddingTop:50, fontSize:14, color:'#1D2195'}} > </Text>
              <Text style= {{width:'80%', lineHeight: 30, textAlign: 'center', fontSize:14,marginBottom:30, color:'#1D2195'}} >You have added all pages of your Advanced Care Statement</Text>
          </View>
        </View>
      </ScrollView>

      <ScrollView scrollEnabled={false} style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'25%', width:'100%'}]:[{height:'30%', width:'100%', maxWidth:800, minWidth:800}]}
        contentContainerStyle={[global.bottom, {width:'100%', alignItems:'center', justifyContent:'center'}]}>

        <CustomButton title = "➔" navigation= {this.props.navigation} onPress = {this.handleAcdOverview}/>
      </ScrollView>
    </View>

  );
}
}



const styles = StyleSheet.create({
  container: {
    flex:1,
    width:'100%',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor:'#501D95',
  },

  inputField:{
    width:'90%',
    flexDirection:'row',
    height:50,
    marginTop:16,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'


  },
  textInputStyle:{
    height: 40,
    width:'78%',
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',
  },

  textstyle:{
    alignItems:'center',
    justifyContent:'center',
    color:'#1D2195',
    fontSize:12,
    textAlign:'center'
  },
  congratulationsweb:{
    width:'75%',
    minHeight:350,
    alignItems:'center',
    // justifyContent:'center',
    backgroundColor:'#F9F1FF',
    marginBottom:10
  },
  congratulations:{
    width:'75%',
    height:'77%',
    alignItems:'center',
    // justifyContent:'center',
    backgroundColor:'#F9F1FF',
    marginBottom:10

  },
})
