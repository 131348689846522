import React, { Component } from 'react';
import { View, Text, StyleSheet, Platform, TextInput,TouchableOpacity, ImageBackground, ScrollView , ActivityIndicator, Dimensions} from 'react-native';
import ACDfooter from '../../../Components/ACDfooter'
import CustomButton from '../../../Components/CustomButton';
import UserComponent from '../../../Components/UserComponent'
import { AsyncStorage } from 'react-native';
import global from "../../../Styles/global"
import axios from 'axios'
import EventBus from 'eventing-bus'

export default class PopOver7 extends Component {
  constructor(){
    super()
    this.state={
      providerText :'You have now applied for a ACDcare account. When your account has been approved you will be able to login.',
      patientText : 'You have now signed up for ACDcare You will receive an email to confirm your email and sign-up.',
      verificationPending: 'Email verification is pending, Confirm your email then you will be able to login.',
      code:'',
      isLoading:false,
      winHeight:Dimensions.get("window").height,

      UserComponent:<ActivityIndicator size="small" color="#9126fb" />
    }
        this.getLocalKeys()
  }


  getLocalKeys = async () =>{
        const value = await AsyncStorage.getItem('@UserData');
        if (value !== null) {
          var data = JSON.parse(value)
          console.log("UserData :: ", data);


            this.setState({userName:data.name})
            this.setState({userAddress:data.address})

            this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})
          }
        }


//   submitCode = () => {
//     if (this.state.code == '' || this.state.code == undefined) {
//       alert("Verification Code required")
//     }
//     else {
//       this.setState({isLoading:true})
//       var optionsAxios = {
//         method: 'GET',
//         headers: {'Content-Type': 'application/json'},
//         url: global.url+'/verifyUser/'+this.props.navigation.state.params.data._id+'/'+this.state.code,
//       }
//       console.log("axios options :: ", optionsAxios);
//       axios(optionsAxios)
//       .then((response)=> {
//         this.setState({isLoading:false})
//         console.log("Response :: ",response.status ,response.data);
//         // if (response.status == 200) {
//           EventBus.publish("verificationCode", response.data.code )
//         // }
//       })
//       .catch((err)=>{
//         this.setState({isLoading:false})
//         if(err.response){
//           console.log("error :: ", err.response);
//           alert(err.response.data.message)
//         }else{
//           console.log("no error")
//         }

//         // alert(err.response.data.message)
//       })
//     }

    // AsyncStorage.clear()
    // this.props.navigation.navigate("Login")
    // if (this.state.code == '' || this.state.code == undefined || this.state.code == null) {
    //   console.log("do nothing");
    // }
    // else {
    //   this.props.navigation.navigate('MainScreen')
    // }
//   }

handleAcdOverview = (e) =>{
  console.log("overview clicked ");
  this.props.navigation.navigate('ACDOverview18')
}

componentWillMount(){
  Dimensions.addEventListener("change", this.handler);
}

componentWillUnmount(){
  Dimensions.removeEventListener("change", this.handler);
}
handler =(e) =>{
  console.log('eee', e);
  this.setState({winHeight: e.window.height})
}


  render() {
    console.log("props :: ", this.props);
    return (
      <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>
        <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'75%',width:'100%'}]:[{height:'70%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
          <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>

          <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleacd()}>
            {this.state.UserComponent}
            </TouchableOpacity>
              <View style = {Platform.OS == 'web'?styles.congratulationsweb: styles.congratulations}>
                <ImageBackground source={require('../../../../assets/CheckboxPurple.png')} style={{width: 80, height: 60, marginTop:100,marginBottom:10}}/>
                <Text style= {{width:'80%',lineHeight: 30, textAlign: 'center',paddingTop:50, fontSize:14, color:'#1D2195'}} > </Text>
                <Text style= {{width:'80%', lineHeight: 30, textAlign: 'center', fontSize:14,marginBottom:30, color:'#1D2195'}} >Your ACD is now uploaded to My Health Record</Text>
              </View>
              {/* :<View style = {Platform.OS == 'web'?styles.congratulationsweb: styles.congratulations}>
                <ImageBackground source={require('../../../assets/CheckboxPurple.png')} style={{width: 80, height: 60, marginTop:100,marginBottom:10}}/>
                <Text style= {{width:'80%',lineHeight: 30, textAlign: 'center',paddingTop:50, fontSize:14, color:'#1D2195'}} >Congratulations </Text>
                <Text style= {{width:'80%', lineHeight: 30, textAlign: 'center', fontSize:14,marginBottom:30, color:'#1D2195'}} >{this.state.providerText}</Text>

                  <Text style= {{width:'90%',fontWeight:"bold" ,lineHeight:0, textAlign: 'left', fontSize:14,marginTop:30, color:'#1D2195'}}>Enter Verification Code: </Text>
                  <View style = {[styles.inputField,{marginBottom:20}]}>
                    <TextInput
maxLength={(this.jsonData)?(this.jsonData.wordLimit != undefined) ? this.jsonData.wordLimit : 999:null}
                      editable
                      style={styles.textInputStyle}
                      onChangeText={code => this.setState({ code })}
                      value={this.state.role}
                      />
                    <Text style= {styles.textstyle}>Code</Text>
                  </View>

              </View> */}
            {/* } */}

          </View>

        </ScrollView>
        <ScrollView scrollEnabled={false} style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'25%', width:'100%'}]:[{height:'30%', width:'100%', maxWidth:800, minWidth:800}]}
          contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
            <CustomButton title = "➔" navigation= {this.props.navigation} onPress = {this.handleAcdOverview}/>
          <ACDfooter hidePurple = {true}/>
        </ScrollView>


      </View>
    );
  }
}



const styles = StyleSheet.create({
  container: {
    flex:1,
    width:'100%',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor:'#501D95',
  },

  inputField:{
    width:'90%',
    flexDirection:'row',
    height:50,
    marginTop:16,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'


  },
  textInputStyle:{
    height: 40,
    width:'78%',
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',
  },

  textstyle:{
    alignItems:'center',
    justifyContent:'center',
    color:'#1D2195',
    fontSize:12,
    textAlign:'center'
  },
  congratulationsweb:{
    width:'75%',
    height:500,
    alignItems:'center',
    // justifyContent:'center',
    backgroundColor:'#F9F1FF',
  },
  congratulations:{
    width:'75%',
    height:'77%',
    alignItems:'center',
    // justifyContent:'center',
    backgroundColor:'#F9F1FF',
  },
})
