import React, { Component } from 'react';
import { View, Text, StyleSheet, Platform, TextInput,TouchableOpacity, ImageBackground, ScrollView , ActivityIndicator, Dimensions} from 'react-native';
import ACDfooter from '../../../Components/ACDfooter'
import CustomButton from '../../../Components/CustomButton';
import UserComponent from '../../../Components/UserComponent'
import { AsyncStorage } from 'react-native';
import global from "../../../Styles/global"
import axios from 'axios'
import LogBookComponent from '../../../Components/LogBookComponent'
import EventBus from 'eventing-bus'
import * as FileSystem from "expo-file-system";
import * as Print from 'expo-print';
import * as WebBrowser from 'expo-web-browser';
var moment = require('moment');

export default class ACDOverview11 extends Component {
  constructor(){
    super()
    this.state={
      providerText :'You have now applied for a ACDcare account. When your account has been approved you will be able to login.',
      patientText : 'You have now signed up for ACDcare You will receive an email to confirm your email and sign-up.',
      verificationPending: 'Email verification is pending, Confirm your email then you will be able to login.',
      code:'',
      winHeight:Dimensions.get("window").height,

      isLoading:false,
      AcdGenerated:false,
      AcdWitnessedprocess:false,
      ActivityProcessing:true,
      totalEmpty:false,
      UserComponent:<ActivityIndicator size="small" color="#9126fb" />
  }
  this.getLocalKeys()
  this.jsonUserMain = global.userData
}


getLocalKeys = async () =>{
  const value = await AsyncStorage.getItem('@UserData');
  this.token = await AsyncStorage.getItem('@token');
  this.user = await AsyncStorage.getItem('@UserData');
  let userSingle = JSON.parse(this.user)
  if (value !== null) {
    var data = JSON.parse(value)
    console.log("UserData :: ", data);

    this.setState({userName:data.name})
    this.setState({userName:data.name})
    this.setState({gp:data.gp})

    this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})
    this.getLogBook()
    // this.getVerifiedLogBook()
  }
}


getLogBook =(e) =>{
  let arrayCreated = []
  let arrayWitnessed = []
  let arraySeven = []
  let arrayResource = []
  console.log('in get logbook')
      console.log('in internet access');
      this.setState({downloading: true})
      let optionsAxios = ''
      let user = JSON.parse(this.user)
        optionsAxios = {
          method: 'POST',
          headers: {'Content-Type': 'application/json', 'Authorization': JSON.parse(this.token)},
          url: global.url+'/api/getLogBook',
          // data: {acdId: this.jsonUserMain._id}
        }

        console.log(" logbook Options axios ", optionsAxios );
        axios(optionsAxios)
        .then((response)=> {
          this.setState({downloading:false})

          console.log("/logbook Acd Response recieved ", response);
          this.setState({ActivityProcessing: false})

          if(response.data.success){
            if(response.data.acdCreated ){
              if(response.data.acdCreated.length !== 0 ){
                this.setState({AcdGenerated:true})
              }
              if(response.data.acdCreated.length > 0){
                let reversedCreated =  response.data.acdCreated;
                reversedCreated.map((x,idx,z) =>{
                  this.setState({pdf:x.url})
                  console.log("pdf is ", x);
                  console.log("date", moment(x.createdOn).format('DD,MM,YYYY'));
                  // let lastDate = moment(x.createdOn).format('D');
                  // // var j = i % 10,
                  // // k = i % 100;
                  // if (lastDate == 1 && lastDate != 11) {
                  //   lastDate = lastDate + "st";
                  // }else if (lastDate == 2 && lastDate != 12) {
                  //   lastDate = lastDate + "nd";
                  // }else if (lastDate == 3 && lastDate != 13) {
                  //   lastDate = lastDate + "rd";
                  // }else{
                  //   lastDate = lastDate + "th";
                  // }
                  // this.setState({lastDate:lastDate})
                  // let lastDate = moment(x.createdOn).format('Do');

                  let fullDate = moment(x.createdOn).format('Do') + ' of ' + moment(x.createdOn).format('MMMM YYYY')

                  // let fullDate = lastDate + ' of ' + moment(x.createdOn).format('MMMM YYYY')
                  this.setState({date:fullDate})
                  console.log('idx', idx);
                  if(idx <= 1){
                    arrayCreated.push(<LogBookComponent stepName= 'ACD Created' onPress= {() => this.handleDownload(x.url)} doctorName = {"By " + this.state.userName} date = {fullDate}/>)
                  }
                })
                this.setState({createdAcdLogBook:arrayCreated.reverse()})

              }else{
                this.setState({createdAcdLogBook: <LogBookComponent stepName= 'ACD Created'  doctorName = {"No Record Found"}/>})

                //
                // this.setState({pdf:response.data.acdCreated.url})
                //
                // let lastDate = moment(response.data.acdCreated.createdOn).format('D');
                // // var j = i % 10,
                // // k = i % 100;
                // if (lastDate == 1 && lastDate != 11) {
                //   lastDate = lastDate + "st";
                // }
                // if (lastDate == 2 && lastDate != 12) {
                //   lastDate = lastDate + "nd";
                // }
                // if (lastDate == 3 && lastDate != 13) {
                //   lastDate = lastDate + "rd";
                // }else{
                //   lastDate = lastDate + "th";
                // }
                // this.setState({lastDate:lastDate})
                // let fullDate = lastDate + ' of ' + moment(response.data.acdCreated.createdOn).format('MMMM YYYY')
                // this.setState({date:fullDate})
                // this.setState({AcdGenerated:true})

              }
            }else{
              this.setState({createdAcdLogBook: <LogBookComponent stepName= 'ACD Created'  doctorName = {"No Record Found"}/>})
            }

            if(response.data.acdWitnessed ){
              if(response.data.acdWitnessed.length !== 0 ){
                this.setState({AcdWitnessedprocess:true})
              }
            }
            if(response.data.sevenStepPlan ){
              if(response.data.sevenStepPlan.length > 0 ){

                console.log("7 path Array check ::",response.data.sevenStepPlan)
                let reversedSeven =  response.data.sevenStepPlan.reverse();

                reversedSeven.map((q,qdx,p) =>{
                  this.setState({pdfSeven:q.url})
                  console.log("pdf is fullDateWitnessed", q);
                  console.log("date", moment(q.submittedOn).format('DD,MM,YYYY'));
                  let lastDateSeven = moment(q.submittedOn).format('D');
                  // var j = i % 10,
                  // k = i % 100;
                  if (lastDateSeven === 1 && lastDateSeven != 11) {
                    lastDateSeven = lastDateSeven + "st";
                  } else if (lastDateSeven == 2 && lastDateSeven != 12) {
                    lastDateSeven = lastDateSeven + "nd";
                  }else if (lastDateSeven == 3 && lastDateSeven != 13) {
                    lastDateSeven = lastDateSeven + "rd";
                  }else{
                    lastDateSeven = lastDateSeven + "th";
                  }
                  this.setState({lastDateSeven:lastDateSeven})
                  let fullDateSeven = moment(q.submittedOn).format('Do') + ' of ' + moment(q.submittedOn).format('MMMM YYYY')

                  // let fullDateSeven = lastDateSeven + ' of ' + moment(q.submittedOn).format('MMMM YYYY')
                  this.setState({dateSeven:fullDateSeven})
                  console.log('fullDateSeven', fullDateSeven);
                  if(qdx <= 1){
                    if(q.createdBy){
                      arraySeven.push(<LogBookComponent type= "seven" stepName= '7 Step Pathway Added' onPress= {() => this.handleDownloadSeven(q.url)} doctorName = {q.createdBy} date = {fullDateSeven}/>)

                    }else{
                      arraySeven.push(<LogBookComponent type= "seven" stepName= '7 Step Pathway Added' onPress= {() => this.handleDownloadSeven(q.url)} doctorName = {this.state.gp} date = {fullDateSeven}/>)
                    }
                  }

                })
                console.log("7 path check ::",arraySeven)
                this.setState({sevenLogBook:arraySeven})

              }else{
                this.setState({sevenLogBook: <LogBookComponent type= "seven" stepName= '7 Step Pathway'  doctorName = {"No Record Found"}/>})
              }
            }else{
              this.setState({sevenLogBook: <LogBookComponent type= "seven" stepName= '7 Step Pathway'  doctorName = {"No Record Found"}/>})
            }
            if(response.data.resources ){
              if(response.data.resources.length > 0 ){
                let reversedResources =  response.data.resources.reverse();

                reversedResources.map((q,qdx,p) =>{
                  this.setState({pdfResource:q.url})
                  console.log("pdf is fullDateResource", q);
                  console.log("date", moment(q.submittedOn).format('DD,MM,YYYY'));
                  let lastDateResources = moment(q.submittedOn).format('D');
                  // var j = i % 10,
                  // k = i % 100;
                  if (lastDateResources == 1 && lastDateResources != 11) {
                    lastDateResources = lastDateResources + "st";
                  } else if (lastDateResources == 2 && lastDateResources != 12) {
                    lastDateResources = lastDateResources + "nd";
                  }else if (lastDateResources == 3 && lastDateResources != 13) {
                    lastDateResources = lastDateResources + "rd";
                  }else{
                    lastDateResources = lastDateResources + "th";
                  }
                  this.setState({lastDateResources:lastDateResources})
                  let fullDateResource = moment(q.submittedOn).format('Do') + ' of ' + moment(q.submittedOn).format('MMMM YYYY')

                  // let fullDateResource = lastDateResources + ' of ' + moment(q.submittedOn).format('MMMM YYYY')
                  this.setState({dateSeven:fullDateResource})
                  console.log('fullDateResource', fullDateResource);
                  arrayResource.push(<LogBookComponent type= "resource" stepName={q.resourceName + " Added"} onPress= {() => this.handleDownloadSeven(q.url)} doctorName = {"By " + this.state.userName} date = {fullDateResource}/>)


                })
                this.setState({resourceLogBook:arrayResource})

              }else{
                this.setState({resourceLogBook: <LogBookComponent type= "resource" stepName= 'Resources'  doctorName = {"No Record Found"}/>})
              }
            }else{
              this.setState({resourceLogBook: <LogBookComponent type= "resource" stepName= 'Resources'  doctorName = {"No Record Found"}/>})
            }

            if(response.data.acdWitnessed ){
              if(response.data.acdWitnessed.length > 0){
                let reversedWitnessed =  response.data.acdWitnessed;

                reversedWitnessed.map((q,qdx,p) =>{
                  this.setState({pdfWitnessed:q.url})
                  console.log("pdf is fullDateWitnessed", q);
                  console.log("date", moment(q.submittedOn).format('DD,MM,YYYY'));
                  let lastDateWitnessed = moment(q.submittedOn).format('D');
                  // var j = i % 10,
                  // k = i % 100;
                  if (lastDateWitnessed == 1 && lastDateWitnessed != 11) {
                    lastDateWitnessed = lastDateWitnessed + "st";
                  } else if (lastDateWitnessed == 2 && lastDateWitnessed != 12) {
                    lastDateWitnessed = lastDateWitnessed + "nd";
                  }else if (lastDateWitnessed == 3 && lastDateWitnessed != 13) {
                    lastDateWitnessed = lastDateWitnessed + "rd";
                  }else{
                    lastDateWitnessed = lastDateWitnessed + "th";
                  }
                  this.setState({lastDateWitnessed:lastDateWitnessed})
                  let fullDateWitnessed = moment(q.submittedOn).format('Do') + ' of ' + moment(q.submittedOn).format('MMMM YYYY')

                  // let fullDateWitnessed = lastDateWitnessed + ' of ' + moment(q.submittedOn).format('MMMM YYYY')
                  this.setState({dateWitnessed:fullDateWitnessed})
                  console.log('fullDateWitnessed', fullDateWitnessed);
                  if(qdx <= 1){
                    arrayWitnessed.push(<LogBookComponent stepName= 'ACD Witnessed' onPress= {() => this.handleDownloadWitnessed(q.url)} doctorName = {"By " + this.state.userName} date = {fullDateWitnessed}/>)
                  }

                })
                this.setState({witnessedAcdLogBook:arrayWitnessed.reverse()})

              }else{
                this.setState({witnessedAcdLogBook: <LogBookComponent stepName= 'ACD Witnessed'  doctorName = {"No Record Found"}/>})

                // this.setState({pdfWitnessed:response.data.acdWitnessed.url})
                // let lastDateWitnessed = moment(response.data.acdWitnessed.submittedOn).format('D');
                // // var j = i % 10,
                // // k = i % 100;
                // if (lastDateWitnessed == 1 && lastDateWitnessed != 11) {
                //   lastDateWitnessed = lastDateWitnessed + "st";
                // }
                // if (lastDateWitnessed == 2 && lastDateWitnessed != 12) {
                //   lastDateWitnessed = lastDateWitnessed + "nd";
                // }
                // if (lastDateWitnessed == 3 && lastDateWitnessed != 13) {
                //   lastDateWitnessed = lastDateWitnessed + "rd";
                // }else{
                //   lastDateWitnessed = lastDateWitnessed + "th";
                // }
                // this.setState({lastDateWitnessed:lastDateWitnessed})
                // let fullDateWitnessed = lastDateWitnessed + ' of ' + moment(response.data.acdWitnessed.submittedOn).format('MMMM YYYY')
                // this.setState({dateWitnessed:fullDateWitnessed})
                // this.setState({AcdGenerated:true})

              }

            }else{
              this.setState({witnessedAcdLogBook: <LogBookComponent stepName= 'ACD Witnessed'  doctorName = {"No Record Found"}/>})
            }


            // global.userData.userData.acd.isComplete = true

            // WebBrowser.openBrowserAsync(response.data.pdf)


          }else{
            console.log(response.data.message);
          }
        })
        .catch((error)=>{
          this.setState({ActivityProcessing: false})
          this.setState({witnessedAcdLogBook: <LogBookComponent stepName= 'ACD Witnessed'  doctorName = {"No Record Found"}/>})
          this.setState({resourceLogBook: <LogBookComponent type= "resource" stepName= 'Resources'  doctorName = {"No Record Found"}/>})
          this.setState({sevenLogBook: <LogBookComponent type= "seven" stepName= '7 Step Pathway'  doctorName = {"No Record Found"}/>})
          this.setState({createdAcdLogBook: <LogBookComponent stepName= 'ACD Created'  doctorName = {"No Record Found"}/>})

          console.log("error", error);
        })
        // this.setState({downloading:false})
        // this.setState({ActivityProcessing: false})
        // this.setState({totalEmpty: true})
        // this.setState({createdAcdLogBook:<Text style={{marginLeft:20,color:'#7F7F7F', fontSize:25, fontWeight:'700', marginBottom:10}}>No Record Found</Text>})


}

getVerifiedLogBook = async (e) =>{
  this.token = await AsyncStorage.getItem('@token');

  console.log('getVerifiedLogBook');


      this.setState({downloading: true})
      let optionsAxios = ''
      let user = JSON.parse(this.user)

      optionsAxios = {
        method: 'POST',
        headers: {'Content-Type': 'application/json' , 'Authorization': JSON.parse(this.token)},
        url: global.url+'/getVerifiedAcdPdf/' + this.jsonUserMain._id,
      }

      console.log(" getVerifiedLogBook Options axios ", optionsAxios );
      axios(optionsAxios)
      .then((response)=> {
        this.setState({downloading:false})

        console.log("/getVerifiedLogBook Acd Response recieved ", response);
        if(response.data.success){

          // if(response.data.acdPdfWitnessed.length > 0 ){
          //   this.setState({AcdGenerated:true})
          // }
          this.setState({ActivityProcessing: false})
          response.data.result.acdPdfWitnessed.map((x,idx,z) =>{
            if(idx == z.length-1){
              this.setState({pdfWitnessed:x.url})
              console.log("pdf is ", x);
              console.log("date", moment(x.submittedOn).format('DD,MM,YYYY'));
              let lastDate = moment(x.submittedOn).format('D');
              // var j = i % 10,
              // k = i % 100;
              if (lastDate == 1 && lastDate != 11) {
                lastDate = lastDate + "st";
              }
              if (lastDate == 2 && lastDate != 12) {
                lastDate = lastDate + "nd";
              }
              if (lastDate == 3 && lastDate != 13) {
                lastDate = lastDate + "rd";
              }else{
                lastDate = lastDate + "th";
              }
              this.setState({lastDate:lastDate})
              let fullDate = moment(x.createdOn).format('Do') + ' of ' + moment(x.createdOn).format('MMMM YYYY')

              // let fullDate = lastDate + ' of ' + moment(x.createdOn).format('MMMM YYYY')
              this.setState({dateWitnessed:fullDate})

            }

          })

        }else{
          this.setState({ActivityProcessing: false})

          console.log(response.data.message);
        }
      })
      .catch((error)=>{
        this.setState({ActivityProcessing: false})

        console.log("error", error);
      })
  //   }
  // });

}



handleHome = (e) =>{
  this.props.navigation.navigate('AcdHome')
}
handleAcdOverview = (e) =>{
  console.log("overview clicked ");
  this.props.navigation.navigate('ACDOverview18')
}
componentWillMount(){
  Dimensions.addEventListener("change", this.handler);
}

componentWillUnmount(){
  Dimensions.removeEventListener("change", this.handler);
}
handler =(e) =>{
  console.log('eee', e);
  this.setState({winHeight: e.window.height})
}

handleDownloadSeven = (pdf) =>{
  console.log("downloading ", FileSystem.documentDirectory);

  if (Platform.OS == 'web' ) {
    WebBrowser.openBrowserAsync(pdf)

  }
  else {

    console.log('in else download');
    WebBrowser.openBrowserAsync(pdf)

  }

}

handleDownloadWitnessed = (pdf)=>{
  console.log("downloading ", FileSystem.documentDirectory);

  if (Platform.OS == 'web' ) {
    WebBrowser.openBrowserAsync(pdf)

  }
  else {

    console.log('in else download');
    WebBrowser.openBrowserAsync(pdf)

  }

}

handleDownload=(pdf)=>{
  console.log("downloading ", FileSystem.documentDirectory);

  if (Platform.OS == 'web' ) {
    WebBrowser.openBrowserAsync(pdf)

    // Linking.openURL('https://res.cloudinary.com/qmr-cloudinary/image/upload/v1570443564/ACD_PDF_isyvyx.pdf')
  }
  else {
    // WebBrowser.openBrowserAsync('https://res.cloudinary.com/qmr-cloudinary/image/upload/v1570443564/ACD_PDF_isyvyx.pdf')

    //   FileSystem.downloadAsync(
    //   this.state.pdf,
    //   FileSystem.documentDirectory + "acd.pdf"
    // )
    //   .then(({ uri }) => {
    //     console.log('Finished downloading to ', uri);
    //   })
    //   .catch(error => {
    //     console.error("error occured: ",error);
    //   });
    console.log('in else download');
    WebBrowser.openBrowserAsync(pdf)

  }

}

render() {
  console.log("props :: ", this.props);
  return (
    <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>
      <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'80%',width:'100%'}]:[{height:'80%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
        <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>


          <TouchableOpacity style={{width:'100%'}} >
            {this.state.UserComponent}
          </TouchableOpacity>

          {
            (this.state.ActivityProcessing)
            ?
            <ActivityIndicator size="large" color="#9126fb" />

            :
            <View style={{width:"100%"}}>

              {
                this.state.totalEmpty == true
                ?
                <View style={{width:"100%"}}>
                  {this.state.createdAcdLogBook}
                </View>
                :
                <View style={{width:"100%"}}>
                  <View style={{backgroundColor: '#F9F1FF', width:'100%',height:40, marginBottom:5, alignItems:'center', flexDirection:'row'}}>
                    <Text style={{color:'#7F7F7F', fontSize:25, fontWeight:'700', marginBottom:4, marginLeft: '4%', textAlign: 'center', justifyContent: 'center'}}>
                      ACD
                    </Text>
                  </View>
                {this.state.createdAcdLogBook}

                {this.state.witnessedAcdLogBook}

                <View style={{backgroundColor: '#f1f8fe', width:'100%',height:40, marginBottom:5, alignItems:'center', flexDirection:'row'}}>
                  <Text style={{color:'#7F7F7F', fontSize:25, fontWeight:'700', marginBottom:4, marginLeft: '4%', textAlign: 'center', justifyContent: 'center'}}>
                    7 Step Pathway
                  </Text>
                </View>
                {this.state.sevenLogBook}
                <View style={{backgroundColor: '#efefef', width:'100%',height:40, marginBottom:5, alignItems:'center', flexDirection:'row'}}>
                  <Text style={{color:'#7F7F7F', fontSize:25, fontWeight:'700', marginBottom:4, marginLeft: '4%', textAlign: 'center', justifyContent: 'center'}}>
                    Resources
                  </Text>
                </View>
                {this.state.resourceLogBook}
              </View>

              }
            </View>


          }

        </View>
      </ScrollView>
      <ScrollView scrollEnabled={false} style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'20%', width:'100%'}]:[{height:'20%', width:'100%', maxWidth:800, minWidth:800}]}
        contentContainerStyle={[global.bottom1, {width:'100%', alignItems:'center', justifyContent:'center'}]}>

        <CustomButton title="Home" fontWeight = "bold" type="multiples" color='#F9F1FF' navigation= {this.props.navigation} onPress = {this.handleHome}/>

      </ScrollView>


    </View>
  );
}
}



const styles = StyleSheet.create({
  container: {
    flex:1,
    width:'100%',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor:'#501D95',
  },

  inputField:{
    width:'90%',
    flexDirection:'row',
    height:50,
    marginTop:16,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'


  },
  textInputStyle:{
    height: 40,
    width:'78%',
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',
  },

  textstyle:{
    // alignItems:'center',
    // justifyContent:'center',
    color:'#1D2195',
    fontSize:15,
    textAlign:'center'
  },
  congratulationsweb:{
    width:'75%',
    height:500,
    alignItems:'center',
    // justifyContent:'center',
    backgroundColor:'#F9F1FF',
  },
  congratulations:{
    width:'75%',
    height:'77%',
    alignItems:'center',
    // justifyContent:'center',
    backgroundColor:'#F9F1FF',
  },
})
