import React,{Component} from 'react';
import {View,Text,TouchableOpacity,StyleSheet,Modal,SafeAreaView} from 'react-native';

export default class AlertComponent extends Component {
    render(){
        return (
          
        <Modal
        transparent={true}
        visible={this.props.visible}
        onRequestClose={this.props.onClose}>
          <SafeAreaView style={styles.safeContainer}>
            <View style={styles.alertContainer}>
            <View style={{alignItems:'center'}}>
            <Text style={styles.alertText}>Alert</Text>
            <Text  style={styles.selectText}>Select option to upload file</Text>
            </View>
            <View style={styles.btnContainer}>
              <TouchableOpacity 
              onPress={this.props.onCameraPress}
              style={styles.buttonStyle}>
                <Text>Open Camera</Text>
              </TouchableOpacity>
              <View style={styles.seperatorContainer} />
              <TouchableOpacity
              onPress={this.props.onGalleryPress}  
              style={styles.buttonStyle}>
                <Text>Upload from Gallery</Text>
              </TouchableOpacity>
              <View style={styles.seperatorContainer} />
              <TouchableOpacity  
              onPress={this.props.onPDFPress}
              style={styles.buttonStyle}>
                <Text>Upload PDF file</Text>
              </TouchableOpacity>
              <View style={styles.seperatorContainer} />
              <TouchableOpacity  
              onPress={this.props.onCancelPress}
              style={styles.buttonStyle}>
                <Text>Cancel</Text>
              </TouchableOpacity>
            </View>
          </View>
          </SafeAreaView>
          </Modal>
        )
    }
}


const styles = StyleSheet.create({
  safeContainer:{
    flex:1,
    alignItems:'center',
    justifyContent:'center'
  },
    alertContainer:{
      backgroundColor:'#FFFFFF',
      borderRadius:10,
      paddingVertical:10,
      position:'absolute',
      alignSelf:'center',
      width:'70%',
      shadowColor: "#000",
  shadowOffset: {
      width: 0,
      height: 2,
  },
  shadowOpacity: 0.25,
  shadowRadius: 3.84,
  
  elevation: 5,
    },
    seperatorContainer:{
      width:'80%',
      height:1,
      backgroundColor:'#D3D3D3',
      alignSelf:'center'
    },
    alertText:{
      fontWeight:'bold',
      fontSize:18,
      marginBottom:10
    },
    btnContainer:{
      alignItems:'center',
      paddingTop:10
    },
    buttonStyle:{
      paddingVertical:12,
      width:'100%',
      alignItems:'center'
    }
  })