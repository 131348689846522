import React, { Component } from 'react';
import { View,Text,StyleSheet, AsyncStorage,ScrollView, TextInput, Platform, ActivityIndicator, TouchableOpacity, Dimensions, ImageBackground } from 'react-native';
import global from '../../../Styles/global';
import UserComponent from '../../../Components/UserComponent'
import {Button, Icon} from 'react-native-elements'
import CustomMainScreenItem from '../../../Components/CustomMainScreenItem';
import PurpleCircle from '../../../Components/PurpleCircle';
import CustomButton from '../../../Components/CustomButton';
import {  CheckBox } from 'react-native-elements'
import ACDfooter from '../../../Components/ACDfooter'
import axios from 'axios'
import ActionSheet from 'react-native-actionsheet'




export default class ACDOverview18 extends Component {
  constructor(){
    super()
    this.state = {
      search:'',
      winHeight:Dimensions.get("window").height,
      activityProcess:false,
      ACDSigned:'#C74252',
      ACDWitnessed:'#C74252',
      ACDSignedCheck:false,
      ACDWitnessedCheck:false,
      userDataId:'',
      UserComponent:<ActivityIndicator size="small" color="#9126fb" />
  }
  global.jsonData.forEach((i,idx,x)=>{
    if (i.title == 'ACD overview - 7') {
      this.jsonData = i
    }
  })
  console.log('global.userData', global.userData);
  this.jsonUser = global.userData.userData
  this.jsonUserMain = global.userData
  this.responseImage = []

  this.getLocalKeys()
  this.uri = ''
  this.count = 0

  this.acdId = global.userData._id
  console.log('this.acdId', global.userData);
}

async componentDidMount(){
const value = await AsyncStorage.getItem('@UserData');
const UserId = JSON.parse(value)

console.log("this userId ::",UserId._id)

this.setState({userDataId : UserId._id})

}
ACDWitnessed = () =>{
  if(this.state.ACDWitnessedCheck === false){
    console.log('in acd witnessed');
    console.log("checking api :::",this.uri.length)
    this.setState({activityProcess: true})
    if(this.uri.length > 0){
      if(Platform.OS === 'ios' || Platform.OS ==='android'){
        if(this.count < this.uri.length){
          // this.ActionSheet.show()
          console.log("here 11")
          this.onActionNonPress()
        }else{
          console.log('in acd witnessed B9DD7A');

          this.setState({ACDWitnessed: '#B9DD7A'})
          this.setState({ACDWitnessedCheck: true})

        }
      }else{
        if(this.count < 6){
          if(this.count < this.uri.length){
            if(this.acdId){
              this.uploadAcdImages(this.uri, this.count, this.acdId)
            }else{
              alert('ACD not created yet')
            }
            // this.uploadAcdImages(this.uri, this.count, this.acdId)
            this.count++
          }else{
            console.log('in acd witnessed B9DD7A');

            this.setState({ACDWitnessed: '#B9DD7A'})
            this.setState({ACDWitnessedCheck: true})
          }

        }else{
          console.log('in acd witnessed B9DD7A');

          this.setState({ACDWitnessed: '#B9DD7A'})
          this.setState({ACDWitnessedCheck: true})
        }
      }
    }else{
      // this.props.navigation.navigate('LogBook')
      // if(this.state.ACDWitnessed === '#B9DD7A'){
      this.submitUserAcd();
      this.setState({ACDWitnessed: '#B9DD7A'})
      this.setState({ACDWitnessedCheck: true})
      // this.setState({activityProcess: false})

      // }else{
      //   this.setState({ACDWitnessed: '#B9DD7A'})
      // }
    }
  }
}

ACDSigned = () =>{

  this.setState({ACDSignedCheck: !this.state.ACDSignedCheck})

  if(this.state.ACDSigned === '#B9DD7A'){
    this.setState({ACDSigned: '#C74252'})
  }else{
    this.setState({ACDSigned: '#B9DD7A'})
  }
}

getLocalKeys = async () =>{
  const value = await AsyncStorage.getItem('@UserData');
  this.user = await AsyncStorage.getItem('@UserData');
  this.token = await AsyncStorage.getItem('@token');
  let stringImages = await AsyncStorage.getItem("@AcdImages")
  this.uri = JSON.parse(stringImages);
  console.log('this.uri images', this.uri);
  let splitted = ''
  let array = []
  this.uri.map(x =>{
    splitted = x.split(".com");
    console.log("splitted Check::",splitted);
    if(splitted[0] !== "http://res.cloudinary"){
      array.push(x)
    }else{
      this.responseImage.push(x)
    }
  })
  this.uri = array;
  console.log('this.uri not uploaded', this.uri);
  if (value !== null) {
    var data = JSON.parse(value)
    console.log("UserData :: ", data);

    this.setState({userName:data.name})
    this.setState({userAddress:data.address})

    this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})
  }
}



handlelogout = () =>{
  AsyncStorage.clear()
  this.props.navigation.navigate("Login")
}
handleacd= () =>{
  this.props.navigation.navigate("AcdHome")
}
handleItemPress= ()=>{
  this.props.navigation.navigate('PersonalDetail')
}

handleAcdOverview = (e) =>{
  console.log("overview clicked ");
  this.props.navigation.navigate('PopOver')
}
uploadMyHealth =(e) =>{
  this.props.navigation.navigate('ACDOverview8')


  //
  //
  // this.setState({activityProcess: true})
  // console.log("In getLocalData", this.jsonUser);
  // this.jsonUser.acd.isComplete = true
  // let optionsAxios = ''
  // let user = JSON.parse(this.user)
  // if(this.jsonUserMain.user_id){
  //   optionsAxios = {
  //     method: 'POST',
  //     headers: {'Content-Type': 'application/json', 'Authorization': JSON.parse(this.token) },
  //     url: global.url+'/api/updateUserAcd',
  //     data: {userData: this.jsonUserMain.userData, acd_images:this.jsonUserMain.acd_images, _id:global.userData._id}
  //   }
  // }else{
  //   optionsAxios = {
  //     method: 'POST',
  //     headers: {'Content-Type': 'application/json', 'Authorization': JSON.parse(this.token)},
  //     url: global.url+'/api/submitUserAcd',
  //     data: {userData: this.jsonUser, user_id:user._id}
  //   }
  // }
  //
  // console.log(" submitUserAcd Options axios ", optionsAxios );
  // axios(optionsAxios)
  // .then((response)=> {
  //   console.log("/submit Acd Response recieved ", response);
  //   if(response.data.success){
  //     // global.userData.userData.acd.isComplete = true
  //     global.userData = response.data.result
  //     this.jsonUser = global.userData
  //     AsyncStorage.setItem("@SyncedUserData", JSON.stringify(global.userData) )
  //     AsyncStorage.removeItem("@unSyncedUserData")
  //     this.acdId = response.data.result._id;
  //
  //     if(this.uri.length > 0){
  //       this.ActionSheet.show()
  //     }else{
  //       this.props.navigation.navigate('LogBook')
  //
  //     }
  //     // this.uploadAcdImages(this.uri, 0, this.acdId)
  //     // this.props.navigation.navigate('LogBook')
  //   }else{
  //     console.log(response.data.message);
  //   }
  //   // AsyncStorage.setItem("@AppData", JSON.stringify(response.data.result) )
  //   // global.jsonData = response.data.result
  // })
  // .catch((error)=>{
  //   console.log("error", error);
  // })
}
submitUserAcd = async ()=>{
  let myUser = JSON.parse(this.user)
  this.setState({activityProcess: true})
  console.log("In getLocalData", this.jsonUser);
  this.jsonUser.acd.isComplete = true
  let optionsAxios = ''
  let user = JSON.parse(this.user)
  if(this.jsonUserMain.user_id){
    optionsAxios = {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'Authorization': JSON.parse(this.token)},
      url: global.url+'/api/updateUserAcd',
      data: {userData: this.jsonUser, acd_images:this.responseImage, user_id:myUser._id, _id:global.userData._id, isAlreadyCreated:true}
    }

        console.log(" submitUserAcd Options axios ", optionsAxios );
        axios(optionsAxios)
        .then((response)=> {
          console.log("/submit Acd Response recieved ", response);
          if(response.data.success){
            this.getNewUserAcd()

          }else{
            console.log(response.data.message);
          }
          // AsyncStorage.setItem("@AppData", JSON.stringify(response.data.result) )
          // global.jsonData = response.data.result
        })
        .catch((error)=>{
          console.log("error", error);
          console.log("error", error.response);
        })
  }else{
    optionsAxios = {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'Authorization': JSON.parse(this.token)},
      url: global.url+'/api/submitUserAcd',
      data: {userData: this.jsonUser, acd_images:this.responseImage, user_id:myUser._id}
    }

    console.log(" submitUserAcd Options axios ", optionsAxios );
    axios(optionsAxios)
    .then((response)=> {
      console.log("/submit Acd Response recieved ", response);
      if(response.data.success){
        this.getNewUserAcd()

      }else{
        console.log(response.data.message);
      }
      // AsyncStorage.setItem("@AppData", JSON.stringify(response.data.result) )
      // global.jsonData = response.data.result
    })
    .catch((error)=>{
      console.log("error", error);
      console.log("error", error.response);
    })
  }
}

getNewUserAcd = async () =>{
  const token = await AsyncStorage.getItem('@token');
  const value = await AsyncStorage.getItem('@UserData');
  let parsedUser = JSON.parse(value)
  console.log("In getUserAcd", parsedUser._id);
  var optionsAxios = {
    method: 'POST',
    headers: {'Content-Type': 'application/json', 'Authorization': JSON.parse(token)},
    url: global.url+'/api/getUserAcd',
    data: {user_id: parsedUser._id, territory:parsedUser.territory._id}
  }
  console.log(" getUserAcd Options axios ", optionsAxios );
  axios(optionsAxios)
  .then((response)=> {
    console.log("/user Acd Response recieved for timeperiod", response);
    if(response.data.result.length !== 0){
      global.userData = response.data.result[0]
      AsyncStorage.setItem("@SyncedUserAcd", JSON.stringify(response.data.result[0]) )
      AsyncStorage.setItem("@AcdImages", JSON.stringify(response.data.result[0].acd_images) )
      AsyncStorage.setItem("@timePeriod", JSON.stringify(response.data.result[0].timePeriod));

      this.setState({UserComponent:<UserComponent name={parsedUser.name} address={parsedUser.address} profileImg = {parsedUser.profileImg}/>})

      AsyncStorage.removeItem("@unSyncedUserData")

      console.log('response.data', response.data);
      this.formatWitnessedAcd(response.data.result[0]);
      // this.props.navigation.navigate('LogBook')
    }else{

      console.log(response.data.message);
    }
  })
  .catch((error)=>{
    console.log("error getUserData :: ", error);
  })

}

formatWitnessedAcd = async (e) =>{
  const token = await AsyncStorage.getItem('@token');
  const value = await AsyncStorage.getItem('@UserData');
  this.setState({downloading: true})
  let optionsAxios = ''
  let user = JSON.parse(this.user)

  optionsAxios = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    url: global.url+'/getVerifiedAcdPdf/' + e._id,
  }

  console.log(" getVerifiedLogBook Options axios ", optionsAxios );
  axios(optionsAxios)
  .then((response)=> {
    this.setState({downloading:false})
    this.setState({ACDWitnessed: '#B9DD7A'})
    this.setState({ACDWitnessedCheck: true})

    console.log("/getVerifiedLogBook Acd Response recieved ", response);
    this.setState({activityProcess: false})

    if(response.data.success){

      // if(response.data.acdPdfWitnessed.length > 0 ){
      //   this.setState({AcdGenerated:true})
      // }
      this.setState({ActivityProcessing: false})
      // response.data.result.acdPdfWitnessed.map((x,idx,z) =>{
      //   if(idx == z.length-1){
      //     this.setState({pdfWitnessed:x.url})
      //     console.log("pdf is ", x);
      //     console.log("date", moment(x.submittedOn).format('DD,MM,YYYY'));
      //     let lastDate = moment(x.submittedOn).format('D');
      //     // var j = i % 10,
      //     // k = i % 100;
      //     if (lastDate == 1 && lastDate != 11) {
      //       lastDate = lastDate + "st";
      //     }
      //     if (lastDate == 2 && lastDate != 12) {
      //       lastDate = lastDate + "nd";
      //     }
      //     if (lastDate == 3 && lastDate != 13) {
      //       lastDate = lastDate + "rd";
      //     }else{
      //       lastDate = lastDate + "th";
      //     }
      //     this.setState({lastDate:lastDate})
      //     let fullDate = lastDate + ' of ' + moment(x.createdOn).format('MMMM YYYY')
      //     this.setState({dateWitnessed:fullDate})
      //
      //   }
      //
      // })

    }else{
      console.log(response.data.message);
    }
  })
  .catch((error)=>{
    console.log("error", error);
    console.log("error", error.response);
  })
}


uploadAcdImages = async (uri, count, acdId)=>{
  let stringImages = await AsyncStorage.getItem("@AcdImages")
  let parsedImages = JSON.parse(stringImages);
  console.log('parsedImages', parsedImages);
  console.log('parsedImages global.userData', global.userData);

  let bodyData = await new FormData();
      if(Platform.OS === 'ios' || Platform.OS ==='android'){
      let extension = uri[count].substring(uri[count].lastIndexOf('.')+1)

        bodyData.append("userFile", {
          uri:uri[count],
          type: extension === 'pdf' ? 'application/pdf' : 'image/jpeg', // or photo.type
          name:'upload.'+extension,
        } )
        console.log("Parsed images :: ", uri);
        console.log("Fetch Options :: ", global.url+ '/api/uploadAcdImg/' + acdId);
        // const optionsAxios = {
        //     method: 'POST',
        //     headers: {'cache-control': 'no-cache', 'Content-type': 'multipart/form-data', 'Authorization': JSON.parse(this.token) } ,
        //     url: global.url+'/api/updateUserAcd',
        //     body: bodyData
        //   }
        // axios(optionsAxios)
        fetch(global.url+ '/api/uploadAcdImg/' + acdId,
        {
          method: 'POST',
          headers: {'cache-control': 'no-cache', 'Content-type': 'multipart/form-data', 'Authorization': JSON.parse(this.token) } ,
          body: bodyData,
        }
      )
      .then((response)=> {

        console.log('###responce picture post ',response);

        if (response.status == 200) {
          return response.json()

        }
      })
      .then((responseJson)=>{
        console.log("#### responseJson picture post :: ", responseJson);
        this.responseImage.push(responseJson.url)
        console.log('this.responseImage', this.responseImage);
        if(Platform.OS === 'ios' || Platform.OS ==='android'){
          if(this.count < this.uri.length){
            // this.ActionSheet.show()
          console.log("here 22")

            this.onActionNonPress()
          }else{
            AsyncStorage.removeItem("@AcdImages")
            // this.ACDWitnessed()
            console.log('this.responseImage in last', this.responseImage);

            // AsyncStorage.setItem("@AcdImagestoSubmit", JSON.stringify(responseImage) )
            this.submitUserAcd()
            // this.getNewUserAcd()
          }
        }else{
          if(this.count < this.uri.length){
            if(this.acdId){
              this.uploadAcdImages()
            }else{
              alert('ACD not created yet')
            }
          }else{
            AsyncStorage.removeItem("@AcdImages")
            // this.ACDWitnessed()
            console.log('this.responseImage in last', this.responseImage);

            // AsyncStorage.setItem("@AcdImagestoSubmit", JSON.stringify(responseImage) )
            this.submitUserAcd()

            // this.getNewUserAcd()
          }
        }
      })
      .catch(err => console.log('###error api', err))

    }else{
      bodyData.append("base64Image",uri[count])
      console.log('url', global.url+ '/api/uploadAcdImg/' + acdId);

      // console.log('token', JSON.parse(token), this.state.image);
      fetch(global.url+ '/api/uploadAcdImg/' + acdId,
      {
        method: 'POST',
        headers: {} ,
        body: bodyData,
      }
    )
    .then( (response)=> {
      console.log('response', response);
      if (response.status == 200) {
        return response.json()

      }
    })
    .then((responseJson)=>{
      console.log('###responce picture post web', responseJson);
      console.log('in response.status 200');
      console.log("#### responseJson picture post :: ", responseJson);
      this.responseImage.push(responseJson.url)
      console.log('this.responseImage', this.responseImage);
      if(Platform.OS === 'ios' || Platform.OS ==='android'){
        if(this.count < this.uri.length){
          // this.ActionSheet.show()
          console.log("here 33")

          this.onActionNonPress()
        }else{
          AsyncStorage.removeItem("@AcdImages")
          // this.ACDWitnessed()
          console.log('this.responseImage in last', this.responseImage);

          // AsyncStorage.setItem("@AcdImagestoSubmit", JSON.stringify(responseImage) )
          this.submitUserAcd()
          // this.getNewUserAcd()
        }
      }else{
        if(this.count < this.uri.length){
          console.log("here 44")

          this.onActionNonPress()
        }else{
          AsyncStorage.removeItem("@AcdImages")
          // this.ACDWitnessed()
          console.log('this.responseImage in last', this.responseImage);

          // AsyncStorage.setItem("@AcdImagestoSubmit", JSON.stringify(responseImage) )
          this.submitUserAcd()

          // this.getNewUserAcd()
        }
      }
    })

    .catch(err => console.log('###error api', err))
    }
}


handleHome = (e) =>{
  this.props.navigation.navigate('AcdHome')
}
componentWillMount(){
  Dimensions.addEventListener("change", this.handler);
}

componentWillUnmount(){
  Dimensions.removeEventListener("change", this.handler);
}
handler =(e) =>{
  console.log('eee', e);
  this.setState({winHeight: e.window.height})
}

handleNext = () => {
  console.log('deactivated');
  // this.props.navigation.navigate("ACDOverview18")
}

onActionPress = (e) => {
  console.log("Action pressed :: ", e, this.count);
  if (e == 0) {
    if(this.count < 6){
      if(this.acdId){
        this.uploadAcdImages(this.uri, this.count, this.acdId)
      }else{
        alert('ACD not created yet')
      }
      // this.uploadAcdImages(this.uri, this.count, this.acdId)
      this.count++
    }
  }

}

onActionNonPress = () => {
  if(this.count < 6){
    console.log("onActionNonpress :::",this.acdId)
    if(this.acdId){
      this.uploadAcdImages(this.uri, this.count, this.acdId)

    this.setState({activityProcess: false})
    }else{
      alert('ACD not created yet')

    this.setState({activityProcess: false})
    }
    // this.uploadAcdImages(this.uri, this.count, this.acdId)
    this.count++
  }
}

render() {
  return (
    <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>
      <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'75%',width:'100%'}]:[{height:'70%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
        <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>

          <View style = {global.mainInsideContainer}>

            <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleacd()}>
              {this.state.UserComponent}
            </TouchableOpacity>

            <View style={{width:"100%", alignItems: 'center', justifyContent: 'center'}}>
              <Text style={{fontSize:14, color:"#1D2195", width:"90%", marginBottom:30}}>
                {this.jsonData.description[0]}
              </Text>
              {
                (Platform.OS !== "web")
                ?
                <ActionSheet
                  ref={o => this.ActionSheet = o}
                  title={'Wanna upload your images ?'}
                  options={['Upload Image', 'Cancel']}
                  cancelButtonIndex={1}
                  destructiveButtonIndex={1}
                  onPress={(index) => { this.onActionPress(index) }}
                  />
                :
                null
              }

              <TouchableOpacity style={{width:'100%'}} >
                <View style={{width:'90%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
                  <View style = {{display:'flex',flexDirection:'row',alignItems:'center', width:'100%',paddingTop:10,paddingBottom:10 ,backgroundColor:'#F9F1FF'}}>
                    <View style={{width:'5%', alignItems:'center', justifyContent:'center'}}>
                    </View>
                    <View style={{width:'85%'}}>
                      <Text style={{textAlign:'left',fontSize:14, color:'#1D2195', fontWeight: 'bold'}}>Advanced Care Directive filled out</Text>
                    </View>
                    <View style={{ width:'10%', backgroundColor:'#9126FB',height:25,width:25, alignItems:'center', justifyContent:'center'}}>
                      <ImageBackground source={require('../../../../assets/Checkbox_check.png')} style={{width: 20, height: 20}}/>
                    </View>
                  </View>
                </View>
              </TouchableOpacity>

              <TouchableOpacity style={{width:'100%'}} onPress = {this.ACDSigned}>
                <View style={{width:'90%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
                  <View style = {{display:'flex',flexDirection:'row',alignItems:'center', width:'100%',paddingTop:10,paddingBottom:10 ,backgroundColor:'#F9F1FF'}}>
                    <View style={{width:'5%', alignItems:'center', justifyContent:'center'}}>
                    </View>
                    <View style={{width:'85%'}}>
                      <Text style={{textAlign:'left',fontSize:14, color:'#1D2195', fontWeight: 'bold'}}>ACD signed by Substitute Decision Maker</Text>
                    </View>
                    {
                      this.state.ACDSignedCheck
                      ?
                      <View style={{ width:'10%', backgroundColor:'#9126FB',height:25,width:25, alignItems:'center', justifyContent:'center'}}>
                        <ImageBackground source={require('../../../../assets/Checkbox_check.png')} style={{width: 20, height: 20}}/>
                      </View>
                      :
                      <View style={{ width:'10%', backgroundColor:'#9126FB',height:25,width:25, alignItems:'center', justifyContent:'center'}}>
                      </View>
                    }
                  </View>
                </View>
              </TouchableOpacity>

              {
                (this.state.activityProcess)
                ?
                <ActivityIndicator size="small" color="#9126fb" style={{marginTop:10}} />
                :
                <TouchableOpacity style={{width:'100%'}} onPress = {this.ACDWitnessed}>
                  <View style={{width:'90%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
                    <View style = {{display:'flex',flexDirection:'row',alignItems:'center', width:'100%',paddingTop:10,paddingBottom:10 ,backgroundColor:'#F9F1FF'}}>
                      <View style={{width:'5%', alignItems:'center', justifyContent:'center'}}>
                      </View>
                      <View style={{width:'85%'}}>
                        <Text style={{textAlign:'left',fontSize:14, color:'#1D2195', fontWeight: 'bold'}}>ACD witnessed</Text>
                      </View>
                      {
                        this.state.ACDWitnessedCheck
                        ?
                        <View style={{ width:'10%', backgroundColor:'#9126FB',height:25,width:25, alignItems:'center', justifyContent:'center'}}>
                          <ImageBackground source={require('../../../../assets/Checkbox_check.png')} style={{width: 20, height: 20}}/>
                        </View>
                        :
                        <View style={{ width:'10%', backgroundColor:'#9126FB',height:25,width:25, alignItems:'center', justifyContent:'center'}}>
                        </View>
                      }
                    </View>
                  </View>
                </TouchableOpacity>
              }

              {
                (this.state.ACDWitnessedCheck === true && this.state.ACDSignedCheck === true)
                ?
                <View style={{width:"100%", alignItems: 'center', justifyContent: 'center'}}>
                  <Text style={{fontSize:14, color:"#1D2195", width:"90%", marginTop:50, marginBottom:20}}>
                    Do you want to upload your ACD to My HealthRecord?
                  </Text>

                  {
                    (this.state.activityProcess)
                    ?
                    null
                    :
                    <CustomButton title = "Upload to My HealthRecord" type= "inScreen" backgroundColor = "#9126FB" border="true" color='#F9F1FF' navigation = {this.props.navigation} onPress= {this.uploadMyHealth}/>
                  }
                </View>

                :
                <Text style={{fontSize:14, color:"#1D2195", width:"90%", marginTop:25}}>
                  {this.jsonData.description[1]}
                </Text>
              }

            </View>
          </View>
        </View>

      </ScrollView>
      <ScrollView scrollEnabled={false} style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'25%', width:'100%'}]:[{height:'30%', width:'100%', maxWidth:800, minWidth:800}]}
        contentContainerStyle={[global.bottom1, {width:'100%', alignItems:'center', justifyContent:'center'}]}>
        <CustomButton title = "Home" type="multiples" color='#F9F1FF' navigation= {this.props.navigation} onPress = {this.handleNext}/>
      </ScrollView>

    </View>
  );
}
}



const styles = StyleSheet.create({
  container: {
    flex:1,
    width:'100%',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor:'#501D95',
    //     paddingTop:50,
  },


  inputField:{
    width:'90%',
    flexDirection:'row',
    height:50,
    //     marginTop:16,
    marginBottom:16,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'


  },
  textInputStyle:{
    height: 40,
    width:'78%',
    marginBottom:5,
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',

  },
  CheckBoxTitle:{
    color:"#1D2195",
    fontSize:14,
    fontWeight:"bold",
    width:"90%"
  },
  CheckBoxContainerStyle:{
    backgroundColor:"transparent",
    borderWidth:0,
    width:"100%"
  },


})
