/**
 * @providesModule RNRestart
 */

'use strict';

import { NativeModules} from 'react-native';

const { RNRestart } = NativeModules;

export default RNRestart;
