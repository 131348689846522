import React, { Component } from "react";
import {
  View,
  Text,
  StyleSheet,
  AsyncStorage,
  ScrollView,
  TextInput,
  Platform,
  ActivityIndicator,
  TouchableOpacity,
  Dimensions,
} from "react-native";
import global from "../../../Styles/global";
import UserComponent from "../UserComponent";
import { Button, Icon } from "react-native-elements";
import CustomMainScreenItem from "../CustomMainScreenItem";
import PurpleCircle from "../PurpleCircle";
import { NavigationEvents } from "react-navigation";
import CustomButton from "../CustomButton";
import axios from "axios";

export default class AcdHomeNorthern extends Component {
  constructor() {
    super();
    this.state = {
      search: "",
      winHeight: Dimensions.get("window").height,
      sevenStepPlanComplete: false,
      isLoading: true,
      UserComponent: <ActivityIndicator size="small" color="#9126fb" />,
    };
    global.jsonData = require("../../../Data/screens_data_new.json");
    // require('../../assets/Profile.png')

    this.getLocalKeys();
  }

  getLocalKeys = async () => {
    const value = await AsyncStorage.getItem("@UserData");
    if (value !== null) {
      var data = JSON.parse(value);
      console.log("UserData :: ", data);
      if (data.accountStatus === "pending") {
        this.props.navigation.navigate("SignedUpScreen");
      }
      if (data.profileImg) {
        global.profileImg = data.profileImg;
        console.log("global.profileImg in acdhome", global.profileImg);
      } else {
        // global.profileImg =
        // require('../../../assets/Profile.png')
        // console.log('global.profileImg',global.profileImg);
      }
      this.setState({ userName: data.name });
      this.setState({ userAddress: data.address });

      this.setState({
        UserComponent: (
          <UserComponent
            name={data.name}
            address={data.address}
            profileImg={data.profileImg}
          />
        ),
      });
    }
  };

  componentDidMount() {
    this.setLocalToGlobal();
  }
  navigateProfile = () => {
    // console.log('in UploadImage');
    // this.props.navigation.navigate("UploadImage")
  };
  onWillFocus = () => {
    console.log("in onWillFocus");
    this.getLocalKeys();
    this.setLocalToGlobal();
    this.getLogResource();
  };

  getLogResource = async (e) => {
    this.token = await AsyncStorage.getItem("@token");
    console.log("in get resourcesComplete");
    let arrayCreated = [];

    this.setState({ downloading: true });
    let optionsAxios = "";
    optionsAxios = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.parse(this.token),
      },
      url: global.url + "/api/getAllResources",
    };

    console.log(" resourcesComplete Options axios ", optionsAxios);
    axios(optionsAxios)
      .then((response) => {
        this.setState({ downloading: false, isLoading: false });

        console.log("/resourcesComplete Acd Response recieved ", response);
        this.setState({ ActivityProcessing: false });
        this.getSevenLog();
        if (response.data.success) {
          // if(response.data.acdCreated.length !== 0 ){
          //   this.setState({PdfSeven:true})
          // }
          if (response.data.result.length > 0) {
            this.setState({ resourcesComplete: true });
          }
        } else {
          console.log(response.data.message);
        }
      })
      .catch((error) => {
        this.getSevenLog();

        this.setState({ PdfSeven: false, isLoading: false });

        console.log("error", error);
      });
  };

  getSevenLog = async (e) => {
    console.log("in get sevenLog");
    let arrayCreated = [];

    this.setState({ downloading: true });
    let optionsAxios = "";
    optionsAxios = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.parse(this.token),
      },
      url: global.url + "/api/getSevenStepPlan",
    };

    console.log(" sevenLog Options axios ", optionsAxios);
    axios(optionsAxios)
      .then((response) => {
        this.setState({ downloading: false, isLoading: false });

        console.log("/sevenLog Acd Response recieved ", response);
        this.setState({ ActivityProcessing: false });
        if (response.data.success) {
          // if(response.data.acdCreated.length !== 0 ){
          //   this.setState({PdfSeven:true})
          // }
          if (response.data.result.sevenStepPdfSubmitted.length > 0) {
            this.setState({ sevenStepPlanComplete: true });
          }
        } else {
          console.log(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ PdfSeven: false, isLoading: false });

        console.log("error", error);
      });
  };

  setLocalToGlobal = async () => {
    console.log("in setLocalToGlobal northern");
    let SyncedUserData = await AsyncStorage.getItem("@SyncedUserAcd");
    let unSyncedUserData = await AsyncStorage.getItem("@unSyncedUserData");
    let acdImages = await AsyncStorage.getItem("@AcdImages");
    let parsedImages = JSON.parse(acdImages);
    if (SyncedUserData !== null) {
      var forCheck = JSON.parse(SyncedUserData);
      console.log("forCheck", forCheck);

      if (forCheck.userData.acd.isComplete) {
        this.setState({ acdComplete: true });
      }
    }
    if (unSyncedUserData !== null) {
      var data = JSON.parse(unSyncedUserData);
      if (data.userData.acd.isComplete) {
        this.setState({ acdComplete: true });
      }
      console.log("in unSyncedUserData if", data);
      global.userData = data;
      if (parsedImages) {
        if (parsedImages.length > 0) {
          global.userData.acd_images = parsedImages;
        }
      }
    } else {
      var data = JSON.parse(SyncedUserData);
      if (data.userData.acd.isComplete) {
        this.setState({ acdComplete: true });
      }
      console.log("in SyncedUserAcd else", data);
      unSyncedUserData = data;
      global.userData = data;
      if (parsedImages) {
        if (parsedImages.length > 0) {
          global.userData.acd_images = parsedImages;
        }
      }
    }
    // this.getLogBookDetails()
  };

  getLogBookDetails = async () => {
    let acdPdfCreated = await AsyncStorage.getItem("@acdPdfCreated");
    let acdPdfWitnessed = await AsyncStorage.getItem("@acdPdfWitnessed");
    let parsedAcdPdfWitnessed = JSON.parse(acdPdfWitnessed);
    let parsedAcdPdfCreated = JSON.parse(acdPdfCreated);
    let createdDate = 0;
    let updatedDate = 0;
    console.log("acdPdfCreated", JSON.parse(acdPdfCreated));
    console.log("acdPdfWitnessed", JSON.parse(acdPdfWitnessed));

    if (parsedAcdPdfCreated.length > 0) {
      createdDate = parsedAcdPdfCreated[0].createdOn;
      if (parsedAcdPdfCreated.length > 1) {
        parsedAcdPdfCreated.map((x, idx, z) => {
          if (idx == z.length - 1) {
            updatedDate = x.createdOn;
          }
        });
      }
    }
    console.log("xxxx", createdDate, updatedDate);

    this.setState({ createdDate: createdDate });
    this.setState({ updatedDate: updatedDate });
    this.setState({
      UserComponent: (
        <TouchableOpacity onPress={() => this.navigateProfile()}>
          <UserComponent createdDate="00" updatedDate="00" />
        </TouchableOpacity>
      ),
    });
  };

  handlelogout = () => {
    AsyncStorage.clear();
    this.props.navigation.navigate("Login");
  };
  handleacd = () => {
    this.props.navigation.navigate("AcdHome");
  };

  handleItemPress = (e) => {
    if (e === "Advanced Care Directive") {
      this.props.navigation.navigate("AcdSignUpNorthern");
    } else if (e === "7 step plan") {
      this.props.navigation.navigate("SevenStart");
    } else if (e === "7 step plan complete") {
      this.props.navigation.navigate("SevenStart2");
    } else if (e === "Resources") {
      this.props.navigation.navigate("ResourcesStart");
    } else if (e === "Resources complete") {
      this.props.navigation.navigate("ResourceLog");
    } else if (e === "AnticipatoryStart") {
      this.props.navigation.navigate("AnticipatoryStart");
    } else if (e === "My Healthrecord") {
      this.props.navigation.navigate("ACDOverview8Northern");
    } else if (e === "Contacts") {
      console.log("checking northern")
      if(Platform.OS === 'web'){
        console.log("checking web")
        this.props.navigation.navigate("ContactsMainWeb");
      }
      else{
        console.log("checking mobile")
        this.props.navigation.navigate("ContactsMain");
      }
    }
  };

  componentWillMount() {
    Dimensions.addEventListener("change", this.handler);
  }

  componentWillUnmount() {
    Dimensions.removeEventListener("change", this.handler);
  }
  handler = (e) => {
    console.log("eee", e);
    this.setState({ winHeight: e.window.height });
  };
  UploadImage = () => {
    this.props.navigation.navigate("UploadImage");
  };

  render() {
    return (
      <View
        style={
          ([global.container],
          {
            height: this.state.winHeight,
            justifyContent: "center",
            alignItems: "center",
          })
        }
      >
        <View
          style={
            Platform.OS === "ios" || Platform.OS === "android"
              ? global.ItemsContainerMobile
              : global.ItemsContainerWeb
          }
        >
          <ScrollView
            style={
              Platform.OS === "ios" || Platform.OS === "android"
                ? [{ height: "90%", width: "100%" }]
                : [{ height: "90%", width: "100%" }]
            }
            contentContainerStyle={{
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <NavigationEvents
              onDidFocus={() => this.onWillFocus()}
              onWillBlur={(payload) => console.log("will blur", payload)}
              onDidBlur={(payload) => console.log("did blur", payload)}
            />
            {this.state.isLoading ? (
              <View
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: 250,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ActivityIndicator size="large" color="#9126fb" />
              </View>
            ) : (
              <View style={global.mainInsideContainer}>
                {this.state.UserComponent}

                {this.state.acdComplete ? (
                  <TouchableOpacity
                    style={{ width: "100%" }}
                    onPress={() =>
                      this.handleItemPress("Advanced Care Directive")
                    }
                  >
                    <View
                      style={{
                        width: "90%",
                        paddingTop: 5,
                        paddingBottom: 5,
                        alignItems: "center",
                        justifyContent: "center",
                        alignSelf: "center",
                      }}
                    >
                      <CustomMainScreenItem
                        color="#1D2195"
                        checkBox="show"
                        title="Advanced Care Directive"
                        fontSize={14}
                      />
                    </View>
                  </TouchableOpacity>
                ) : (
                  <TouchableOpacity
                    style={{ width: "100%" }}
                    onPress={() =>
                      this.handleItemPress("Advanced Care Directive")
                    }
                  >
                    <View
                      style={{
                        width: "90%",
                        paddingTop: 5,
                        paddingBottom: 5,
                        alignItems: "center",
                        justifyContent: "center",
                        alignSelf: "center",
                      }}
                    >
                      <CustomMainScreenItem
                        color="#1D2195"
                        checkBox="empty"
                        title="Advanced Care Directive"
                        fontSize={14}
                      />
                    </View>
                  </TouchableOpacity>
                )}
                {this.state.sevenStepPlanComplete ? (
                  <TouchableOpacity
                    style={{ width: "100%" }}
                    onPress={() => this.handleItemPress("7 step plan complete")}
                  >
                    <View
                      style={{
                        width: "90%",
                        paddingTop: 5,
                        paddingBottom: 5,
                        alignItems: "center",
                        justifyContent: "center",
                        alignSelf: "center",
                      }}
                    >
                      <CustomMainScreenItem
                        color="#1D2195"
                        checkBox="show"
                        title="7 step plan"
                        fontSize={14}
                      />
                    </View>
                  </TouchableOpacity>
                ) : (
                  <TouchableOpacity
                    style={{ width: "100%" }}
                    onPress={() => this.handleItemPress("7 step plan")}
                  >
                    <View
                      style={{
                        width: "90%",
                        paddingTop: 5,
                        paddingBottom: 5,
                        alignItems: "center",
                        justifyContent: "center",
                        alignSelf: "center",
                      }}
                    >
                      <CustomMainScreenItem
                        color="#1D2195"
                        checkBox="empty"
                        title="7 step plan"
                        fontSize={14}
                      />
                    </View>
                  </TouchableOpacity>
                )}

                {this.state.resourcesComplete ? (
                  <TouchableOpacity
                    style={{ width: "100%" }}
                    onPress={() => this.handleItemPress("Resources complete")}
                  >
                    <View
                      style={{
                        width: "90%",
                        paddingTop: 5,
                        paddingBottom: 5,
                        alignItems: "center",
                        justifyContent: "center",
                        alignSelf: "center",
                      }}
                    >
                      <CustomMainScreenItem
                        color="#1D2195"
                        checkBox="show"
                        title="Resources"
                        fontSize={14}
                      />
                    </View>
                  </TouchableOpacity>
                ) : (
                  <TouchableOpacity
                    style={{ width: "100%" }}
                    onPress={() => this.handleItemPress("Resources")}
                  >
                    <View
                      style={{
                        width: "90%",
                        paddingTop: 5,
                        paddingBottom: 5,
                        alignItems: "center",
                        justifyContent: "center",
                        alignSelf: "center",
                      }}
                    >
                      <CustomMainScreenItem
                        color="#1D2195"
                        checkBox="empty"
                        title="Resources"
                        fontSize={14}
                      />
                    </View>
                  </TouchableOpacity>
                )}

                <View
                  style={{
                    width: "90%",
                    paddingTop: 5,
                    paddingBottom: 5,
                    alignItems: "center",
                    justifyContent: "center",
                    alignSelf: "center",
                  }}
                >
                  <CustomMainScreenItem
                    color="#1D2195"
                    checkBox="empty"
                    title="Medication Summary"
                    fontSize={14}
                  />
                </View>

                <View
                  style={{
                    width: "90%",
                    paddingTop: 5,
                    paddingBottom: 5,
                    alignItems: "center",
                    justifyContent: "center",
                    alignSelf: "center",
                  }}
                >
                  <CustomMainScreenItem
                    color="#1D2195"
                    checkBox="empty"
                    title="Medical History"
                    fontSize={14}
                  />
                </View>

                <TouchableOpacity
                  style={{ width: "100%" }}
                  onPress={() => this.handleItemPress("AnticipatoryStart")}
                >
                  <View
                    style={{
                      width: "90%",
                      paddingTop: 5,
                      paddingBottom: 5,
                      alignItems: "center",
                      justifyContent: "center",
                      alignSelf: "center",
                    }}
                  >
                    <CustomMainScreenItem
                      color="#1D2195"
                      title="Anticipatory Prescribing Protocol"
                      fontSize={14}
                    />
                  </View>
                </TouchableOpacity>

                <TouchableOpacity
                  onPress={() => this.handleItemPress("My Healthrecord")}
                  style={{
                    width: "90%",
                    paddingTop: 5,
                    paddingBottom: 5,
                    alignItems: "center",
                    justifyContent: "center",
                    alignSelf: "center",
                  }}
                >
                  <CustomMainScreenItem
                    color="#1D2195"
                    title="My Healthrecord"
                    fontSize={14}
                  />
                </TouchableOpacity>

                <TouchableOpacity
                  onPress={() => this.handleItemPress("Contacts")}
                  style={{
                    width: "90%",
                    paddingTop: 5,
                    paddingBottom: 5,
                    alignItems: "center",
                    justifyContent: "center",
                    alignSelf: "center",
                  }}
                >
                  <CustomMainScreenItem
                    color="#1D2195"
                    title="Contacts"
                    fontSize={14}
                  />
                </TouchableOpacity>

                <CustomButton
                  title="LOG OUT"
                  marginBottom={25}
                  fontWeight="bold"
                  type="multiples"
                  onPress={() => this.handlelogout()}
                  navigation={this.props.navigation}
                />
              </View>
            )}
          </ScrollView>
          <ScrollView
            scrollEnabled={false}
            style={
              Platform.OS === "ios" || Platform.OS === "android"
                ? [{ height: "10%", width: "100%" }]
                : [
                    {
                      height: "10%",
                      width: "100%",
                      maxWidth: 800,
                      minWidth: 800,
                    },
                  ]
            }
            contentContainerStyle={{
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          ></ScrollView>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor:'#501D95',
    //     paddingTop:50,
  },

  inputField: {
    width: "90%",
    flexDirection: "row",
    height: 50,
    //     marginTop:16,
    marginBottom: 16,
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 10,
    justifyContent: "space-between",
    borderWidth: 0.5,
    borderColor: "#1D2195",
  },
  textInputStyle: {
    height: 40,
    width: "78%",
    marginBottom: 5,
    alignSelf: "flex-end",
    // outline:'none',
    borderColor: "transparent",
  },
});
