import React, { Component } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  TextInput,
  ImageBackground,
  TouchableOpacity,
  AsyncStorage,
  ActivityIndicator,
  ScrollView,
  Dimensions
} from 'react-native';
import {  Button, CheckBox } from 'react-native-elements'
import UserComponent from '../../../Components/UserComponent'
import UserDetail from '../../../Components/UserDetail'
import CustomButton from '../../../Components/CustomButton'
import CustomMainScreenItem from '../../../Components/CustomMainScreenItem'

import ACDfooter from '../../../Components/ACDfooter'
import global from '../../../Styles/global';
import DatePicker from 'react-native-datepicker'

export default class ACDoverview2Northern extends Component {
  constructor(props) {
    super(props);

    this.state={
      UserComponent:<ActivityIndicator size="small" color="#9126fb" />,
    data:'',
    ACDSigned:'#C74252',
    ACDWitnessed:'#C74252',
    winHeight:Dimensions.get("window").height,

  }

  global.jsonData.forEach((i,idx,x)=>{
    // console.log('global.jsonData', global.jsonData);
    if (i.title == 'North ACD overview - 9') {
      this.jsonData = i
      console.log('i is ', i);
    }
  })

  this.getLocalKeys()
}

getLocalKeys = async () =>{
  const value = await AsyncStorage.getItem('@UserData');
  if (value !== null) {
    var data = JSON.parse(value)
    console.log("UserData :: ", data);
    if (data.accountStatus == "pending") {
      this.props.navigation.navigate('SignedUp', {comingFrom:"login", data:data});
    }
    else {
      this.setState({data:data})

      this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})
    }
  }
}

ACDfilledout = () =>{
  console.log("ACDfilledout");
}
ACDWitnessed = () =>{
  if(this.state.ACDWitnessed === '#B9DD7A'){
    this.setState({ACDWitnessed: '#C74252'})
  }else{
    this.setState({ACDWitnessed: '#B9DD7A'})
  }
}
ACDSigned = () =>{
  if(this.state.ACDSigned === '#B9DD7A'){
    this.setState({ACDSigned: '#C74252'})
  }else{
    this.setState({ACDSigned: '#B9DD7A'})
  }
}
handleNext = () => {
  this.props.navigation.navigate("ACDoverview3Northern")
}
componentWillMount(){
  Dimensions.addEventListener("change", this.handler);
}

componentWillUnmount(){
  Dimensions.removeEventListener("change", this.handler);
}
handler =(e) =>{
  console.log('eee', e);
  this.setState({winHeight: e.window.height})
}
render() {
  return (
    <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>
      <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'75%',width:'100%'}]:[{height:'70%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
        <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>

          {this.state.UserComponent}

          <View style={{width:"100%", alignItems: 'center', justifyContent: 'center'}}>
            <Text style={{fontSize:14, color:"#1D2195", width:"90%"}}>
              <Text style={{fontWeight:"bold"}}>{this.jsonData.instructions[0]}</Text> {this.jsonData.description[0]}{"\n"}
              </Text>

              <TouchableOpacity style={{width:'100%'}} >
                <View style={{width:'90%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
                  <View style = {{display:'flex',flexDirection:'row',alignItems:'center', width:'100%',paddingTop:10,paddingBottom:10 ,backgroundColor:'#F9F1FF'}}>
                    <View style={{width:'5%', alignItems:'center', justifyContent:'center'}}>
                    </View>
                    <View style={{width:'85%'}}>
                      <Text style={{textAlign:'left',fontSize:14, color:'#1D2195', fontWeight: 'bold'}}>Advanced Care Statement filled out</Text>
                    </View>
                    <View style={{ width:'10%', backgroundColor:'#9126FB',height:25,width:25, alignItems:'center', justifyContent:'center'}}>
                      <ImageBackground source={require('../../../../assets/Checkbox_check.png')} style={{width: 20, height: 20}}/>
                    </View>
                  </View>
                </View>
              </TouchableOpacity>
              <Text style={{fontSize:14, color:"#1D2195", width:"90%"}}>
              {"\n"}{this.jsonData.description[1]}{"\n"}{"\n"}

                <Text style={{fontWeight:"bold"}}>{this.jsonData.instructions[1]}</Text>{"\n"}{"\n"}

                  {this.jsonData.description[2]}{"\n"}{"\n"}
                  <Text style={{fontWeight:"bold"}}>Tasks to complete {"\n"}</Text>
                </Text>


                  <TouchableOpacity style={{width:'100%'}} >
                    <View style={{width:'90%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
                      <View style = {{display:'flex',flexDirection:'row',alignItems:'center', width:'100%',paddingTop:10,paddingBottom:10 ,backgroundColor:'#F9F1FF'}}>
                        <View style={{width:'5%', alignItems:'center', justifyContent:'center'}}>
                        </View>
                        <View style={{width:'85%'}}>
                          <Text style={{textAlign:'left',fontSize:14, color:'#1D2195', fontWeight: 'bold'}}>ACS signed by Substitute Decision Maker</Text>
                        </View>
                        <View style={{ width:'10%', backgroundColor:'#9126FB',height:25,width:25, alignItems:'center', justifyContent:'center'}}>
                        </View>
                      </View>
                    </View>
                  </TouchableOpacity>

                  <TouchableOpacity style={{width:'100%'}} >
                    <View style={{width:'90%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
                      <View style = {{display:'flex',flexDirection:'row',alignItems:'center', width:'100%',paddingTop:10,paddingBottom:10 ,backgroundColor:'#F9F1FF'}}>
                        <View style={{width:'5%', alignItems:'center', justifyContent:'center'}}>
                        </View>
                        <View style={{width:'85%'}}>
                          <Text style={{textAlign:'left',fontSize:14, color:'#1D2195', fontWeight: 'bold'}}>ACS witnessed</Text>
                        </View>
                        <View style={{ width:'10%', backgroundColor:'#9126FB',height:25,width:25, alignItems:'center', justifyContent:'center'}}>
                        </View>
                      </View>
                    </View>
                  </TouchableOpacity>

              </View>
            </View>

          </ScrollView>
          <ScrollView scrollEnabled={false} style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'25%', width:'100%'}]:[{height:'30%', width:'100%', maxWidth:800, minWidth:800}]}
            contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
            <CustomButton title = "➔"    navigation= {this.props.navigation} onPress = {this.handleNext}/>
          </ScrollView>
        </View>
      );

    }
  }

  const styles = StyleSheet.create({
    buttonContainer:{
      alignItems:'center',
      marginTop:50,
      marginBottom:10,
      width:'90%',
    },
    loginButton:{
      borderRadius:5,

    },
    arrow:{
      fontSize: 20,
    },
    inputField:{
      width:'100%',
      flexDirection:'row',
      height:50,
      alignItems:'center',
      paddingLeft:10,
      paddingRight:10,
      marginBottom:8,
      justifyContent:'space-between',
      borderWidth:.5,
      borderColor:'#1D2195'
    },
    textInputStyle:{
      height: 40,
      width:'78%',
      alignSelf:'flex-end',
      borderColor:'transparent',
    },
    textstyle:{
      alignItems:'center',
      justifyContent:'center',
      color:'#1D2195',
      fontSize:12,
      textAlign:'center'
    },
    CheckBoxTitle:{
      color:"#707070",
      fontSize:14,
      fontWeight:"bold",
      width:"90%"
    },
    CheckBoxContainerStyle:{
      backgroundColor:"transparent",
      borderWidth:0,
      width:"100%"
    }

  })
