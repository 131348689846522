import React, { Component } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  TextInput,
  ImageBackground,
  TouchableOpacity,
} from 'react-native';
import {  Button } from 'react-native-elements'
import Icon from 'react-native-vector-icons/FontAwesome';
export default class CustomButton extends Component {
  constructor(props) {
    super(props);
  }

  handleBackButton=()=>{
      if (this.props.comingFrom == "SignedUpScreen") {
        AsyncStorage.clear()
        this.props.navigation.navigate("Login")
      }else{
        console.log('in go back');
        this.props.navigation.goBack()
      }
  }

  handleHomeButton=()=>{
    this.props.navigation.popToTop()
  }

  render() {
    var bgColor = '#9126FB'
    var color = 'white'
    var fontWeight = 'normal'
    if (this.props.backgroundColor) {
      bgColor = this.props.backgroundColor
    }

    if (this.props.color) {
      color = this.props.color
    }
    if (this.props.fontWeight) {
      fontWeight = this.props.fontWeight
    }



    return (
      <View style={(this.props.type === 'multiples')? styles.buttonContainerMultiples : (this.props.type === 'inScreen')? styles.buttonContainerinScreen: (this.props.type === 'createAccount')? styles.buttonContainerCreateAccount : styles.buttonContainer}>
        {
          (this.props.type === 'multiples' && this.props.title === "Home")
          ?
          <TouchableOpacity onPress = {() => this.handleHomeButton()}
            style = {this.props.border?{borderRadius:5, backgroundColor:bgColor, width: '100%', height:48 ,alignSelf:'center', justifyContent:'center', alignItems:'center', borderColor:"#1D2195", borderWidth:2}
              :{borderRadius:5, backgroundColor:bgColor, width: '100%', height:48 ,alignSelf:'center', justifyContent:'center', alignItems:'center'}}
              >
            <Image
              style={styles.buttonHome}
              source={require('../../assets/home.png')}
            />
          </TouchableOpacity>
          :
          (this.props.type === 'multiples' && this.props.title === "HomeProvider")
          ?
          <TouchableOpacity onPress = {this.props.onPress}
            style = {this.props.border?{borderRadius:5, backgroundColor:bgColor, width: '100%', height:48 ,alignSelf:'center', justifyContent:'center', alignItems:'center', borderColor:"#1D2195", borderWidth:2}
              :{borderRadius:5, backgroundColor:bgColor, width: '100%', height:48 ,alignSelf:'center', justifyContent:'center', alignItems:'center'}}
              >
            <Image
              style={styles.buttonHome}
              source={require('../../assets/home.png')}
            />
          </TouchableOpacity>
          :
          (this.props.type === 'multiples' || this.props.type === 'inScreen' || this.props.type === 'createAccount')
          ?
          <Button
            raised
            title={this.props.title}
            onPress = {this.props.onPress}
            titleStyle = {(this.props.title == '➔' || this.props.title == '←')? styles.arrow: this.props.title == 'LOG IN' || this.props.title == 'Citizen/patient' || this.props.title == "Provider" ?{fontWeight:"bold", fontSize:14}: this.props.fontWeight == 'bold' ? {color:color, fontSize:14, fontWeight:fontWeight,}: this.props.title == 'Add new 7-step pathway' ?{color:color, fontSize:20, fontWeight:fontWeight} :
            this.props.title == 'See existing 7 step pathway' ?{color:color, fontSize:20, fontWeight:fontWeight} :this.props.title?{color:color, fontSize:14}:null}
            buttonStyle = {this.props.border?{borderRadius:5, backgroundColor:bgColor, width: '100%', minHeight:48 ,alignSelf:'center', justifyContent:'center', borderColor:"#1D2195", borderWidth:2}
              :{borderRadius:5, backgroundColor:bgColor, width: '100%', minHeight:48 ,alignSelf:'center', justifyContent:'center'}}
            containerStyle= {{width:'100%', marginBottom : this.props.marginBottom}}
            />

          :

          (this.props.type === 'home')
          ?
          <Button
            raised
            title='Home'
            onPress = {() => this.handleHomeButton()}
            titleStyle = {(this.props.title == '➔' || this.props.title == '←')? styles.arrow: this.props.title == 'Home' ?{fontWeight:"bold", fontSize:14}: this.props.title?{color:color, fontSize:14}:null}
            buttonStyle = {this.props.border?{borderRadius:5, backgroundColor:bgColor, width: '100%', height:48 ,alignSelf:'center', justifyContent:'center', borderColor:"#1D2195", borderWidth:2}
              :{borderRadius:5, backgroundColor:bgColor, width: '100%', height:48 ,alignSelf:'center', justifyContent:'center'}}
            containerStyle= {{width:'100%', marginBottom : this.props.marginBottom}}
            />
          :
          <View style={{width:'100%', flexDirection:"row", display:"flex", justifyContent:'space-between'}}>

            <TouchableOpacity onPress={() => this.handleBackButton()}
              style = {this.props.border?{borderRadius:5, backgroundColor:bgColor, width: '20%', height:48 ,alignSelf:'center', justifyContent:'center', alignItems:'center', borderColor:"#1D2195", borderWidth:2}
                :{borderRadius:5, backgroundColor:bgColor, width: '20%', height:48 ,alignSelf:'center', justifyContent:'center', alignItems:'center'}}
                >
              <Image
                style={styles.button}
                source={require('../../assets/Symbolback.png')}
              />
            </TouchableOpacity>

              <TouchableOpacity onPress = {() => this.handleHomeButton()}
                style = {this.props.border?{borderRadius:5, backgroundColor:bgColor, width: '20%', height:48 ,alignSelf:'center', justifyContent:'center', alignItems:'center', borderColor:"#1D2195", borderWidth:2}
                  :{borderRadius:5, backgroundColor:bgColor, width: '50%', height:48 ,alignSelf:'center', justifyContent:'center', alignItems:'center'}}
                  >
                <Image
                  style={styles.buttonHome}
                  source={require('../../assets/home.png')}
                />
              </TouchableOpacity>

              <TouchableOpacity onPress={this.props.onPress}
                style = {this.props.border?{borderRadius:5, backgroundColor:bgColor, width: '20%', height:48 ,alignSelf:'center', justifyContent:'center', alignItems:'center', borderColor:"#1D2195", borderWidth:2}
                  :{borderRadius:5, backgroundColor:bgColor, width: '20%', height:48 ,alignSelf:'center', justifyContent:'center', alignItems:'center'}}
                  >
                <Image
                  style={styles.button}
                  source={require('../../assets/Symbolforward.png')}
                />
              </TouchableOpacity>
    {/*
          <Button
            raised
            title={this.props.title}
            onPress = {this.props.onPress}
            titleStyle = {(this.props.title == '➔' || this.props.title == '←')? styles.arrow: this.props.title == 'LOG IN' ?{fontWeight:"bold", fontSize:14}: this.props.title?{color:color, fontSize:14}:null}
            buttonStyle = {this.props.border?{borderRadius:5, backgroundColor:bgColor, width: '100%', height:48 ,alignSelf:'center', justifyContent:'center', borderColor:"#1D2195", borderWidth:2}
              :{borderRadius:5, backgroundColor:bgColor, width: '100%', height:48 ,alignSelf:'center', justifyContent:'center'}}
            containerStyle= {{width:'20%', marginBottom : this.props.marginBottom}}
            />
          */}
          </View>
        }

      </View>
    );
  }
}
const styles = StyleSheet.create({
  buttonContainer:{
    flexDirection:'row',
    alignItems:'center',
    marginTop:10,
    marginBottom:60,
    width:'90%',
    display:'flex', justifyContent:'space-between',

    // justifyContent:'center',
  },
  buttonContainerMultiples:{
    flexDirection:'row',
    alignItems:'center',
    marginTop:10,
    marginBottom:2,
    width:'90%',
    // justifyContent:'center',
  },
  buttonContainerinScreen:{
    flexDirection:'row',
    alignItems:'center',
    marginTop:4,
    marginBottom:4,
    width:'90%',
    // justifyContent:'center',
  },
  buttonContainerCreateAccount:{
    flexDirection:'row',
    alignItems:'center',
    marginTop:25,
    marginBottom:25,
    width:'90%',
    // justifyContent:'center',
  },
  loginButton:{
    borderRadius:5,
  },
  arrow:{
    fontSize: 20,
  },
  button:{
    height:12,
    width:19
  },
  buttonHome:{
    height:40,
    width:45
  }

})
