import React, {Component} from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Navigator from './src/Navigator/navigator'
import { connectActionSheet } from '@expo/react-native-action-sheet'
import { AppearanceProvider } from 'react-native-appearance';

// import * as Sentry from "@sentry/react-native";

class App extends Component{
  constructor(){
    super()
    // global.url = 'https://palcare-backend-dev.herokuapp.com'
    // global.url = 'https://palcare-backend-internal.herokuapp.com'
    global.url = 'https://acdcare-backend.sammetric.com'

  }

  
  render()
  {
    // Sentry.init({
    //   dsn: "https://373e9f3f970c46e0a73cad06b829e352@o436573.ingest.sentry.io/5397952",
    // });

    // throw new Error("My first Sentry error!");
    return(
      <AppearanceProvider>
      <Navigator/>
      </AppearanceProvider>
    )
  }
}

const ConnectedApp = connectActionSheet(App)
export default ConnectedApp;
