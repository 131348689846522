import React, { Component } from 'react';
import { View,Text,StyleSheet, AsyncStorage,ScrollView, TextInput, Platform, ActivityIndicator, TouchableOpacity, Dimensions, Image } from 'react-native';
import global from "../../../Styles/global";
import UserComponent from '../UserComponent'
import {Button, Icon} from 'react-native-elements'
import CustomMainScreenItem from '../CustomMainScreenItem';
import PurpleCircle from '../PurpleCircle';
import { NavigationEvents } from 'react-navigation';
import CustomButton from '../CustomButton'
import ACDfooter from '../ACDfooter';
import {  CheckBox } from 'react-native-elements'


export default class AdvanceCareHomeNorthern extends Component {
  constructor(){
    super()
    this.state = {
      search:'',
      winHeight:Dimensions.get("window").height,

      UserComponent:<ActivityIndicator size="small" color="#9126fb" />
    }
    // global.jsonData = require('../../../Data/screens_data_new.json')
    // require('../../assets/Profile.png')
    this.jsonUserMain = global.userData.userData.acd.advance_care_statement
    // this.jsonUser = global.userData.userData.acd.advance_care_statement.questions[0]
    console.log("JSON USER MAIN ::::",global.userData.userData.acd);
    global.jsonData.forEach((i,idx,x)=>{
      if (i.title == "North My value and wishes - info") {
        this.jsonData = i
      }
    })
    this.getLocalKeys()
  }

  getLocalKeys = async () =>{
    const value = await AsyncStorage.getItem('@UserData');
    if (value !== null) {
      var data = JSON.parse(value)
      console.log("UserData :: ", data);


      this.setState({userName:data.name})
      this.setState({userAddress:data.address})
      console.log('====================================');
      console.log("Data::::::", data);
      console.log('====================================');
      this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})

      console.log('====================================');
      console.log("Data:::::: after setstate", data);
      console.log('====================================');

    }
  }
  componentDidMount(){

  }
  navigateProfile = () =>{
    // console.log('in UploadImage');
    // this.props.navigation.navigate("UploadImage")
  }
  onWillFocus = ()=>{
    console.log('in onWillFocus');

  }

  handlelogout = () =>{
    AsyncStorage.clear()
    this.props.navigation.navigate("Login")
  }
  handleacd= () =>{
    this.props.navigation.navigate("AcdHome")
  }
  handleItemPress= (e)=>{
    let toPage = 'AdvanceCare' + e
    console.log('toPage', toPage);
    this.props.navigation.navigate(toPage)
  }

componentWillMount(){
  Dimensions.addEventListener("change", this.handler);
}

componentWillUnmount(){
  Dimensions.removeEventListener("change", this.handler);
}
handler =(e) =>{
console.log('eee', e);
this.setState({winHeight: e.window.height})
}
UploadImage = () =>{
  this.props.navigation.navigate("UploadImage")

}
handleAcdOverview = () =>{
  console.log("JSON USER MAIN ::",this.jsonUserMain);
  if(this.state.skipSubstitute){
    this.jsonUserMain.questions.map((x,idx,z)=>{
      x.answer = ''
      x.isComplete = false
      if(idx === 3){
        x.checkBox.isChecked = false
      }
      if(idx === 4){
        x.checkBox1.isChecked = false
        x.checkBox2.isChecked = false
        x.checkBox3.isChecked = false
      }
    })

    global.userData.userData.acd.advance_care_statement = this.jsonUserMain

    global.userData.userData.acd.advance_care_statement.isComplete = false
    AsyncStorage.setItem("@unSyncedUserData", JSON.stringify(global.userData) )

    this.props.navigation.navigate("AdvanceConsentHomeNorthern")
  }else{
    this.props.navigation.navigate("AdvanceCare1")
  }

}

  render() {
    return (
      <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>
        <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'75%',width:'100%'}]:[{height:'70%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
          <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>
            <NavigationEvents
              onWillFocus={() => this.onWillFocus()}
              onDidFocus={payload => console.log('did focus', payload)}
              onWillBlur={payload => console.log('will blur', payload)}
              onDidBlur={payload => console.log('did blur', payload)}
              />

          <View style = {global.mainInsideContainer}>

            <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleacd()}>
            {this.state.UserComponent}
            </TouchableOpacity>

            <View style={{width:'80%',paddingBottom:10, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
              <Text style={[global.textBold, {marginTop: 10}]}>
                In this part you can write down:
              </Text>
            </View>

            <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleItemPress("1")}>
            <View style={{width:'90%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
            <CustomMainScreenItem checkBox = {this.jsonUserMain.questions[0].isComplete ? 'show' : 'empty'} circleValue='1' color='#1D2195' title = "What gives your life meaning?" fontSize={14} />
            </View>
            </TouchableOpacity>

            <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleItemPress("2a")}>
            <View style={{width:'90%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
            <CustomMainScreenItem checkBox = {this.jsonUserMain.questions[1].isComplete ? 'show' : 'empty'} circleValue='2a' color='#1D2195' title = "What are your goals/priorities?" fontSize={14} />
            </View>
            </TouchableOpacity>

            <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleItemPress("2b")}>
            <View style={{width:'90%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
            <CustomMainScreenItem checkBox = {this.jsonUserMain.questions[2].isComplete ? 'show' : 'empty'} circleValue='2b' color='#1D2195' title = "What is unacceptable to you?" fontSize={14} />
            </View>
            </TouchableOpacity>

            <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleItemPress("2c")}>
            <View style={{width:'90%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
            <CustomMainScreenItem checkBox = {this.jsonUserMain.questions[3].isComplete ? 'show' : 'empty'} circleValue='2c' color='#1D2195' title = "Connect to palliative and comfort care" fontSize={14} />
            </View>
            </TouchableOpacity>

            <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleItemPress("3")}>
            <View style={{width:'90%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
            <CustomMainScreenItem checkBox = {this.jsonUserMain.questions[4].isComplete ? 'show' : 'empty'} circleValue='3' color='#1D2195' title = "Where would you like to die/finish up?" fontSize={14} />
            </View>
            </TouchableOpacity>

            <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleItemPress("4")}>
            <View style={{width:'90%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
            <CustomMainScreenItem checkBox = {this.jsonUserMain.questions[5].isComplete ? 'show' : 'empty'} circleValue='4' color='#1D2195' title = "Other information" fontSize={14} />
            </View>
            </TouchableOpacity>

            <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleItemPress("5")}>
            <View style={{width:'90%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
            <CustomMainScreenItem checkBox = {this.jsonUserMain.questions[6].isComplete ? 'show' : 'empty'} circleValue='5' color='#1D2195' title = "Cultural or spiritual requests?" fontSize={14} />
            </View>
            </TouchableOpacity>

            <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleItemPress("6")}>
            <View style={{width:'90%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
            <CustomMainScreenItem checkBox = {this.jsonUserMain.questions[7].isComplete ? 'show' : 'empty'} circleValue='6' color='#1D2195' title = "After death, what is important to you?" fontSize={14} />
            </View>
            </TouchableOpacity>
            <View style={{width:'90%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
              <CheckBox
                title='I do not want to write down what is Important to me.'
                containerStyle={styles.CheckBoxContainerStyle}
                textStyle={styles.CheckBoxTitle}
                size={38}
                checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_pressed.png')} />}
                uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_notpressed.png')} />}
                checked={this.state.skipSubstitute}
                onPress={() => this.setState({skipSubstitute: !this.state.skipSubstitute})}
                />
            </View>
          </View>


        </View>

          </ScrollView>
          <ScrollView scrollEnabled={false} style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'25%', width:'100%'}]:[{height:'30%', width:'100%', maxWidth:800, minWidth:800}]}
            contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
            <CustomButton title = "➔" navigation = {this.props.navigation} onPress= {this.handleAcdOverview}/>
            <ACDfooter  title="B" marginLeft="25%" />

          </ScrollView>

      </View>
    );
  }
}



const styles = StyleSheet.create({
  container: {
    flex:1,
    width:'100%',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor:'#501D95',
//     paddingTop:50,
  },


  inputField:{
    width:'90%',
    flexDirection:'row',
    height:50,
//     marginTop:16,
    marginBottom:16,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'


  },
  textInputStyle:{
    height: 40,
    width:'78%',
    marginBottom:5,
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',

  },
  CheckBoxTitle:{
    color:"#1D2195",
    fontSize:14,
    fontWeight:"bold",
    width:"90%"
  },
  CheckBoxContainerStyle:{
    backgroundColor:"transparent",
    borderWidth:0,
    width:"100%"
  },

})
