import React, { Component } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  TextInput,
  ImageBackground,
  TouchableOpacity,
  AsyncStorage,
  ActivityIndicator,
  Dimensions,
  ScrollView,
  KeyboardAvoidingView,
  Alert,
} from 'react-native';
import {  Button } from 'react-native-elements'
import UserComponent from '../UserComponent'
import UserDetail from '../UserDetail'
import NoButton from '../NoButton'
import CustomButton from '../CustomButton'
import global from '../../../Styles/global';
import ACDfooter from '../ACDfooter';
import DatePicker from 'react-native-datepicker'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { NavigationEvents } from 'react-navigation';
import axios from 'axios';
var moment = require('moment');


export default class PersonalDetailNorthern extends Component {
  constructor(props) {
    super(props);

    this.state={
      UserComponent:<ActivityIndicator size="small" color="#9126fb" />,
      data:'',
      name:'',
      email:'',
      birthday:'',
      address:'',
      mobile:'',
      gp:'',
      dropDownState: false,
      editName:false,
      editEmail:false,
      editBirthday:false,
      editAddress:false,
      editMobile:false,
      editGP:false,
      loadingData:true,
      winHeight:Dimensions.get("window").height,


      UserDetail:'',
      allTerritories: [
        {
            "_id": "5dbbd498dd07ce301d623bfc",
            "name": "New South Wales",
            "abbreviation": "NSW"
        },
        {
            "_id": "5dbbd498dd07ce301d623bfd",
            "name": "Victoria",
            "abbreviation": "VIC"
        },
        {
            "_id": "5dbbd498dd07ce301d623bfe",
            "name": "Queensland",
            "abbreviation": "QLD"
        },
        {
            "_id": "5dbbd498dd07ce301d623bff",
            "name": "Tasmania",
            "abbreviation": "TAS"
        },
        {
            "_id": "5dbbd498dd07ce301d623c00",
            "name": "South Australia",
            "abbreviation": "SA"
        },
        {
            "_id": "5dbbd498dd07ce301d623c01",
            "name": "Western Australia",
            "abbreviation": "WA"
        },
        {
            "_id": "5dbbd498dd07ce301d623c02",
            "name": "Northern Territory",
            "abbreviation": "NT"
        },
        {
            "_id": "5dbbd498dd07ce301d623c03",
            "name": "Australian Capital Territory",
            "abbreviation": "ACT"
        }
]
    }
    this.maxDate = new Date()

    console.log('global.userData in personal details', global.userData.userData.acd.personal_details);
    this.jsonUser = global.userData.userData.acd.personal_details
    this.jsonUserMain = global.userData

    this.token = ''
    this.getLocalKeys()
  }

  getLocalKeys = async () =>{
    const value = await AsyncStorage.getItem('@UserData');
    if (value !== null) {
      var data = JSON.parse(value)
      this.data = JSON.parse(value)
      console.log("UserData :: ", data);
      if (data.accountStatus == "pending") {
        this.props.navigation.navigate('SignedUp', {comingFrom:"login", data:data});
      }
      else {
        this.setState({data:data})
        this.setState({UserComponent:
            <UserComponent onPress={() => this.navigateProfile()} name={data.name} address={data.address} profileImg = {data.profileImg}/>
      })
        // this.setValues()
            this.getUserDetails()
      }
    }
  }
  onWillFocus = ()=>{
    this.getLocalKeys()
  }
  componentDidMount (){
    // this.setValues();
    this.getEnums();
  }

  getUserDetails = async () =>{
    console.log('getUserDetails');
    this.token = await AsyncStorage.getItem('@token');

    // api/getSingleUser
    // console.log('e is', e);
    console.log('token', JSON.parse(this.token));
    this.setState({activityProcess: true})
    let optionsAxios = ''
    optionsAxios = {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'Authorization': JSON.parse(this.token) },
      url: global.url+'/api/getSingleUser'
    }

    console.log(" getUser Options axios ", optionsAxios );
    axios(optionsAxios)
    .then((response)=> {
      console.log("/user get Response recieved ", response);
      if(response.status === 200){

        AsyncStorage.setItem("@UserData", JSON.stringify(response.data.user))
        this.data = response.data.user
        this.setValues();


      }else{
        console.log(response.data.message);
      }
    })
    .catch((error)=>{
      console.log("error", error);
      console.log("error", error.response);
    })
  }


  setValues = async () =>{
    this.token = await AsyncStorage.getItem('@token');

    console.log('this.jsonUser in setValues', this.jsonUser);
    if(this.jsonUser.email){
      this.setState({email:this.jsonUser.email})
    }else{
      this.setState({email:this.data.email})
    }
    if(this.jsonUser.name){
      this.setState({name:this.jsonUser.name})
    }else{
      this.setState({name:this.data.name})
    }
    if(this.jsonUser.birthday){
      this.setState({birthday:this.jsonUser.birthday})
    }else{
      this.setState({birthday:this.data.dateOfBirth})
    }
    if(this.jsonUser.address){
      this.setState({address:this.jsonUser.address})
    }else{
      this.setState({address:this.data.address})
    }
    if(this.jsonUser.mobile){
      console.log('have mobile');
      this.setState({mobile:this.jsonUser.mobile})
    }else{
      this.setState({mobile:this.data.phone})
    }

    if(this.jsonUser.territory){
      console.log('have territory');
      this.setState({territory:this.jsonUser.territory})
      this.setState({oldTerritory:this.jsonUser.territory, territoryNew:this.jsonUser.territory})
    }else{
      this.setState({territory:this.data.territory.name})
      this.setState({oldTerritory:this.data.territory.name, territoryNew:this.data.territory.name})
    }
    if(this.jsonUser.doctor){
      this.setState({doctor:this.jsonUser.doctor})
      console.log('have doctor');
    }else{
      this.setState({doctor:this.data.gp})
    }
    setTimeout( () => {
      console.log('this.state', this.state);

    }, 100);
    this.setState({loadingData:false})

  }

  handlelogout = () =>{
    AsyncStorage.clear()
    this.props.navigation.navigate("Login")
  }
  handleSaveState = (e) => {
    console.log(e, " Pressed");
    const nameRegex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g

    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/



    if (e == 'name') {

      if (!nameRegex.test(this.state.name )) {
        if(this.state.name == undefined || this.state.name == ''){
          alert("Name is required")
        }
        else{
          alert("Invalid Name")
        }
        // alert("Name is required");
      }else{
        this.setState({editName:!this.state.editName})
        this.setState({editEmail:false})
        this.setState({editBirthday:false})
        this.setState({editAddress:false})
        this.setState({editMobile:false})
        this.setState({editGP:false})
      }
    }
    else if (e == 'email') {

        if (!emailRegex.test(this.state.email )) {
        if (this.state.email == undefined || this.state.email == '') {
          alert("Email is required");
        }
        else{
          alert("Invalid Email")
        }
      }else{
        this.setState({editName:false})
        this.setState({editEmail:!this.state.editEmail})
        this.setState({editBirthday:false})
        this.setState({editAddress:false})
        this.setState({editMobile:false})
        this.setState({editGP:false})
        this.setState({editTerritory:false})

      }
    }
    else if (e == 'birthday') {
      this.setState({editName:false})
      this.setState({editEmail:false})
      this.setState({editBirthday:!this.state.editBirthday})
      this.setState({editAddress:false})
      this.setState({editMobile:false})
      this.setState({editGP:false})
      this.setState({editTerritory:false})

    }
    else if (e == 'territory') {
      this.setState({editName:false})
      this.setState({editEmail:false})
      this.setState({editTerritory:!this.state.editTerritory})
      this.setState({editAddress:false})
      this.setState({editMobile:false})
      this.setState({editGP:false})
    }
    else if (e == 'address') {
      this.setState({editName:false})
      this.setState({editEmail:false})
      this.setState({editBirthday:false})
      this.setState({editAddress:!this.state.editAddress})
      this.setState({editMobile:false})
      this.setState({editGP:false})
      this.setState({editTerritory:false})

    }
    else if (e == 'mobile') {
      this.setState({editName:false})
      this.setState({editEmail:false})
      this.setState({editBirthday:false})
      this.setState({editAddress:false})
      this.setState({editMobile:!this.state.editMobile})
      this.setState({editGP:false})
      this.setState({editTerritory:false})

    }
    else if (e == 'gp') {
      this.setState({editName:false})
      this.setState({editEmail:false})
      this.setState({editBirthday:false})
      this.setState({editAddress:false})
      this.setState({editMobile:false})
      this.setState({editGP:!this.state.editGP})
      this.setState({editTerritory:false})

    }
  }
  handleDetailPress = (e) =>{
    console.log(e, " Pressed");
    if (e == 'name') {
      this.setState({editName:true})
      this.setState({editEmail:false})
      this.setState({editBirthday:false})
      this.setState({editAddress:false})
      this.setState({editMobile:false})
      this.setState({editGP:false})
    }
    else if (e == 'email') {
      this.setState({editName:false})
      this.setState({editEmail:true})
      this.setState({editBirthday:false})
      this.setState({editAddress:false})
      this.setState({editMobile:false})
      this.setState({editGP:false})
    }
    else if (e == 'birthday') {
      this.setState({editName:false})
      this.setState({editEmail:false})
      this.setState({editBirthday:true})
      this.setState({editAddress:false})
      this.setState({editMobile:false})
      this.setState({editGP:false})
    }
    else if (e == 'address') {
      this.setState({editName:false})
      this.setState({editEmail:false})
      this.setState({editBirthday:false})
      this.setState({editAddress:true})
      this.setState({editMobile:false})
      this.setState({editGP:false})
    }
    else if (e == 'mobile') {
      this.setState({editName:false})
      this.setState({editEmail:false})
      this.setState({editBirthday:false})
      this.setState({editAddress:false})
      this.setState({editMobile:true})
      this.setState({editGP:false})
    }
    else if (e == 'gp') {
      this.setState({editName:false})
      this.setState({editEmail:false})
      this.setState({editBirthday:false})
      this.setState({editAddress:false})
      this.setState({editMobile:false})
      this.setState({editGP:true})
    }
  }

  // handleAcdOverview = (e) =>{
  //   console.log("handleNext");
  //   this.jsonUser.isComplete = true
  //   console.log('this.jsonUser', this.jsonUser);
  //   global.userData.userData.acd.personal_details.isComplete = true
  //   this.props.navigation.navigate('AdvanceCareNoteNorthern')
  //
  //
  // }
  handleCheckTerritory = () =>{
    console.log('this.state.territoryMain', this.state.territoryMain);
    console.log('this.jsonUser.territory', this.jsonUser.territory);
    console.log('this.state.territory', this.state.territory);
    console.log('this.state.territoryNew', this.state.territoryNew);
    console.log('this.state.oldTerritory', this.state.oldTerritory);
    if(this.state.oldTerritory === this.state.territoryNew){
      // this.updateUser()
          this.updateUser()
    }else{
      if(Platform.OS === 'ios' || Platform.OS ==='android'){
        Alert.alert(
          'Warning',
          'Changing Territory will lose all unsaved data!',
          [
            { text: 'Cancel', onPress: () => console.log('Cancel Pressed') },
            {
              text: 'Change Territory',
              onPress: () => this.checkInternetForTerritoryChange('Change'),
              style: 'cancel',
            },
          ],
          { cancelable: true }
        );
      }else{
        let r = confirm(
          'Changing Territory will lose all unsaved data!'
        )
        if (r == true) {
          this.checkInternetForTerritoryChange('Change')
        } else {
          txt = "You pressed Cancel!";
        }
      }

    }
  }

  checkInternetForTerritoryChange = () =>{
    this.setState({activityProcess: true})
        this.setState({activityProcess: false})

        // this.updateUser('Change')
        this.emptyData('Change')

  }

  updateUser = (e) =>{
    console.log('e is', e);
    this.setState({activityProcess: true})
    let optionsAxios = ''
    optionsAxios = {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'Authorization': JSON.parse(this.token) },
      url: global.url+'/api/updateUser',
      data: {address: this.state.address, gp:this.state.doctor, phone:this.state.mobile, territory:this.state.territoryMain}
    }

    console.log(" updateuser Options axios ", optionsAxios );
    axios(optionsAxios)
    .then((response)=> {
      console.log("/user update Response recieved ", response);
      if(response.status === 200){
        if(e === 'Change'){
          AsyncStorage.setItem("@UserData", JSON.stringify(response.data.result))
          AsyncStorage.removeItem("@unSyncedUserData")

          this.props.navigation.navigate("Login")
          // Expo.Util.reload()
          console.log('restart the scenario');
          // RNRestart.Restart()
        }else{
          AsyncStorage.setItem("@UserData", JSON.stringify(response.data.result))

          this.handleAcdOverview()
        }

      }else{
        console.log(response.data.message);
      }
      // AsyncStorage.setItem("@AppData", JSON.stringify(response.data.result) )
      // global.jsonData = response.data.result
    })
    .catch((error)=>{
      console.log("error", error);
      console.log("error", error.response);
    })
  }


    emptyData = async (e)=>{
      if(e === 'Change'){
        this.setState({activityProcess: true})
        // console.log("In getLocalData", this.jsonUser);
        // this.jsonUser.acd.isComplete = true
        let optionsAxios = ''
        let user = this.data
        let myUser = this.data
        let emptyJson = require('../../../Data/northern_acd.json')
        console.log('emptyJson', emptyJson);
        console.log('this.jsonUserMain', this.jsonUserMain);
        let cmmValue =  this.jsonUserMain.userData.isComplete
        emptyJson.userData.isComplete = cmmValue
        if(this.jsonUserMain.user_id){
          optionsAxios = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': JSON.parse(this.token)},
            url: global.url+'/api/updateUserAcd',
            data: {userData: emptyJson.userData, acd_images:[], user_id:myUser._id, _id:global.userData._id, isAlreadyCreated:true}
          }

              console.log(" submitUserAcd Options axios ", optionsAxios );
              axios(optionsAxios)
              .then((response)=> {
                console.log("/submit Acd Response recieved ", response);
                if(response.data.success){
                  // this.getNewUserAcd()
                  AsyncStorage.removeItem('@AcdImages');

                  this.updateUser('Change')

                }else{
                  console.log(response.data.message);
                }
              })
              .catch((error)=>{
                console.log("error", error);
                console.log("error", error.response);
              })
        }else{
          AsyncStorage.removeItem('@AcdImages');

          this.updateUser('Change')
          // optionsAxios = {
          //   method: 'POST',
          //   headers: {'Content-Type': 'application/json', 'Authorization': JSON.parse(this.token)},
          //   url: global.url+'/api/submitUserAcd',
          //   data: {userData: emptyJson.userData, acd_images:[], user_id:myUser._id, isAlreadyCreated:true}
          // }
          //
          // console.log(" submitUserAcd Options axios ", optionsAxios );
          // axios(optionsAxios)
          // .then((response)=> {
          //   console.log("/submit Acd Response recieved ", response);
          //   if(response.data.success){
          //     // this.getNewUserAcd()
          //     AsyncStorage.removeItem('@AcdImages');
          //
          //     this.updateUser('Change')
          //
          //   }else{
          //     console.log(response.data.message);
          //   }
          //   // AsyncStorage.setItem("@AppData", JSON.stringify(response.data.result) )
          //   // global.jsonData = response.data.result
          // })
          // .catch((error)=>{
          //   console.log("error", error);
          //   console.log("error", error.response);
          // })
        }
      }else{

      }

    }

  handleAcdOverview = (e) =>
  {
    // AsyncStorage.setItem("@UserAcd", JSON.stringify(response.data.result[0]) )
    const nameRegex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g

    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/


    if (!emailRegex.test(this.state.email )) {
      if (this.state.email == undefined || this.state.email == '') {
        alert("Email is required");
      }
      else{
        alert("Invalid Email")
      }
    }else if(!nameRegex.test(this.state.name )) {
      if(this.state.name == undefined || this.state.name == ''){
        alert("Name is required")
      }
      else{
        alert("Invalid Name")
      }
      // alert("Name is required");
    }else{
      if(this.state.birthday){
        console.log('this.state.birthday', this.state.birthday);

        const broken= this.state.birthday.split('/');
        if(broken[0] && broken[1] && broken[2]){
          if(broken[2].length == 4 && broken[0].length == 2 && broken[1].length == 2 && broken[0] < 32 && broken[1] < 13){
            console.log("handle2Next", this.jsonUser);
            this.jsonUser.isComplete = true
            this.jsonUser.email = this.state.email
            this.jsonUser.name = this.state.name
            this.jsonUser.address = this.state.address
            this.jsonUser.birthday = this.state.birthday
            this.jsonUser.mobile = this.state.mobile
            this.jsonUser.doctor = this.state.doctor
            this.jsonUser.territory = this.state.territoryNew

            this.jsonUser.isComplete = true
            console.log('this.jsonUser', this.jsonUser);
            global.userData.userData.acd.personal_details = this.jsonUser
            AsyncStorage.setItem("@unSyncedUserData", JSON.stringify(global.userData) )

            console.log('global.userData.userData.acd.personal_details', global.userData.userData.acd.personal_details);
            console.log('global.userData.userData.acd', global.userData.userData.acd);

            this.props.navigation.navigate("AdvanceCareNoteNorthern")
          }else{
            alert("Birthday should be in format DD/MM/YYYY")
          }
        }else{
          console.log('Birthday should be in format DD/MM/YYYY');
          alert("Birthday should be in format DD/MM/YYYY")
        }
        console.log('broken', broken);
      }else{
        alert("Birthday Is Missing")
      }
    }

  }

  componentWillMount(){
    Dimensions.addEventListener("change", this.handler);
  }

  componentWillUnmount(){
    Dimensions.removeEventListener("change", this.handler);
  }
  handler =(e) =>{
  console.log('eee', e);
  this.setState({winHeight: e.window.height})
  }
  navigateProfile = () =>{
    console.log('in UploadImage');
    this.props.navigation.navigate("UploadImage")
  }

  getEnums = async ()=>{
    const value = await AsyncStorage.getItem('@allTerritories');
    let parsedTerrit = ''
    if(value !== '' && value !== undefined && value !== null){
      parsedTerrit = JSON.parse(value)
    }else{
      parsedTerrit = this.state.allTerritories
      AsyncStorage.setItem('@allTerritories', this.state.allTerritories);
    }
    let dropdowns = []
    parsedTerrit.map(x =>{
      if(x.abbreviation === 'SA' || x.abbreviation === 'NT'){

      let array =

      <TouchableOpacity onPress={() => this.handleDropDownValue(x)} style={{width:'100%', marginLeft: 10,  alignItems: 'center', justifyContent: 'center'}}>
      <View style={{width:"100%", alignItems: 'center',height:30, backgroundColor:'#fff',justifyContent: 'center'}}>
      <View style={{width:'90%'}}>
      <Text style={styles.descriptionText}>{x.name}</Text>
      </View>
      </View>
      </TouchableOpacity>

      dropdowns.push(array)
    }
    })

    this.setState({allTerritoriesDropdown: dropdowns})

  }

  handleDropDown =()=>{
    this.setState({
      dropDownState: !this.state.dropDownState
    })
  }

  handleDropDownValue = (e) =>{

    this.setState({territory: e.name, territoryNew: e.name, territoryMain:e._id, dropDownState: !this.state.dropDownState})
    console.log('e.name', e.name, );
  }

  render() {
    return (

      <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>
        <NavigationEvents
          onWillFocus={() => this.onWillFocus()}
          onDidFocus={payload => console.log('did focus', payload)}
          onWillBlur={payload => console.log('will blur', payload)}
          onDidBlur={payload => console.log('did blur', payload)}
          />

          {

            this.state.loadingData
            ?
            <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>

            <ActivityIndicator size="large" color="#9126fb" />
          </View>

            :
          <KeyboardAwareScrollView
            style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'75%',width:'100%'}]:[{height:'70%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}
            enableOnAndroid
            >
            <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>


          <View style = {global.mainInsideContainer}>
            {this.state.UserComponent}

            <View style={{width:"90%"}}>


            {!this.state.editName
              ?<UserDetail data = {this.state.name} title="Full Name" icon='name'/>
              :<View style = {styles.inputField}>

                <TextInput
maxLength={(this.jsonData)?(this.jsonData.wordLimit != undefined) ? this.jsonData.wordLimit : 999:null}
                  editable
                  style={styles.textInputStyle}
                  onChangeText={name => this.setState({ name })}
                  value={this.state.name}
                  />
                  <NoButton title = "Save" onPress={()=>this.handleSaveState("name")}/>

              </View>
            }

            {!this.state.editEmail
              ?<UserDetail data = {this.state.email} title="Email" icon='email' />
              :<View style = {styles.inputField}>

                <TextInput
maxLength={(this.jsonData)?(this.jsonData.wordLimit != undefined) ? this.jsonData.wordLimit : 999:null}
                  editable
                  style={styles.textInputStyle}
                  onChangeText={email => this.setState({ email })}
                  keyboardType = "email-address"
                  value={this.state.email}
                  />
                  <NoButton title = "Save" onPress={()=>this.handleSaveState("email")}/>
              </View>
            }

            {!this.state.editBirthday
              ?<UserDetail data = {this.state.birthday} title="Birthday" icon='birthday' />
              :<View style = {styles.inputField}>


              {
              Platform.OS !== 'web'
              ?
              <DatePicker
                maxDate = {this.maxDate}
                date={this.state.birthday}
                placeholder= " "
                showIcon={false}
                style={{
                  borderWidth:0,
                  borderColor:"transparent",

                }}
                customStyles={{
                  dateInput: {
                    position: 'absolute',
                    left: 0,
                    marginLeft: 0,
                    borderWidth:0,
                    borderColor:"transparent",
                  }
                }}
                mode="date"
                format="DD/MM/YYYY"
                confirmBtnText="Confirm"
                cancelBtnText="Cancel"
                onDateChange={(birthday) => {this.setState({birthday: birthday})}}
                />

              :<TextInput
maxLength={(this.jsonData)?(this.jsonData.wordLimit != undefined) ? this.jsonData.wordLimit : 999:null}
                editable
                ref={(ip) => this.birthday = ip}
                style={styles.textInputStyle}
                placeholder="DD/MM/YYYY"
                onChangeText={birthday => this.setState({ birthday })}
                value={this.state.birthday}
                />
            }


                  <NoButton title = "Save" onPress={()=>this.handleSaveState("birthday")}/>

              </View>
            }

              <UserDetail data = {this.state.territory} title="Territory" icon='territory' onPress={()=>this.handleDropDown("territory")}/>

              {this.state.dropDownState == false ? null
              :
              <View style = {{ display:'flex', flexDirection:'column',width:'100%', }}>

                {this.state.allTerritoriesDropdown}

              </View>
              }

            {!this.state.editAddress
              ?
              <UserDetail data = {this.state.address} title="Address" icon='address' onPress={()=>this.handleDetailPress("address")}/>
              :<View style = {styles.inputField}>

                <TextInput
maxLength={(this.jsonData)?(this.jsonData.wordLimit != undefined) ? this.jsonData.wordLimit : 999:null}
                  editable
                  style={styles.textInputStyle}
                  onChangeText={address => this.setState({ address })}
                  value={this.state.address}
                  />
                  <NoButton title = "Save" onPress={()=>this.handleSaveState("address")}/>

              </View>
            }
            {!this.state.editMobile
              ?<UserDetail data ={this.state.mobile} title="Mobile" icon='phone' onPress={()=>this.handleDetailPress("mobile")}/>
              :<View style = {styles.inputField}>

                <TextInput
maxLength={(this.jsonData)?(this.jsonData.wordLimit != undefined) ? this.jsonData.wordLimit : 999:null}
                  editable
                  style={styles.textInputStyle}
                  keyboardType = "phone-pad"
                  onChangeText={mobile => this.setState({ mobile })}
                  value={this.state.mobile}
                  />
                  <NoButton title = "Save" onPress={()=>this.handleSaveState("mobile")}/>

              </View>
            }
            {!this.state.editGP
              ?<UserDetail data = {this.state.doctor} title="GP" icon='gp' onPress={()=>this.handleDetailPress("gp")}/>
              :<View style = {styles.inputField}>

                <TextInput
maxLength={(this.jsonData)?(this.jsonData.wordLimit != undefined) ? this.jsonData.wordLimit : 999:null}
                  editable
                  style={styles.textInputStyle}
                  onChangeText={doctor => this.setState({ doctor })}
                  value={this.state.doctor}
                  />
                {/* <Text style= {styles.textstyle}>GP</Text> */}
                  <NoButton title = "Save" onPress={()=>this.handleSaveState("gp")}/>

              </View>
            }
            </View>
              <Text style= {{width:'100%', textAlign:'center', paddingTop:20, paddingBottom:20, fontSize:10, color:"#1D2195"}}>
                You can edit your details by clicking the relevant field (v0.3.4)
              </Text>


          </View>
        </View>
      </KeyboardAwareScrollView>

    }

          <ScrollView scrollEnabled={false} style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'25%', width:'100%'}]:[{height:'30%', width:'100%', maxWidth:800, minWidth:800}]}
            contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
            <CustomButton title = "➔" navigation = {this.props.navigation} onPress= {this.handleCheckTerritory}/>
                <ACDfooter  hidePurple = {true}  />

          </ScrollView>

      </View>

    );

  }
}

const styles = StyleSheet.create({
  buttonContainer:{
    alignItems:'center',
    marginTop:50,
    marginBottom:10,
    width:'90%',
    // justifyContent:'center',

  },
  loginButton:{
    borderRadius:5,

  },
  arrow:{
    fontSize: 20,
  },
  inputField:{
    width:'100%',
    flexDirection:'row',
    height:50,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    marginBottom:8,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'
  },
  textInputStyle:{
    height: 40,
    width:'78%',
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',
  },
  textstyle:{
    alignItems:'center',
    justifyContent:'center',
    color:'#1D2195',
    fontSize:12,
    textAlign:'center'
  },

})
