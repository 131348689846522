import React, { Component } from "react";
import {
  View,
  Text,
  StyleSheet,
  AsyncStorage,
  ScrollView,
  TextInput,
  Platform,
  ActivityIndicator,
  Linking,
  TouchableOpacity,
  Dimensions,
  Alert,
  FlatList,
} from "react-native";
import global from "../../Styles/global";
import UserComponent from "../../Components/UserComponent";
import { Button, Icon, SearchBar } from "react-native-elements";
import CustomButtonContacts from "../../Components/CustomButtonContacts";
import CustomButton from "../../Components/CustomButton";
import { NavigationEvents } from "react-navigation";
import NoButton from "../../Components/NoButton";
import { GooglePlacesAutocomplete } from "react-native-google-places-autocomplete";
import {
  GoogleAutoComplete,
  GoogleLocationDetailResult,
  GoogleLocationResult,
} from "react-native-google-autocomplete";
import { Avatar } from "react-native-elements";
import axios from "axios";
import * as ImagePicker from "expo-image-picker";
import openMap from "react-native-open-maps";

import * as Permissions from "expo-permissions";

// import MapView, { PROVIDER_GOOGLE, Marker } from "react-native-maps";
import * as Location from "expo-location";
import UserDetail from "../../Components/UserDetail";
import MapInput from "./MapInput";

const homePlace = {
  description: "Home",
  geometry: { location: { lat: 48.8152937, lng: 2.4597668 } },
};
const workPlace = {
  description: "Work",
  geometry: { location: { lat: 48.8496818, lng: 2.2940881 } },
};

export default class ContactsSingleProvider extends Component {
  constructor() {
    super();
    this.state = {
      search: "",
      winHeight: Dimensions.get("window").height,
      predictions: [],
      UserComponent: <ActivityIndicator size="small" color="#9126fb" />,
    };
    // global.userData = require('../../../Data/northern_acd.json')
    global.jsonDatatest.forEach((i, idx, x) => {
      if (i.title == "Anticipatory prescribing tool") {
        this.jsonData = i;
      }
    });
    this.timeout = 0;
    this.getLocalKeys();
  }

  getCoordsFromName(loc) {
    console.log("loc", loc);
    this.setState({
      region: {
        latitude: loc.lat,
        longitude: loc.lng,
        latitudeDelta: 0.003,
        longitudeDelta: 0.003,
      },
    });
  }
  _goToYosemite = () => {
    console.log("navigating");
    openMap({
      latitude: this.state.lat,
      longitude: this.state.lng,
      end: this.state.mapAddress,
      provider: "google",
    });
  };

  getLocalKeys = async () => {
    const value = await AsyncStorage.getItem("@UserData");
    this.token = await AsyncStorage.getItem("@token");

    if (value !== null) {
      var data = JSON.parse(value);
      console.log("UserData :: ", data);

      this.setState({ userName: data.name });
      this.setState({ userAddress: data.address });
      console.log("====================================");
      console.log("Data::::::", data);
      console.log("====================================");
      this.setState({
        UserComponent: (
          <UserComponent
            onPress={() => this.navigateProfile()}
            name={data.name}
            address={data.address}
            profileImg={data.profileImg}
          />
        ),
      });
      console.log("params log", this.props.navigation.state.params);
      if (this.props.navigation.state.params) {
        this.setFromExistingData(this.props.navigation.state.params.data);
      } else {
        // this.setInitial()
      }
      console.log("====================================");
      console.log("Data:::::: after setstate", data);
      console.log("====================================");
    }
  };
  navigateProfile = () => {
    console.log("in UploadImage");
    this.props.navigation.navigate("UploadImageContact");
  };

  setInitial = async () => {
    var locationAwait = await Location.requestPermissionsAsync();
    let location = await Location.getCurrentPositionAsync({});

    console.log("location ::::: 1212", location);
    let region = {
      latitude: location.coords.latitude,
      longitude: location.coords.longitude,
      latitudeDelta: 0.0043,
      longitudeDelta: 0.0034,
    };
    let locationPlace = await Location.reverseGeocodeAsync({
      latitude: location.coords.latitude,
      longitude: location.coords.longitude,
    });
    this.setState({
      lat: location.coords.latitude,
      lng: location.coords.longitude,
    });
    console.log("locationPlace :::::", locationPlace);

    let exactName = !locationPlace[0].name ? "" : locationPlace[0].name + " , ";

    let exactStreet = !locationPlace[0].street
      ? ""
      : locationPlace[0].street + " , ";

    let exactCity = !locationPlace[0].city ? "" : locationPlace[0].city + " , ";

    let exactRegion = !locationPlace[0].region
      ? ""
      : locationPlace[0].region + " , ";

    let exactCountry = !locationPlace[0].country
      ? ""
      : locationPlace[0].country;

    let exactLocation = exactName + exactStreet + exactCity + exactCountry;
    this.setState({ address: exactLocation });

    console.log("!! Region", region);
    this.setState({
      initialRegion: region,
    });
  };

  onWillFocus = () => {
    this.getLocalKeys();
  };

  handleDetailPress = (e) => {
    console.log(e, " Pressed");
    if (e == "email") {
      this.setState({ editEmail: true });
      this.setState({ editNumber: false });
      this.setState({ editNote: false });
      this.setState({ editMapAddress: false });
    } else if (e == "number") {
      this.setState({ editEmail: false });
      this.setState({ editNumber: true });
      this.setState({ editNote: false });
      this.setState({ editMapAddress: false });
    } else if (e == "note") {
      this.setState({ editEmail: false });
      this.setState({ editNumber: false });
      this.setState({ editNote: true });
      this.setState({ editMapAddress: false });
    } else if (e == "mapAddress") {
      this.setState({ editEmail: false });
      this.setState({ editNumber: false });
      this.setState({ editNote: false });
      this.setState({ editMapAddress: true });
    }
  };

  onRegionChange = async (region) => {
    console.log("region", region);

    let locationPlace = await Location.reverseGeocodeAsync({
      latitude: region.latitude,
      longitude: region.longitude,
    });

    console.log("locationPlace :::::", locationPlace);

    let exactName = !locationPlace[0].name ? "" : locationPlace[0].name + " , ";

    let exactStreet = !locationPlace[0].street
      ? ""
      : locationPlace[0].street + " , ";

    let exactCity = !locationPlace[0].city ? "" : locationPlace[0].city + " , ";

    let exactRegion = !locationPlace[0].region
      ? ""
      : locationPlace[0].region + " , ";

    let exactCountry = !locationPlace[0].country
      ? ""
      : locationPlace[0].country;

    let exactLocation = exactName + exactStreet + exactCity + exactCountry;
    console.log("exactLocation", exactLocation);
    this.setState({ address: exactLocation });
    // this.state.region.setValue(region);
  };

  handleSaveState = (e) => {
    console.log(e, " Pressed");
    const nameRegex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g;

    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (e == "email") {
      if (!emailRegex.test(this.state.email)) {
        if (this.state.email == undefined || this.state.email == "") {
          alert("Email is required");
        } else {
          alert("Invalid Email");
        }
      } else {
        this.setState({ editEmail: !this.state.editEmail });
        this.setState({ editNumber: false });
        this.setState({ editNote: false });
        this.setState({ editMapAddress: false });
      }
    } else if (e == "number") {
      this.setState({ editEmail: false });
      this.setState({ editNumber: !this.state.editNumber });
      this.setState({ editNote: false });
      this.setState({ editMapAddress: false });
    } else if (e == "note") {
      this.setState({ editEmail: false });
      this.setState({ editNumber: false });
      this.setState({ editMapAddress: false });

      this.setState({ editNote: !this.state.editNote });
    } else if (e == "mapAddress") {
      this.setState({ editEmail: false });
      this.setState({ editNumber: false });
      this.setState({ editMapAddress: !this.state.editMapAddress });
      this.setState({ editNote: false });
    }
  };

  addChecks = () => {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!emailRegex.test(this.state.email)) {
      if (this.state.email == undefined || this.state.email == "") {
        alert("Email is required");
        return;
      } else {
        alert("Invalid Email");
        return;
      }
    }
    if (this.state.mapAddress && !this.state.lat) {
      this.getCurrentLatlng();
    } else {
      if (
        this.state.email &&
        this.state.name &&
        this.state.number &&
        this.state.mapAddress &&
        this.state.note &&
        this.state.lat &&
        this.state.lng
      ) {
        if (this.props.navigation.state.params) {
          this.updateContact(this.props.navigation.state.params.data);
        } else {
          this.addContact();
        }
      } else {
        alert("Fill up all fields");
      }
    }
  };

  getCurrentLatlng = async () => {
    var locationAwait = await Location.requestPermissionsAsync();
    let location = await Location.getCurrentPositionAsync({});

    console.log("location ::::: 1313", location);
    let region = {
      latitude: location.coords.latitude,
      longitude: location.coords.longitude,
      latitudeDelta: 0.0043,
      longitudeDelta: 0.0034,
    };
    let locationPlace = await Location.reverseGeocodeAsync({
      latitude: location.coords.latitude,
      longitude: location.coords.longitude,
    });
    this.setState({
      lat: location.coords.latitude,
      lng: location.coords.longitude,
    });
    console.log("locationPlace :::::", locationPlace);

    let exactName = !locationPlace[0].name ? "" : locationPlace[0].name + " , ";

    let exactStreet = !locationPlace[0].street
      ? ""
      : locationPlace[0].street + " , ";

    let exactCity = !locationPlace[0].city ? "" : locationPlace[0].city + " , ";

    let exactRegion = !locationPlace[0].region
      ? ""
      : locationPlace[0].region + " , ";

    let exactCountry = !locationPlace[0].country
      ? ""
      : locationPlace[0].country;

    let exactLocation = exactName + exactStreet + exactCity + exactCountry;
    // locationPlace[0].name + ' , ' + locationPlace[0].street+ ' , ' +locationPlace[0].city+ ' , ' +locationPlace[0].region+ ' , ' +locationPlace[0].country
    this.setState({ mapAddress: exactLocation });
    if (
      this.state.email &&
      this.state.name &&
      this.state.number &&
      exactLocation &&
      this.state.note &&
      location.coords.latitude &&
      location.coords.longitude
    ) {
      if (this.props.navigation.state.params) {
        this.updateContact(this.props.navigation.state.params.data);
      } else {
        this.addContact();
      }
    } else {
      alert("Fill up all fields");
    }
  };

  updateContact = (e) => {
    this.setState({ activityProcess: true });
    let optionsAxios = "";
    optionsAxios = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.parse(this.token),
      },
      url: global.url + "/api/updateContact",
      data: {
        contactId: e._id,
        address: this.state.mapAddress,
        name: this.state.name,
        mobile: this.state.number,
        email: this.state.email,
        note: this.state.note,
        lat: this.state.lat,
        lng: this.state.lng,
      },
    };

    console.log(" newContact Options axios ", optionsAxios);
    axios(optionsAxios)
      .then((response) => {
        console.log("/new contact Response recieved ", response);
        if (response.status === 200) {
          console.log("response", response);
          if (this.state.newImg) {
            this.handleUploadImage(response.data.contact);
          } else {
            this.setState({ activityProcess: false });

            this.props.navigation.navigate("ContactsMain");
          }
        } else {
          this.setState({ activityProcess: false });

          console.log(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ activityProcess: false });

        console.log("error", error);
        console.log("error", error.response);
      });
  };

  addContact = () => {
    this.setState({ activityProcess: true });
    let optionsAxios = "";
    optionsAxios = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.parse(this.token),
      },
      url: global.url + "/api/addContact",
      data: {
        address: this.state.mapAddress,
        name: this.state.name,
        mobile: this.state.number,
        email: this.state.email,
        note: this.state.note,
        lat: this.state.lat,
        lng: this.state.lng,
      },
    };

    console.log(" newContact Options axios ", optionsAxios);
    axios(optionsAxios)
      .then((response) => {
        console.log("/new contact Response recieved ", response);
        if (response.status === 200) {
          console.log("response", response);
          if (this.state.newImg) {
            this.handleUploadImage(response.data.contact);
          } else {
            this.props.navigation.navigate("ContactsMain");
            this.setState({ activityProcess: false });
          }
        } else {
          this.setState({ activityProcess: false });

          console.log(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ activityProcess: false });

        console.log("error", error);
        console.log("error", error.response);
      });
  };

  pickImage = async () => {
    console.log("Alert");
    if (Platform.OS !== "web") {
      Alert.alert(
        "Alert",
        "Select option to upload file",
        [
          { text: "Cancel", onPress: () => console.log("Cancel Pressed") },
          {
            text: "Upload from Gallery",
            onPress: () => this.uploadFromGallery(),
            style: "cancel",
          },
          { text: "Open Camera", onPress: () => this.uploadFromCamera() },
        ],
        { cancelable: true }
      );
    } else {
      this.uploadFromGallery();
    }
  };

  uploadFromGallery = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      base64: false,
    });
    console.log(result);

    if (!result.cancelled) {
      this.setState({ img: result.uri, newImg: true, showButton: true });
    }
  };

  uploadFromCamera = async () => {
    let result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      base64: false,
    });

    console.log(result);

    if (!result.cancelled) {
      this.setState({ img: result.uri, newImg: true, showButton: true });
    }
  };

  componentDidMount() {
    this.getLocationAsync();
  }
  async getLocationAsync() {
    // permissions returns only for location permissions on iOS and under certain conditions, see Permissions.LOCATION
    const { status, permissions } = await Permissions.askAsync(
      Permissions.LOCATION
    );
    if (status === "granted") {
      return Location.getCurrentPositionAsync({ enableHighAccuracy: true });
    } else {
      throw new Error("Location permission not granted");
    }
  }

  handleUploadImage = async (contact) => {
    console.log("this.state.img", this.state.img);

    this.setState({ activityProcess: true });

    console.log("this.state.img", this.state.img);

    let bodyData = await new FormData();
    if (Platform.OS === "ios" || Platform.OS === "android") {
      bodyData.append("userFile", {
        uri: this.state.img,
        type: "image/jpeg", // or photo.type
        name: "upload.png",
      });
      console.log("url", global.url + "/api/uploadContactImage/" + contact._id);

      console.log("token", JSON.parse(this.token));
      fetch(global.url + "/api/uploadContactImage/" + contact._id, {
        method: "POST",
        headers: { Authorization: JSON.parse(this.token) },
        body: bodyData,
      })
        .then(async (response) => {
          let newResponse = await response;
          console.log("###responce picture post", newResponse);
          if (response.status == 200) {
            this.setState({ activityProcess: false });

            console.log("in response.status 200");
            this.props.navigation.navigate("ContactsMain");
          }
        })
        .catch((err) => console.log("###error api", err));
    } else {
      bodyData.append("base64Image", this.state.img);
      console.log("url", global.url + "/api/uploadContactImage/" + contact._id);

      console.log("token", JSON.parse(this.token), this.state.img);
      fetch(global.url + "/api/uploadContactImage/" + contact._id, {
        method: "POST",
        headers: {},
        body: bodyData,
      })
        .then(async (response) => {
          let newResponse = await response;
          console.log("###responce picture post", newResponse);
          if (response.status == 200) {
            console.log("in response.status 200");
            this.setState({ activityProcess: false });

            this.props.navigation.navigate("ContactsMain");
          }
        })
        .catch((err) => console.log("###error api", err));
    }
  };
  onSelectAddress = (e) => {
    console.log("e is e", e);
  };
  handleDelete = () => {
    if (this.props.navigation.state.params) {
      this.setState({ activityProcess: true });
      let optionsAxios = "";
      optionsAxios = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(this.token),
        },
        url: global.url + "/api/removeContact",
        data: { contactId: this.props.navigation.state.params.data._id },
      };
      console.log(" delete Options axios ", optionsAxios);
      axios(optionsAxios)
        .then((response) => {
          console.log("/delete contact Response recieved ", response);
          if (response.status === 200) {
            console.log("response", response);
            this.setState({ activityProcess: false });

            this.props.navigation.navigate("ContactsMain");
          } else {
            this.setState({ activityProcess: false });

            console.log(response.data.message);
          }
        })
        .catch((error) => {
          this.setState({ activityProcess: false });
          console.log("error", error);
          console.log("error", error.response);
        });
    } else {
    }
  };
  // "Islamabad, Pakistan"
  searchLocation = (e) => {
    console.log("received string is", e);
    let toSearch = e.replace(" ", "%");
    console.log("changed string is", toSearch);
    let optionsAxios = "";
    optionsAxios = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url:
        "https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=" +
        toSearch +
        "&inputtype=textquery&fields=photos,formatted_address,name,rating,opening_hours,geometry&key=AIzaSyCpNLjzuWzWArD9W4-y78l0yFwBHkn4MEw",
    };
    console.log(" location Options axios ", optionsAxios);
    axios(optionsAxios)
      .then((response) => {
        console.log("/new location Response recieved ", response);
        if (response.status === 200) {
          if (response.data.candidates.length > 0) {
            this.setState({ locationAnalyzed: response.data.candidates[0] });
            this.setState({ editMapAddress: false, dropDownState: false });
            this.setMapView(response.data.candidates[0]);
          } else if (response.data.error_message) {
            this.setState({ editMapAddress: false, dropDownState: false });

            alert("Cannot find lat lng");
          }
          console.log("response", response);
        } else {
          this.setState({ editMapAddress: false, dropDownState: false });

          console.log(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ editMapAddress: false, dropDownState: false });
        console.log("error", error);
        console.log("error", error.response);
      });
  };

  searchPredictions = () => {
    let e = this.state.mapAddress;
    console.log("received string is", e);
    let toSearch = e.replace(" ", "%");
    console.log("changed string is", toSearch);

    let optionsAxios = "";
    optionsAxios = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url:
        "https://maps.googleapis.com/maps/api/place/autocomplete/json?input=" +
        toSearch +
        "&inputtype=textquery&fields=photos,formatted_address,name,rating,opening_hours,geometry&key=AIzaSyCpNLjzuWzWArD9W4-y78l0yFwBHkn4MEw",
    };
    console.log(" location Options axios ", optionsAxios);
    axios(optionsAxios)
      .then((response) => {
        console.log("/new location Response recieved ", response);
        if (response.status === 200) {
          if (response.data.predictions.length > 0) {
            this.setState({ predictions: response.data.predictions });
          } else {
            let noPred = [{ noFilter: "No Location Found" }];
            this.setState({ predictions: noPred });
          }
          console.log("response", response);
        } else {
          console.log(response.data.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        console.log("error", error.response);
      });
  };

  handleChange = (e) => {
    console.log("e", e);
    this.setState({ mapAddress: e });
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {}, 1000);
  };

  handleDropDown = () => {
    this.setState({
      dropDownState: !this.state.dropDownState,
    });
  };

  handleDropDownValue = (e) => {
    this.setState({ mapAddress: e, dropDownState: !this.state.dropDownState });
    this.searchLocation(e);
  };

  setMapView = async (e) => {
    console.log("lat lng received", e);
    this.setState({ mapAddress: e.formatted_address });
    this.setState({
      lat: e.geometry.location.lat,
      lng: e.geometry.location.lng,
    });

    this.setState({ address: e.address });
    let region = {
      latitude: e.geometry.location.lat,
      longitude: e.geometry.location.lng,
      latitudeDelta: 0.0043,
      longitudeDelta: 0.0034,
    };
    this.setState({
      region: region,
    });
  };

  setFromExistingData = async (e) => {
    this.setState({
      lat: e.lat,
      lng: e.lng,
      name: e.name,
      email: e.email,
      number: e.mobile,
      note: e.note,
      img: e.img,
    });

    this.setState({ mapAddress: e.address });
    let region = {
      latitude: e.lat,
      longitude: e.lng,
      latitudeDelta: 0.0043,
      longitudeDelta: 0.0034,
    };
    this.setState({
      region: region,
    });
  };
  toTop = () => {
    this.props.navigation.popToTop();
  };

  handleDial = (number) => {
    let phoneNumber = "";
    if (Platform.OS === "android") {
      phoneNumber = `tel:${number}`;
    } else {
      phoneNumber = `telprompt:${number}`;
    }
    Linking.openURL(phoneNumber);
  };

  render() {
    return (
      <View
        style={
          ([global.container],
          {
            height: this.state.winHeight,
            maxHeight: 800,
            justifyContent: "center",
            alignItems: "center",
          })
        }
      >
        {this.state.activityProcess ? (
          <View
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ActivityIndicator size="large" color="#1D2195" />
          </View>
        ) : (
          <View>
            <ScrollView
              style={
                Platform.OS === "ios" || Platform.OS === "android"
                  ? [{ height: "50%", width: "100%" }]
                  : [{ height: "50%", width: "100%" }]
              }
              contentContainerStyle={{
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <View
                style={
                  Platform.OS === "ios" || Platform.OS === "android"
                    ? [global.ItemsContainerMobile, { marginBottom: 20 }]
                    : [global.ItemsContainerWeb, { maxHeight: 1500 }]
                }
              >
                <NavigationEvents
                  onWillFocus={() => this.onWillFocus()}
                  onDidFocus={(payload) => console.log("did focus", payload)}
                  onWillBlur={(payload) => console.log("will blur", payload)}
                  onDidBlur={(payload) => console.log("did blur", payload)}
                />

                <View style={global.mainInsideContainer}>
                  <TouchableOpacity style={{ width: "100%" }}>
                    <View
                      style={
                        Platform.OS === "ios" || Platform.OS === "android"
                          ? [
                              {
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                                minHeight: 80,
                                marginBottom: 20,
                                backgroundColor: "#F9F1FF",
                                borderColor: "#BCE0FD",
                                borderWidth: 2,
                                justifyContent: "space-between",
                              },
                            ]
                          : [
                              {
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                                minHeight: 80,
                                marginBottom: 20,
                                backgroundColor: "#F9F1FF",
                                borderColor: "#BCE0FD",
                                borderWidth: 2,
                                justifyContent: "space-between",
                                marginTop: 10,
                              },
                            ]
                      }
                    >
                      <View
                        style={{
                          width: "80%",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <TouchableOpacity
                          style={
                            Platform.OS === "ios" || Platform.OS === "android"
                              ? [
                                  {
                                    paddingLeft: 10,
                                    width: "30%",
                                    maxWidth: 100,
                                    flexDirection: "row",
                                  },
                                ]
                              : [
                                  {
                                    width: "30%",
                                    maxWidth: 100,
                                    flexDirection: "row",
                                  },
                                ]
                          }
                        >
                          <Avatar
                            size="large"
                            rounded
                            source={{
                              uri: this.state.img,
                            }}
                            containerStyle={{
                              marginLeft: 4,
                              height: 65,
                              width: 65,
                            }}
                          />
                        </TouchableOpacity>
                        <View
                          style={{
                            width: "70%",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            flexDirection: "column",
                          }}
                        >
                          <Text
                            numberOfLines={1}
                            ellipsizeMode="tail"
                            style={styles.textInputStyleName}
                          >
                            {this.state.name}
                          </Text>
                          <Text
                            numberOfLines={4}
                            ellipsizeMode="tail"
                            style={{ color: "#1D2195", fontSize: 12 }}
                          >
                            {this.state.mapAddress}
                          </Text>
                        </View>
                      </View>
                      <View
                        style={{
                          width: "20%",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      ></View>
                    </View>
                  </TouchableOpacity>

                  <View style={{ flex: 1, width: "100%" }}></View>

                  <View
                    style={{
                      width: "95%",
                      paddingTop: 5,
                      paddingBottom: 10,
                      alignItems: "center",
                      justifyContent: "center",
                      alignSelf: "center",
                    }}
                  >
                    <UserDetail
                      data={this.state.number}
                      title="Mobile"
                      icon="phone"
                      onPress={() => this.handleDial(this.state.number)}
                    />

                    <UserDetail
                      data={this.state.email}
                      title="Email"
                      icon="email"
                      onPress={() => this.handleDetailPress("email")}
                    />

                    <UserDetail
                      data={this.state.mapAddress}
                      title="Address"
                      icon="address"
                      onPress={() => this.handleDetailPress("mapAddress")}
                    />
                    {this.state.dropDownState == false ? null : (
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FlatList
                          data={this.state.predictions}
                          initialNumToRender={10}
                          renderItem={({ item, index }) => (
                            <>
                              {item.noFilter ? (
                                <Text
                                  numberOfLines={1}
                                  ellipsizeMode="tail"
                                  style={[
                                    styles.label,
                                    {
                                      marginTop: 10,
                                      marginLeft: "5%",
                                      fontSize: 18,
                                    },
                                  ]}
                                >
                                  {item.noFilter}
                                </Text>
                              ) : (
                                <TouchableOpacity
                                  onPress={() =>
                                    this.handleDropDownValue(item.description)
                                  }
                                  style={{
                                    width: "100%",
                                    marginTop: 3,
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <View
                                    style={{
                                      width: "100%",
                                      alignItems: "center",
                                      minHeight: 30,
                                      backgroundColor: "#F9F1FF",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <View
                                      style={{
                                        width: "90%",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Text
                                        ellipsizeMode="tail"
                                        style={styles.descriptionText}
                                      >
                                        {item.description}
                                      </Text>
                                    </View>
                                  </View>
                                </TouchableOpacity>
                              )}
                            </>
                          )}
                          keyExtractor={(item) => item.id}
                        />
                      </View>
                    )}

                    <UserDetail
                      data={this.state.note}
                      title="Note"
                      icon="note1"
                      onPress={() => this.handleDetailPress("note")}
                    />
                  </View>
                </View>
              </View>
            </ScrollView>

            <ScrollView
              scrollEnabled={false}
              style={
                Platform.OS === "ios" || Platform.OS === "android"
                  ? [{ height: "50%", width: "100%" }]
                  : [
                      {
                        height: "50%",
                        width: "100%",
                        maxWidth: 800,
                        minWidth: 800,
                      },
                    ]
              }
              contentContainerStyle={[
                global.bottom1,
                {
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                },
              ]}
            >{
              Platform.OS !== 'web' &&
              <MapView
                minZoomLevel={16}
                maxZoomLevel={25}
                clusterColor="#8b0000"
                provider={PROVIDER_GOOGLE}
                style={[styles.mapStyle, { height: "70%", marginTop: "-10%" }]}
                initialSnap={10}
                ref={(map) => {
                  this.map = map;
                }}
                showsUserLocation={true}
                showsMyLocationButton={true}
                initialRegion={this.state.initialRegion}
                region={this.state.region}
                onRegionChange={(region) => this.onRegionChange(region)}
              >
                <Marker
                  coordinate={{
                    latitude: this.state.lat,
                    longitude: this.state.lng,
                  }}
                  title={this.state.mapAddress}
                  onPress={this._goToYosemite}
                />
              </MapView>}
              <CustomButton
                title="Home"
                type="multiples"
                color="#F9F1FF"
                navigation={this.props.navigation}
                onPress={this.toTop}
              />
            </ScrollView>
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },

  inputField: {
    width: "90%",
    flexDirection: "row",
    height: 50,
    marginBottom: 16,
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 10,
    justifyContent: "space-between",
    borderWidth: 0.5,
    borderColor: "#1D2195",
  },
  textInputStyle: {
    height: 40,
    width: "78%",
    marginBottom: 5,
    alignSelf: "flex-end",
    borderColor: "transparent",
    color: "#1D2195",
    fontSize: 16,
  },
  textInputStyleName: {
    borderColor: "transparent",
    fontSize: 16,
    color: "#1D2195",
    marginBottom: 2,
    paddingRight: "1%",
    fontWeight: "bold",
  },
  textSmall: {
    width: "100%",
    alignItems: "flex-start",
    marginTop: 50,
    color: "#1D2195",
  },
  textBold: {
    width: "100%",
    alignItems: "flex-start",
    marginTop: 50,
    color: "#1D2195",
    fontWeight: "bold",
  },
  descriptionText: {
    width: "90%",
    marginLeft: 10,
    marginRight: 10,
    textAlign: "left",
    color: "#1D2195",
    lineHeight: 20,
  },
  mapStyle: {
    width: Dimensions.get("window").width,
  },
});
