import React, {Component} from 'react';
import { View,Text,StyleSheet, AsyncStorage,ScrollView, TextInput, Platform, ActivityIndicator, TouchableOpacity, Dimensions, SafeAreaView, SectionList } from 'react-native';
import global from "../../Styles/global";
import UserComponent from '../../Components/UserComponent'
import {Button, Icon} from 'react-native-elements'
import CustomButton from '../../Components/CustomButton';
import { NavigationEvents } from 'react-navigation';
import NoButton from '../../Components/NoButton'
import Constants from 'expo-constants';
import axios from 'axios';
import CustomButtonContacts from '../../Components/CustomButtonContacts';
import { Avatar } from 'react-native-elements';

// import * as Contacts from 'expo-contacts';
// import MapView,{ PROVIDER_GOOGLE, Marker } from 'react-native-maps';
import * as Location from 'expo-location';
import UserDetail from '../../Components/UserDetail'
import * as Permissions from 'expo-permissions';


const ITEM_HEIGHT = 50;

function Item({ title }) {
  return (
    <View style={styles.item}>
      <Text style={styles.title}>{title}</Text>
    </View>
  );
}


const DATA = [
  {
    title: 'Main dishes',
    data: ['Pizza', 'Burger', 'Risotto'],
  },
  {
    title: 'Sides',
    data: ['French Fries', 'Onion Rings', 'Fried Shrimps'],
  },
  {
    title: 'Drinks',
    data: ['Water', 'Coke', 'Beer'],
  },
  {
    title: 'Desserts',
    data: ['Cheese Cake', 'Ice Cream'],
  },
  {
    title: 'Desserts',
    data: ['Cheese Cake', 'Ice Cream'],
  },
  {
    title: 'Desserts',
    data: ['Cheese Cake', 'Ice Cream'],
  },
  {
    title: 'Desserts',
    data: ['Cheese Cake', 'Ice Cream'],
  },
  {
    title: 'Desserts',
    data: ['Cheese Cake', 'Ice Cream'],
  },
  {
    title: 'Desserts',
    data: ['Cheese Cake', 'Ice Cream'],
  },
  {
    title: 'Desserts',
    data: ['Cheese Cake', 'Ice Cream'],
  },
  {
    title: 'Desserts',
    data: ['Cheese Cake', 'Ice Cream'],
  },
  {
    title: 'Desserts',
    data: ['Cheese Cake', 'Ice Cream'],
  },
  {
    title: 'Desserts',
    data: ['Cheese Cake', 'Ice Cream'],
  },
  {
    title: 'Desserts',
    data: ['Cheese Cake', 'Ice Cream'],
  },
  {
    title: 'Desserts',
    data: ['Cheese Cake', 'Ice Cream'],
  },
  {
    title: 'Desserts',
    data: ['Cheese Cake', 'Ice Cream'],
  },
  {
    title: 'Desserts',
    data: ['Cheese Cake', 'Ice Cream'],
  },
  {
    title: 'Desserts',
    data: ['Cheese Cake', 'Ice Cream'],
  },
  {
    title: 'Desserts',
    data: ['Cheese Cake', 'Ice Cream'],
  },
  {
    title: 'Desserts',
    data: ['Cheese Cake', 'Ice Cream'],
  },
  {
    title: 'Desserts',
    data: ['Cheese Cake', 'Ice Cream'],
  },
  {
    title: 'Desserts',
    data: ['Cheese Cake', 'Ice Cream'],
  },
  {
    title: 'Pesserts',
    data: ['Cheese Cake', 'Ice Cream'],
  },
  {
    title: 'Pesserts',
    data: ['Cheese Cake', 'Ice Cream'],
  },

];

export default class ContactsMain extends Component {
//
//   scrollRef=React.createRef()
// GetSectionListItem = item => {
//   //Function for click on an item
//   this.scrollRef.scrollToLocation({itemIndex:18})
//   Alert.alert(item);
// };
FlatListItemSeparator = () => {
  return (
    //Item Separator
    <View
      style={{ height: 0.5, width: '100%', backgroundColor: '#C8C8C8' }}
    />
  );
};
renderItemFlat ({item}) {
  // if(str === x.name.charAt(0)){
  //   console.log('pushing in', x);
  //   demoArray[str].push(x)
  // }
  let char = item.name.charAt(0)
  let char1 = ''
  if(char1 === char){
    char = ''
  }
return (
  <View style={{width: "100%"}}>
    {
      item.nameTitle
      ?
      <Text
        style = {styles.SectionHeaderStyle}
        >
        {item.nameTitle}

      </Text>
      :
null

    }

    <TouchableOpacity onPress= {() => this.handleToContact(item)} style ={{width:'95%', fontSize: 15, paddingLeft: 15, paddingTop: 15, paddingBottom:15, color: '#000', backgroundColor: '#F9F1FF', width:"100%", minHeight:10 , display:'flex', flexDirection:'row', }}>
      <Avatar
        size="large"
        rounded
        source={{
          uri: item.img
        }}
        containerStyle={{marginLeft: 1,height:35, width:35}}
        />
      <View style = {{paddingLeft:15, width:"70%", whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis', flexDirection:'column'}}>
        <Text numberOfLines={1} ellipsizeMode='tail' style={{color:'#1D2195',fontWeight:'bold' ,fontSize:20}}>{item.name}</Text>
        <Text numberOfLines={4} ellipsizeMode='tail' style={{color:'#1D2195', fontSize:10}} >{item.address}</Text>
      </View>

    </TouchableOpacity>
  </View>

);
}

FlatListItemSeparator = () => {
  return (
    <View
      style={{
        height: 1,
        width: "100%",
        backgroundColor: "#000",
      }}
    />
  );
}

keyExtractor (item) {
  return item.id;
}


  constructor(){
    super()
    this.state = {
      search:'',
      winHeight:Dimensions.get("window").height,
      contactsReceived: [],
      activityProcess:true,
      contactsList:
      {
          "A" : [],
          "B" : [],
          "C" : [],
          "D" : [],
          "E" : [],
          "F" : [],
          "G" : [],
          "H" : [],
          "I" : [],
          "J" : [],
          "K" : [],
          "L" : [],
          "M" : [],
          "N" : [],
          "O" : [],
          "P" : [],
          "Q" : [],
          "R" : [],
          "S" : [],
          "T" : [],
          "U" : [],
          "V" : [],
          "W" : [],
          "X" : [],
          "Y" : [],
          "Z" : [],
      },
      UserComponent:<ActivityIndicator size="small" color="#9126fb" />
  }
  // global.userData = require('../../../Data/northern_acd.json')
  global.jsonDatatest.forEach((i,idx,x)=>{
    if (i.title == 'Anticipatory prescribing tool') {
      this.jsonData = i
    }
  })
  console.log('height of window', Math.round(Dimensions.get('window').height));

  this.getLocalKeys()

// if(Platform.OS !== 'web'){
//   this.AlphaScrollFlatList =  require('alpha-scroll-flat-list');
//   }
}

getLocalKeys = async () =>{
  const value = await AsyncStorage.getItem('@UserData');
  this.token = await AsyncStorage.getItem('@token');

  if (value !== null) {
    var data = JSON.parse(value)
    console.log("UserData :: ", data);


    this.setState({userName:data.name})
    this.setState({userAddress:data.address})
    console.log('====================================');
    console.log("Data::::::", data);
    console.log('====================================');
    this.setState({UserComponent:<UserComponent onPress={() => this.navigateProfile()} name={data.name} address={data.address} profileImg = {data.profileImg}/>})
    // this.setInitial()
    this.getallContacts()
    console.log('====================================');
    console.log("Data:::::: after setstate", data);
    console.log('====================================');

  }
}

getallContacts = () =>{
  this.setState({activityProcess: true})
  let optionsAxios = ''
  optionsAxios = {
    method: 'POST',
    headers: {'Content-Type': 'application/json', 'Authorization': JSON.parse(this.token) },
    url: global.url+'/api/getContacts',

  }

  console.log(" allContacts Options axios ", optionsAxios );
  axios(optionsAxios)
  .then((response)=> {
    console.log("/all contacts Response recieved ", response);
    if(response.status === 200){
      console.log('response', response);
      console.log('DATA', DATA);

      this.setState({contactsReceived: response.data.contacts})
      this.setState({allContacts: response.data.contacts})
      this.contactsReceived =response.data.contacts
      this.setState({activityProcess: false})

      // this.setInitial()
      // this.formattingContacts()

    }else{
      this.setState({activityProcess: false})

      console.log(response.data.message);
    }
    // AsyncStorage.setItem("@AppData", JSON.stringify(response.data.result) )
    // global.jsonData = response.data.result
  })
  .catch((error)=>{
    console.log("error", error);
    console.log("error", error.response);
  })
}


navigateProfile = () =>{
  console.log('in UploadImage');
  this.props.navigation.navigate("UploadImageContact")
}

navigateToNew =()=>{
  this.props.navigation.navigate("ContactsSingleEditable")

}

setInitial = async () =>{

  var locationAwait = await Location.requestPermissionsAsync();
  let location = await Location.getCurrentPositionAsync({});

  console.log("location ::::: 111" , location );
  let region = {
    latitude:       location.coords.latitude,
    longitude:      location.coords.longitude,
    latitudeDelta:  0.00922*1.5,
    longitudeDelta: 0.00421*1.5
  }
  let locationPlace = await Location.reverseGeocodeAsync({latitude: location.coords.latitude, longitude: location.coords.longitude});

  console.log("locationPlace :::::" , locationPlace );

  let exactName =
  (!locationPlace[0].name ?'' : locationPlace[0].name + ' , ')

  let exactStreet =
  (!locationPlace[0].street ?'' : locationPlace[0].street + ' , ')

  let exactCity =
  (!locationPlace[0].city ?'' : locationPlace[0].city + ' , ')

  let exactRegion =
  (!locationPlace[0].region ?'' : locationPlace[0].region + ' , ')

  let exactCountry =
  (!locationPlace[0].country ?'' : locationPlace[0].country)

  let exactLocation = exactName + exactStreet + exactCity  + exactCountry
  // locationPlace[0].name + ' , ' + locationPlace[0].street+ ' , ' +locationPlace[0].city+ ' , ' +locationPlace[0].region+ ' , ' +locationPlace[0].country
  this.setState({address:exactLocation})
  // let initialCoordinates ={
  //   latitude:       location.coords.latitude,
  //   longitude:      location.coords.longitude,
  // }

  console.log("!! Region",region );
  this.setState({
    initialRegion:region,
    // coordinate:initialCoordinates
  })
  // this.setState({ location });
}


onWillFocus = ()=>{
  this.getLocalKeys()
}


handleDetailPress = (e) =>{
  console.log(e, " Pressed");
  if (e == 'email') {
    this.setState({editEmail:true})
    this.setState({editNumber:false})
    this.setState({editNote:false})
  }
  else if (e == 'number') {
    this.setState({editEmail:false})
    this.setState({editNumber:true})
    this.setState({editNote:false})
  }
  else if (e == 'note') {
    this.setState({editEmail:false})
    this.setState({editNumber:false})
    this.setState({editNote:true})
  }
}


onRegionChange = async (region)=> {
  console.log('region', region);

  let locationPlace = await Location.reverseGeocodeAsync({latitude: region.latitude, longitude: region.longitude});

  console.log("locationPlace :::::" , locationPlace );


  let exactName =
  (!locationPlace[0].name ?'' : locationPlace[0].name + ' , ')

  let exactStreet =
  (!locationPlace[0].street ?'' : locationPlace[0].street + ' , ')

  let exactCity =
  (!locationPlace[0].city ?'' : locationPlace[0].city + ' , ')

  let exactRegion =
  (!locationPlace[0].region ?'' : locationPlace[0].region + ' , ')

  let exactCountry =
  (!locationPlace[0].country ?'' : locationPlace[0].country)

  let exactLocation = exactName + exactStreet + exactCity  + exactCountry
  console.log('exactLocation', exactLocation);
  this.setState({address:exactLocation})
  // this.state.region.setValue(region);
}


handleSaveState = (e) => {
  console.log(e, " Pressed");
  const nameRegex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g

  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (e == 'email') {

    if (!emailRegex.test(this.state.email )) {
      if (this.state.email == undefined || this.state.email == '') {
        alert("Email is required");
      }
      else{
        alert("Invalid Email")
      }
    }else{

      this.setState({editEmail:!this.state.editEmail})
      this.setState({editNumber:false})
      this.setState({editNote:false})

    }
  }
  else if (e == 'number') {
    this.setState({editEmail:false})
    this.setState({editNumber:!this.state.editNumber})
    this.setState({editNote:false})

  }
  else if (e == 'note') {
    this.setState({editEmail:false})
    this.setState({editNumber:false})
    this.setState({editNote:!this.state.editNote})

  }
}
  componentDidMount(){
    // this.formattingContacts()
    this.getLocationAsync()
  }

  async getLocationAsync() {
    // permissions returns only for location permissions on iOS and under certain conditions, see Permissions.LOCATION
    const { status, permissions } = await Permissions.askAsync(Permissions.LOCATION);
    if (status === 'granted') {
      return Location.getCurrentPositionAsync({ enableHighAccuracy: true });
    } else {
      throw new Error('Location permission not granted');
    }
  }

  handleToContact = (item) =>{
    console.log('item', item);
    if(item._id === 'disable'){
        console.log('do nothing');
    }else{
      this.props.navigation.navigate('ContactsSingle', {comingFrom:"contacts", data:item});

    }

  }
  formattingContacts = () =>{
    console.log('in formatting');
      var dummyData = [
        { id: '10', value: 'Bulgaria' },
        { id: '11', value: 'Cambodia' },
        { id: '12', value: 'Cameroon' },
        { id: '13', value: 'Canada' },
      { id: '1', value: 'Afghanistan' },
      { id: '2', value: 'Afghanistan' },
      { id: '3', value: 'Afghanistan' },
      { id: '4', value: 'Benin' },
      { id: '5', value: 'Bhutan' },
      { id: '6', value: 'Bosnia' },
      { id: '7', value: 'Botswana' },
      { id: '8', value: 'Brazil' },
      { id: '9', value: 'Brunei' },

      { id: '14', value: 'Cabo' },
      { id: '15', value: 'Cambodia' },
      { id: '16', value: 'Cameroon' },
      { id: '17', value: 'Canada' },
      { id: '18', value: 'Cabo' },
    ];
    var i = 65;
    var j = 91;
    const demoArray =
    {
        "A" : [],
        "B" : [],
        "C" : [],
        "D" : [],
        "E" : [],
        "F" : [],
        "G" : [],
        "H" : [],
        "I" : [],
        "J" : [],
        "K" : [],
        "L" : [],
        "M" : [],
        "N" : [],
        "O" : [],
        "P" : [],
        "Q" : [],
        "R" : [],
        "S" : [],
        "T" : [],
        "U" : [],
        "V" : [],
        "W" : [],
        "X" : [],
        "Y" : [],
        "Z" : [],
    }
    console.log('demoArray', demoArray);
    const array = []
    console.log('this.state.contactsReceived', this.state.contactsReceived);
    this.state.contactsReceived.map(x=>{
      // console.log("first letter", x.name, x.name.charAt(0));
      //set the default value of i & j to print A to Z


      //loop through the values from i to j
      for(k = i; k < j; k++){
        //convert the char code to string (Alphabets)
        var str =String.fromCharCode(k);
        //print the result in console
        console.log(str);
        if(str === x.name.charAt(0)){
          console.log('pushing in', x);
          demoArray[str].push(x)
        }
        // else if(str != x.name.charAt(0)){
        //   console.log('str', demoArray[str], x.name.charAt(0));
        //   demoArray[str].splice(1)
        // }
      }
    })
    let sections = []
    console.log('demoArray', demoArray);
    this.setState({demoArray:demoArray})
    // this.state.contactsReceived.map(x =>{
    //   console.log('demoArray x', x);
    // })
    let qwe =
    <SectionList
      ref={ref=>(this.scrollRef=ref)}
      style={{width: "100%"}}
      ItemSeparatorComponent={this.FlatListItemSeparator}
      sections={[
        { title: demoArray.A.length > 0 ? 'A' :'', data: demoArray.A, show:demoArray.A.length > 0 ? 'true' : 'false'},
        { title: demoArray.B.length > 0 ? 'B' :'', data: demoArray.B, show:demoArray.B.length > 0 ? 'true' : 'false'},
        { title: demoArray.C.length > 0 ? 'C' :'', data: demoArray.C, show:demoArray.C.length > 0 ? 'true' : 'false'},
        { title: demoArray.D.length > 0 ? 'D' :'', data: demoArray.D, show:demoArray.D.length > 0 ? 'true' : 'false'},
        { title: demoArray.E.length > 0 ? 'E' :'', data: demoArray.E, show:demoArray.E.length > 0 ? 'true' : 'false'},
        { title: demoArray.F.length > 0 ? 'F' :'', data: demoArray.F, show:demoArray.F.length > 0 ? 'true' : 'false'},
        { title: demoArray.G.length > 0 ? 'G' :'', data: demoArray.G, show:demoArray.G.length > 0 ? 'true' : 'false'},
        { title: demoArray.H.length > 0 ? 'H' :'', data: demoArray.H, show:demoArray.H.length > 0 ? 'true' : 'false'},
        { title: demoArray.I.length > 0 ? 'I' :'', data: demoArray.I, show:demoArray.I.length > 0 ? 'true' : 'false'},
        { title: demoArray.J.length > 0 ? 'J' :'', data: demoArray.J, show:demoArray.J.length > 0 ? 'true' : 'false'},
        { title: demoArray.K.length > 0 ? 'K' :'', data: demoArray.K, show:demoArray.K.length > 0 ? 'true' : 'false'},
        { title: demoArray.L.length > 0 ? 'L' :'', data: demoArray.L, show:demoArray.L.length > 0 ? 'true' : 'false'},
        { title: demoArray.M.length > 0 ? 'M' :'', data: demoArray.M, show:demoArray.M.length > 0 ? 'true' : 'false'},
        { title: demoArray.N.length > 0 ? 'N' :'', data: demoArray.N, show:demoArray.N.length > 0 ? 'true' : 'false'},
        { title: demoArray.O.length > 0 ? 'O' :'', data: demoArray.O, show:demoArray.O.length > 0 ? 'true' : 'false'},
        { title: demoArray.P.length > 0 ? 'P' :'', data: demoArray.P, show:demoArray.P.length > 0 ? 'true' : 'false'},
        { title: demoArray.Q.length > 0 ? 'Q' :'', data: demoArray.Q, show:demoArray.Q.length > 0 ? 'true' : 'false'},
        { title: demoArray.R.length > 0 ? 'R' :'', data: demoArray.R, show:demoArray.R.length > 0 ? 'true' : 'false'},
        { title: demoArray.S.length > 0 ? 'S' :'', data: demoArray.S, show:demoArray.S.length > 0 ? 'true' : 'false'},
        { title: demoArray.T.length > 0 ? 'T' :'', data: demoArray.T, show:demoArray.T.length > 0 ? 'true' : 'false'},
        { title: demoArray.U.length > 0 ? 'U' :'', data: demoArray.U, show:demoArray.U.length > 0 ? 'true' : 'false'},
        { title: demoArray.V.length > 0 ? 'V' :'', data: demoArray.V, show:demoArray.V.length > 0 ? 'true' : 'false'},
        { title: demoArray.W.length > 0 ? 'W' :'', data: demoArray.W, show:demoArray.W.length > 0 ? 'true' : 'false'},
        { title: demoArray.X.length > 0 ? 'X' :'', data: demoArray.X, show:demoArray.X.length > 0 ? 'true' : 'false'},
        { title: demoArray.Y.length > 0 ? 'Y' :'', data: demoArray.Y, show:demoArray.Y.length > 0 ? 'true' : 'false'},
        { title: demoArray.Z.length > 0 ? 'Z' :'', data: demoArray.Z, show:demoArray.Z.length > 0 ? 'true' : 'false'},


      ]}
      renderSectionHeader={({ section }) => (
        <Text
          style = {section.show === "true" ?[styles.SectionHeaderStyle]:[styles.SectionHeaderStyleEmpty]}
          > {section.title}


        </Text>

      )}
      renderItem={({ item }) => (
        // Single Comes here which will be repeatative for the FlatListItems


        <TouchableOpacity onPress= {() => this.handleToContact(item)} style ={{fontSize: 15, paddingLeft: 15, paddingTop: 15, paddingBottom:15, color: '#000', backgroundColor: '#F9F1FF', width:"100%", minHeight:10 , display:'flex', flexDirection:'row', }}>
          <Avatar
            size="large"
            rounded
            source={{
              uri: item.img
            }}
            containerStyle={{marginLeft: 1,height:35, width:35}}
            />
          <View style = {{paddingLeft:15, width:"70%", whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis', flexDirection:'column'}}>
            <Text numberOfLines={1} ellipsizeMode='tail' style={{color:'#1D2195',fontWeight:'bold' ,fontSize:20}}>{item.name}</Text>
            <Text numberOfLines={4} ellipsizeMode='tail' style={{color:'#1D2195', fontSize:10}} >{item.address}</Text>
          </View>



        </TouchableOpacity>
      )}
      keyExtractor={(item, index) => index}
    />


    console.log('array', demoArray);
    console.log('arrayA', demoArray.A);
    console.log('arrayB', demoArray.B);
    this.setState({contactsList:demoArray})
    this.setState({phoneBook:qwe})
  }
  handleLayoutLoaded = () =>{
    try {
      // Once the list is loaded, measure it and scroll the user to the end of it
      this.scrollRef.scrollToLocation({ sectionIndex: 4, animated: true });
    } catch (error) {
      console.log('ProgramSchedule handleLayoutLoaded error: ', error);
    }
  }
  FlatListHeader = ({item}) => {
    console.log('item in header', item);
  return (
    <View elevation={1}
      style={{
        height: 100,
        width: "97%",
        margin: 5,
        backgroundColor: "#fff",
        border: 2.9,
        borderColor: "black",
        alignSelf: "center",
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 16,
        },
        shadowOpacity: 1,
        shadowRadius: 7.49
      }}
    >
      <Text style={{  textShadowColor: 'black', textShadowOffset: { width: 1, height: 3 },textShadowRadius: 10, fontSize: 40, fontWeight: '800', flex: 1, alignSelf: "center", paddingTop: 30, fontSize: 40}}>Latest articles</Text>
    </View>
  );
}

handleChange =(e)=>{
  this.setState({search:e})

  console.log("handle change :: ", e );
  this.setState({text:e})
  this.setState({contactsReceived:this.state.allContacts.filter(item => {
    console.log('item is ', item);
    if(item.name){
      if(item.name.toLowerCase().indexOf(e.toLowerCase()) > -1)
      {
        console.log('in if', item.name);
        return item
      }
    }

  })
})

}

render() {
  if(this.state.contactsReceived.length == 0){
    this.state.contactsReceived.push({"name": "No Contact found", '_id':"disable"})
  }
  return (

    <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>
      <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'99%',width:'100%'}]:[{height:'99%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
        <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>
          <NavigationEvents
            onWillFocus={() => this.onWillFocus()}
            onDidFocus={payload => console.log('did focus', payload)}
            onWillBlur={payload => console.log('will blur', payload)}
            onDidBlur={payload => console.log('did blur', payload)}
            />

          <View style = {global.mainInsideContainer}>
            {
              /*
              <TouchableOpacity style={{width:'100%'}}>
                {this.state.UserComponent}
              </TouchableOpacity>
              */
            }

            <View style = {styles.inputField}>

              <TextInput
maxLength={(this.jsonData)?(this.jsonData.wordLimit != undefined) ? this.jsonData.wordLimit : 999:null}
                editable
                style={styles.textInputStyle}
                placeholder="Search"
                onChangeText={(e) => this.handleChange(e)}
                value={this.state.search}
                />

              <Button buttonStyle={{backgroundColor:'white'}} onPress={this.searchUsers} icon={ <Icon name="search" size={25} color="black" /> } />

            </View>


            <CustomButtonContacts title = "New Contact" type='single' navigation = {this.props.navigation} onPressSave= {this.navigateToNew}/>

            <View style={{width:'125%', paddingTop:5,paddingBottom:10, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>

              {
                this.state.contactsReceived && !this.state.activityProcess
                ?
                <View style={styles.containerContacts}>
                  {
                    Platform.OS !== 'web' &&
                    <AlphaScrollFlatList
                    stickySectionHeadersEnabled={true}
                    keyExtractor={this.keyExtractor.bind(this)}
                    data={this.state.contactsReceived.sort((prev, next) => prev.name.localeCompare(next.name))}
                    renderItem={this.renderItemFlat.bind(this)}
                    scrollKey={'name'}
                    reverse={false}
                    itemHeight={ITEM_HEIGHT}
                    scrollBarContainerStyle={{ backgroundColor: 'white',}}
                    scrollBarColor='#9126FB'
                    />
                  }
                </View>

                :
                <ActivityIndicator size="small" color="#9126fb" />

              }


            </View>
          </View>
        </View>
      </ScrollView>
      <ScrollView scrollEnabled={false} style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'1%', width:'100%'}]:[{height:'1%', width:'100%', maxWidth:800, minWidth:800}]}
        contentContainerStyle={[global.bottom, {width:'100%', alignItems:'center', justifyContent:'center'}]}>


      </ScrollView>

    </View>
  );
}
}




{/*
  <TouchableOpacity style={{width:'100%'}}>
  {this.state.UserComponent}
  </TouchableOpacity>


  <View style={{width:'90%', paddingTop:5,paddingBottom:10, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
  <View >


  <UserDetail data = {this.state.address} title="Address" icon='address'/>


  </View>

  <Text style={styles.descriptionText}>
  </Text>

  <Text style={[styles.descriptionText,{fontWeight:'bold',marginTop:10}]}>
  </Text>

  </View>

  <View style={{width:'90%', paddingTop:5,paddingBottom:10, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>


  <MapView
  minZoomLevel={3}
  maxZoomLevel={20}



  clusterColor="#8b0000"
  provider={PROVIDER_GOOGLE}
  style={[styles.mapStyle, {height: '80%'}]}
  initialSnap={10}
  ref={(map) => { this.map = map; }}
  showsUserLocation={true}
  showsMyLocationButton={true}
  initialRegion={this.state.initialRegion}
  region={this.state.region}
  onRegionChange={(region) => this.onRegionChange(region)}
  />


  </View>

  */}
  const styles = StyleSheet.create({
    container: {
      flex:1,

      width:'100%',
      alignItems: 'center',
      justifyContent: 'center',
      // backgroundColor:'#501D95',
      //     paddingTop:50,
    },
    containerContacts: {
      flex:1,
      marginTop:10,
      height: Dimensions.get('window').height-270,
      // height: 700,
      alignItems: 'center',
      justifyContent: 'center',
      // backgroundColor:'#501D95',
      //     paddingTop:50,
    },

    inputField:{
      width:'90%',
      flexDirection:'row',
      height:50,
      //     marginTop:16,
      marginBottom:16,
      marginTop:26,
      alignItems:'center',
      paddingLeft:10,
      paddingRight:10,
      justifyContent:'space-between',
      borderWidth:.5,
      borderColor:'#1D2195'


    },
    textInputStyle:{
      height: 40,
      width:'78%',
      marginBottom:5,
      alignSelf:'flex-end',
      // outline:'none',
      borderColor:'transparent',

    },
    textSmall:{
      width:'100%',
      alignItems:'flex-start',
      marginTop:50,
      color:'#1D2195',
    },
    textBold:{
      width:'100%',
      alignItems:'flex-start',
      marginTop:50,
      color:'#1D2195',
      fontWeight:'bold'
    },
    descriptionText:{
      marginBottom:10,
      // fontWeight:'bold',
      width:'90%',
      marginLeft:10,
      marginRight:10,
      textAlign:'left',
      color:'#1D2195',
      lineHeight: 20,

    },
    mapStyle: {
      width: Dimensions.get('window').width ,
      // height: (Dimensions.get('window').height -100),
    },

    SectionHeaderStyle: {
      backgroundColor: '#9126FB',
      fontSize: 18,
      padding: 4,
      color: '#fff',
    },
    SectionHeaderStyleEmpty:{
      backgroundColor: 'white',
      height: 0
    },
    SectionListItemStyle: {
      fontSize: 15,
      padding: 15,
      color: '#000',
      backgroundColor: '#F9F1FF',
    },
  })
