import React, { Component } from 'react';
import { View, Text, StyleSheet, Platform, TextInput,TouchableOpacity, ImageBackground, ScrollView , ActivityIndicator, Dimensions, } from 'react-native';
// import ACDfooter from '../../../Components/ACDfooter'
import CustomButton from '../../Components/CustomButton';
import UserComponentProvider from '../../Components/UserComponentProvider'
import { AsyncStorage } from 'react-native';
import global from "../../Styles/global"
import axios from 'axios'
import LogBookComponent from '../../Components/LogBookComponent'
import EventBus from 'eventing-bus'
import * as FileSystem from "expo-file-system";
import * as Print from 'expo-print';
import * as WebBrowser from 'expo-web-browser';
import { WebView } from 'react-native-webview';

var moment = require('moment');

export default class ResourcesEmptyProvider extends Component {
  constructor(){
    super()
    this.state={
      providerText :'You have now applied for a ACDcare account. When your account has been approved you will be able to login.',
      patientText : 'You have now signed up for ACDcare You will receive an email to confirm your email and sign-up.',
      verificationPending: 'Email verification is pending, Confirm your email then you will be able to login.',
      code:'',
      winHeight:Dimensions.get("window").height,

      isLoading:false,
      AcdGenerated:false,
      AcdWitnessedprocess:false,
      ActivityProcessing:true,
      winHeight:Dimensions.get("window").height,
      showButton:true,
      UserComponent:<ActivityIndicator size="small" color="#9126fb" />
  }
  this.getLocalKeys()
  this.jsonUserMain = global.userData
}


getLocalKeys = async () =>{
  const value = await AsyncStorage.getItem('@UserData');
  this.token = await AsyncStorage.getItem('@token');
  this.user = await AsyncStorage.getItem('@UserData');
  let userSingle = JSON.parse(this.user)
  if (value !== null) {
    var data = JSON.parse(value)
    console.log("UserData :: ", data);

    this.setState({userName:data.name})
    this.setState({userAddress:data.address})

    this.setState({UserComponent:<UserComponentProvider createdOn={this.props.navigation.state.params.allData.patientsWithAcds[0].acdPdfCreated} isComplete={this.props.navigation.state.params.allData.patientsWithAcds[0].userData.acd.isComplete} name={this.props.navigation.state.params.allData.name} address={this.props.navigation.state.params.allData.address} profileImg = {this.props.navigation.state.params.allData.profileImg}/>})


    // this.getVerifiedLogBook()

  }
}

handleHome = (e) =>{
  this.props.navigation.popToTop()

  // this.props.navigation.navigate('AcdHome')
}
handleAcdOverview = (e) =>{
  console.log("overview clicked ");
  this.props.navigation.navigate('ACDOverview18')
}
componentWillMount(){
  Dimensions.addEventListener("change", this.handler);
}

componentWillUnmount(){
  Dimensions.removeEventListener("change", this.handler);
}
handler =(e) =>{
  console.log('eee', e);
  this.setState({winHeight: e.window.height})
}

handleDownloadWitnessed = ()=>{
  console.log("downloading ", FileSystem.documentDirectory);

  if (Platform.OS == 'web' ) {
    WebBrowser.openBrowserAsync(this.state.pdfWitnessed)

  }
  else {

    console.log('in else download');
      WebBrowser.openBrowserAsync(this.state.pdfWitnessed)
  }
}

handleNext = () =>{
  console.log('in handlenex MainScreenProvider');
  this.props.navigation.navigate("MainScreenProvider")
}

handleDownload=(pdf)=>{
  console.log("downloading ", FileSystem.documentDirectory);

  if (Platform.OS == 'web' ) {
    WebBrowser.openBrowserAsync(this.state.pdf)

    // Linking.openURL('https://res.cloudinary.com/qmr-cloudinary/image/upload/v1570443564/ACD_PDF_isyvyx.pdf')
  }
  else {
    // WebBrowser.openBrowserAsync('https://res.cloudinary.com/qmr-cloudinary/image/upload/v1570443564/ACD_PDF_isyvyx.pdf')

        //   FileSystem.downloadAsync(
    //   this.state.pdf,
    //   FileSystem.documentDirectory + "acd.pdf"
    // )
    //   .then(({ uri }) => {
    //     console.log('Finished downloading to ', uri);
    //   })
    //   .catch(error => {
    //     console.error("error occured: ",error);
    //   });
    console.log('in else download');
      WebBrowser.openBrowserAsync(this.state.pdf)

  }

}
handleWebViewNavigationStateChange = newNavState => {
const { url } = newNavState;


if (url.includes('expo')) {
this.setState({showButton:false})
}
};

render() {
  console.log("props :: ", this.props);
  return (
    <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>
      <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'80%',width:'100%'}]:[{height:'80%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
        <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>


          <TouchableOpacity style={{width:'100%'}} >
            {this.state.UserComponent}
          </TouchableOpacity>

          <View style = {Platform.OS == 'web'?styles.congratulationsweb: styles.congratulations}>
            <ImageBackground source={require('../../../assets/exclaim.png')} style={{width: 80, minHeight: 170, marginTop:100,marginBottom:10}}/>
            <Text style= {{width:'80%',lineHeight: 30, textAlign: 'center',paddingTop:50, fontSize:14, color:'#1D2195'}} > </Text>
              <Text style= {{width:'80%', lineHeight: 30, textAlign: 'center', fontSize:14,marginBottom:30, color:'#1D2195'}} >The patient has not added any resources</Text>
          </View>

          </View>

        </ScrollView>
        <ScrollView scrollEnabled={false} style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'20%', width:'100%'}]:[{height:'20%', width:'100%', maxWidth:800, minWidth:800}]}
          contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
          <CustomButton title="HomeProvider" type="multiples" color='#F9F1FF' navigation= {this.props.navigation} onPress = {this.handleNext}/>
        </ScrollView>

    </View>
  );
}
}



const styles = StyleSheet.create({
  container: {
    flex:1,
    width:'100%',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor:'#501D95',
  },

  inputField:{
    width:'90%',
    flexDirection:'row',
    height:50,
    marginTop:16,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'


  },
  textInputStyle:{
    height: 40,
    width:'78%',
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',
  },

  textstyle:{
    alignItems:'center',
    justifyContent:'center',
    color:'#1D2195',
    fontSize:20,
    textAlign:'center'
  },
  congratulationsweb:{
    width:'75%',
    height:500,
    alignItems:'center',
    // justifyContent:'center',
    backgroundColor:'#F9F1FF',
  },
  congratulations:{
    width:'75%',
    height:'77%',
    alignItems:'center',
    // justifyContent:'center',
    backgroundColor:'#F9F1FF',
  },
})
