import React, { Component } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  TextInput,
  ImageBackground,
  TouchableOpacity,
  AsyncStorage,
  ActivityIndicator,
  ScrollView,
  Alert,
  Dimensions,
  Modal,
  TouchableHighlight
} from 'react-native';
import {  Button, CheckBox, Avatar } from 'react-native-elements'
import UserComponent from '../../Components/UserComponent'
import UserDetail from '../../Components/UserDetail'
import CustomButton from '../../Components/CustomButton'
import ACDfooter from '../../Components/ACDfooter'
import global from '../../Styles/global';
import DatePicker from 'react-native-datepicker'
import * as Permissions from 'expo-permissions';
import * as ImagePicker from 'expo-image-picker';
import Constants from 'expo-constants';
import ActionSheet from 'react-native-actionsheet'
import axios from 'axios';

import * as DocumentPicker from 'expo-document-picker';
import AlertComponent from '../../Components/AlertComponent';


import pdfImage from '../../../assets/pdfImage.png'

var multiImageArray=[]
var dummyImage = "file:///Users/sofit/Library/Developer/CoreSimulator/Devices/348CA465-0FBC-4A72-A6BA-6D2543F01034/data/Containers/Data/Application/47EC2CFD-BE22-487D-8E91-083F7A889BDC/Library/Caches/ExponentExperienceData/%2540haseemulhaq%252FPalCare/ImagePicker/AE5A8622-5FF6-452C-8D07-87F0A37DEE2A.jpg"
var multiImageURI = []
export default class ACDoverview17 extends Component {
  constructor(props) {
    super(props);

    this.state={
      UserComponent:<ActivityIndicator size="small" color="#9126fb" />,
    data:'',
    winHeight:Dimensions.get("window").height,
    showImageOptions:false,
    imageDefault:'https://res.cloudinary.com/hqepnl8tg/image/upload/v1571642768/cam_pnlffd.png',
    image:"https://res.cloudinary.com/hqepnl8tg/image/upload/v1571642768/cam_pnlffd.png",
    multiImages:[],
    uploadedImages:'',
    capturedImage: this.props.navigation.state.params.image,
    ImageUri:'',
    LargeImage:this.props.navigation.state.params.image,
    modalVisible: false,
    pictureUploading:false,
    alertStatus:false
  }
  this.uri = '',
  this.count = 0,
  this.responseImage = [],
  this.resourceImages = []

  this.getLocalKeys()
}

componentDidMount = () => {
  console.log('in did mount');
  this.formatPictureArray();
}

formatPictureArray = async ()=>{
  console.log('n formatPictureArray', global.userData.userData);
  let imageDiv = []
  const images = await AsyncStorage.getItem('@resourceImages');
  // let parsedImages = JSON.parse(images)
  // global.userData.userData.resource.recourceImages = parsedImages
  if(global.userData.userData.resource){

    if(global.userData.userData.resource.recourceImages > 0){
      console.log('in 1 image');
      if(global.userData.userData.resource.recourceImages == 1){

      let extension = global.userData.userData.resource.recourceImages[0].substring(global.userData.userData.resource.recourceImages[0].lastIndexOf('.')+1)
        imageDiv =
        [
          extension === 'pdf' ?
        <TouchableOpacity key={global.userData.userData.resource.recourceImages[0]} onPress={()=>{this.showActionSheet(global.userData.userData.resource.recourceImages[0])}}>
        <ImageBackground source={pdfImage} style={{width:50, height:60, backgroundColor:"gray", marginLeft:5, marginRight:5}}/>
        </TouchableOpacity>
        :
        <TouchableOpacity key={global.userData.userData.resource.recourceImages[0]} onPress={()=>{this.showActionSheet(global.userData.userData.resource.recourceImages[0])}}>
        <ImageBackground source={{uri : global.userData.userData.resource.recourceImages[0]}} style={{width:50, height:60, backgroundColor:"gray", marginLeft:5, marginRight:5}}/>
        </TouchableOpacity>

      ]
      this.setState({multiImages:imageDiv})
    }else if(global.userData.userData.resource.recourceImages.length > 1){
      console.log('in more than 1 image');
      global.userData.userData.resource.recourceImages.map((x,idx, z) =>{
        console.log('idx', idx);
        if(idx <= 5){
          console.log('x ix', x);
          if(x){
            console.log('global.userData.userData.resource.recourceImages[x]', global.userData.userData.resource.recourceImages[x]);

            let extension = x.substring(x.lastIndexOf('.')+1)
            this.state.multiImages.push(
              extension ?
              <TouchableOpacity key={x} onPress={()=>{this.showActionSheet(x)}}>
                <ImageBackground source={pdfImage} style={{width:50, height:60, backgroundColor:"gray", marginLeft:5, marginRight:5}}/>
              </TouchableOpacity>
              :
              <TouchableOpacity key={x} onPress={()=>{this.showActionSheet(x)}}>
                <ImageBackground source={{uri : x}} style={{width:50, height:60, backgroundColor:"gray", marginLeft:5, marginRight:5}}/>
              </TouchableOpacity>
            )
          }
        }
      })

      this.setState({multiImages:this.state.multiImages})
      console.log('this.state.multiImages after for', this.state.multiImages);
    }
  }else{
    console.log('in 1 image', this.props.navigation.state.params.image);
    let extension = this.props.navigation.state.params.image.substring(this.props.navigation.state.params.image.lastIndexOf('.')+1)

    imageDiv =
    [
    extension === 'pdf' ?
    <TouchableOpacity key={this.props.navigation.state.params.image} onPress={()=>{this.showActionSheet(this.props.navigation.state.params.image)}}>
      <ImageBackground source={pdfImage} style={{width:50, height:60, backgroundColor:"gray", marginLeft:5, marginRight:5}}/>
    </TouchableOpacity>
    :
    <TouchableOpacity key={this.props.navigation.state.params.image} onPress={()=>{this.showActionSheet(this.props.navigation.state.params.image)}}>
      <ImageBackground source={{uri : this.props.navigation.state.params.image}} style={{width:50, height:60, backgroundColor:"gray", marginLeft:5, marginRight:5}}/>
    </TouchableOpacity>
    ]
    this.setState({multiImages:imageDiv})
  }
}else{
  console.log('in 1 image last else', this.props.navigation.state.params.image);
  let extension = this.props.navigation.state.params.image.substring(this.props.navigation.state.params.image.lastIndexOf('.')+1)
  console.warn("extension check  resource::", extension)
  imageDiv =
  [
  extension === 'pdf' ?
  <TouchableOpacity key={this.props.navigation.state.params.image} onPress={()=>{this.showActionSheet(this.props.navigation.state.params.image)}}>
    <ImageBackground source={pdfImage} style={{width:50, height:60, backgroundColor:"gray", marginLeft:5, marginRight:5}}/>
  </TouchableOpacity>
  :
  <TouchableOpacity key={this.props.navigation.state.params.image} onPress={()=>{this.showActionSheet(this.props.navigation.state.params.image)}}>
    <ImageBackground source={{uri : this.props.navigation.state.params.image}} style={{width:50, height:60, backgroundColor:"gray", marginLeft:5, marginRight:5}}/>
  </TouchableOpacity>
]
  this.setState({multiImages:imageDiv})
}
// console.log('in 1 image', this.props.navigation.state.params.image);
//
//   imageDiv =
//   [<TouchableOpacity key={this.props.navigation.state.params.image} onPress={()=>{this.showActionSheet(this.props.navigation.state.params.image)}}>
//     <ImageBackground source={{uri : this.props.navigation.state.params.image}} style={{width:50, height:60, backgroundColor:"gray", marginLeft:5, marginRight:5}}/>
//   </TouchableOpacity>]
//   this.setState({multiImages:imageDiv})
}


setModalVisible(visible) {
  console.log('setModalVisible web', visible);

  this.setState({ modalVisible: visible });
}
componentDidMount() {
  this.getPermissionAsync();
}

getPermissionAsync = async () => {
  if (Constants.platform.ios) {
    const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL);
    if (status !== 'granted') {
      alert('Sorry, we need camera roll permissions to make this work!');
    }
  }
}

getLocalKeys = async () =>{
  const value = await AsyncStorage.getItem('@UserData');
  this.token = await AsyncStorage.getItem('@token');

  if (value !== null) {
    var data = JSON.parse(value)
    console.log("UserData :: ", data);
    if (data.accountStatus == "pending") {
      this.props.navigation.navigate('SignedUp', {comingFrom:"login", data:data});
    }
    else {
      this.setState({data:data})

      this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})
    }
  }
}

ACDfilledout = () =>{
  console.log("ACDfilledout");
}

handleNext = () => {
  this.setState({pictureUploading:true})
  this.uploadImages();
}

uploadImages = async () =>{
  let allImages = []
  this.state.multiImages.map(x=>{
    allImages.push(x.key);
  })
  console.log('allImages', allImages);
  console.log('global.userData', global.userData);
  // global.userData.userData.sevenStepPlan.ssp_images = allImages
  AsyncStorage.setItem("@resourceImages", JSON.stringify(allImages) )
  this.resourceImages = allImages

  this.uri = allImages;
  console.log('this.uri images', this.uri);

  console.log('this.uri not uploaded', this.uri);

  this.uploadToBackend()

}

uploadToBackend = () =>{

  if(this.uri.length > 0){
    if(Platform.OS === 'ios' || Platform.OS ==='android'){
      if(this.count < this.uri.length){
        // this.ActionSheet.show()
        this.onActionNonPress()
      }
    }else{
      if(this.count < 15){
        if(this.count < this.uri.length){
          this.uploadResourceImages(this.uri, this.count)
          this.count++
        }else{

        }

      }else{

      }
    }
  }else{
    this.generatePdf();
  }
}

onActionNonPress = () => {
  console.log('in action non press', this.count);
  if(this.count < 15){
    this.uploadResourceImages(this.uri, this.count)
    this.count++
  }
}

uploadResourceImages = async (uri, count)=>{
  console.log('in uploadResourceImages');

  // let stringImages = await AsyncStorage.getItem("@resourceImages")
  let parsedImages = this.resourceImages
  console.log('parsedImages', parsedImages);

  // console.log('parsedImages global.userData', global.userData);

  let bodyData = await new FormData();


  if(Platform.OS === 'ios' || Platform.OS ==='android'){

    let extension = uri[count].substring(uri[count].lastIndexOf('.')+1)
  const value = await AsyncStorage.getItem('@UserData');
  const UserId = JSON.parse(value)
    bodyData.append("userFile", {
      uri:uri[count],
      type: extension === 'pdf' ? 'application/pdf' : 'image/jpeg', // or photo.type
      name:'upload.'+extension,
    } )
    if (extension === "pdf") {
      bodyData.append(
        "resourceName",
        this.props.navigation.state.params.resource
      );
    }
    console.log("Parsed images :: ", uri);

    console.log("API FOR resource Images",global.url+ `/api/uploadResourceImages/${UserId._id}`);
    console.log(bodyData);
    fetch(global.url+ `/api/uploadResourceImages/${UserId._id}`,
    {
      method: 'POST',
      headers: {'cache-control': 'no-cache', 'Content-type': 'multipart/form-data', 'Authorization': JSON.parse(this.token) } ,
      body: bodyData,
    }
  )
  .then((response)=> {

    console.log('###responce picture post ::',response);

    if (response.status == 200) {
      return response.json()
    }
  })
  .then((responseJson)=>{
    console.log("#### responseJson picture post :: ", responseJson);
    this.responseImage.push(responseJson.url)
    console.log('this.responseImage', this.responseImage);
    if(Platform.OS === 'ios' || Platform.OS ==='android'){
      if(this.count < this.uri.length){
        // this.ActionSheet.show()
        this.onActionNonPress()
      }else{
        AsyncStorage.removeItem("@resourceImages")
        // this.ACDWitnessed()
        console.log('this.responseImage in last', this.responseImage);

        // AsyncStorage.setItem("@AcdImagestoSubmit", JSON.stringify(responseImage) )
        this.generatePdf()
        // this.getNewUserAcd()
      }
    }else{
      if(this.count < this.uri.length){
        this.uploadResourceImages()
      }else{
        AsyncStorage.removeItem("@resourceImages")
        // this.ACDWitnessed()
        console.log('this.responseImage in last', this.responseImage);

        // AsyncStorage.setItem("@AcdImagestoSubmit", JSON.stringify(responseImage) )
        this.generatePdf()

        // this.getNewUserAcd()
      }
    }
  })
  .catch(err => console.log('###error api', err))

}else{

  const value = await AsyncStorage.getItem('@UserData');
  const UserId = JSON.parse(value)
  bodyData.append("base64Image",uri[count])
  console.log('url resource', global.url+ `/api/uploadResourceImages/${UserId._id}`);
  // console.log('token', JSON.parse(token), this.state.image);
  fetch(global.url+ `/api/uploadResourceImages/${UserId._id}`,
  {
    method: 'POST',
    headers: {} ,
    body: bodyData,
  }
)
.then( (response)=> {
  console.log('response', response);
  if (response.status == 200) {
    return response.json()

  }
})
.then((responseJson)=>{
  console.log('###responce picture post web', responseJson);
  console.log('in response.status 200');
  console.log("#### responseJson picture post :: ", responseJson);
  this.responseImage.push(responseJson.url)
  console.log('this.responseImage', this.responseImage);
  if(Platform.OS === 'ios' || Platform.OS ==='android'){
    if(this.count < this.uri.length){
      // this.ActionSheet.show()
      this.onActionNonPress()
    }else{
      AsyncStorage.removeItem("@resourceImages")
      // this.ACDWitnessed()
      console.log('this.responseImage in last', this.responseImage);

      // AsyncStorage.setItem("@AcdImagestoSubmit", JSON.stringify(responseImage) )
      this.generatePdf()
      // this.getNewUserAcd()
    }
  }else{
    if(this.count < this.uri.length){
      this.onActionNonPress()
    }else{
      AsyncStorage.removeItem("@resourceImages")
      // this.ACDWitnessed()
      console.log('this.responseImage in last', this.responseImage);

      // AsyncStorage.setItem("@AcdImagestoSubmit", JSON.stringify(responseImage) )
      this.generatePdf()

      // this.getNewUserAcd()
    }
  }
})

.catch(err => console.log('###error api', err))
}


}

generatePdf = async () =>{
  let old = []
  old = await AsyncStorage.getItem('@resourcePdfs');

  console.log('this.responseImage generatePdf', this.responseImage);
  let options = {
    resourceImageArray: this.responseImage,
    resourceName:this.props.navigation.state.params.resource
  }

  let optionsAxios = {
    method: 'POST',
    headers: {'Content-Type': 'application/json', 'Authorization': JSON.parse(this.token)},
    url: global.url+'/api/getResourcePdf',
    data: options
  }

  console.log("axios options :: ", optionsAxios);
  axios(optionsAxios)
  .then((response)=> {
    console.log("getPdfWithName Response :: ",response.status ,response.data);
    let array = []
    if(old){
      array = JSON.parse(old)
    }
    console.log('array is', array);
    array.push(response.data.url)
    AsyncStorage.setItem("@resourcePdfs", JSON.stringify(array))
    this.setState({ pictureUploading:false})

    // "http://res.cloudinary.com/hqepnl8tg/image/upload/v1574764717/Resustication_Alert_7_Step_Pathway_1577.pdf"
    this.props.navigation.navigate("PopOver9")

    console.log("getPdfWithName Response :: ",response.status ,response);

  })
  .catch((err)=>{
    this.setState({pdf:'', downloading:false})

    if(err.response){
      console.log("error getPdfWithName :: ", err.response);
    }
    // alert(err.response.data.message)
  })
}



pickImage = async () => {
  this.setState({showImageOptions:false});
  console.log("Alert");
  if (this.state.multiImages.length > 14) {
    alert("Cannot upload more than 15 images")
  }else{
    if (Platform.OS !== 'web') {
      this.setState({alertStatus:true})
    }
    else {
      this.uploadFromGallery()
    }
  }
}


uploadpdfFile = async () => {
  const file = await DocumentPicker.getDocumentAsync();
  if (file.type === 'success') {
    console.warn("File content",file);

    this.setState({ image:file.uri,alertStatus:false });

    var pushToList = true

    console.log("multiImageURI :: ", multiImageURI );

    if (this.state.multiImages.length > 14) {
      alert("Cannot upload more than 15 images")
    }
    else {
      console.log('this.state.multiImages', this.state.multiImages);

    let extension = file.uri.substring(file.uri.lastIndexOf('.')+1)
      this.state.multiImages.push(
        extension === 'pdf' ?
        <TouchableOpacity key={file.uri} onPress={()=>{this.showActionSheet(file.uri)}}>
          <ImageBackground source={pdfImage} style={{width:50, height:60, backgroundColor:"gray", marginLeft:5, marginRight:5}}/>
        </TouchableOpacity>
        :
        <TouchableOpacity key={file.uri} onPress={()=>{this.showActionSheet(file.uri)}}>
          <ImageBackground source={{uri : file.uri}} style={{width:50, height:60, backgroundColor:"gray", marginLeft:5, marginRight:5}}/>
        </TouchableOpacity>
      )

      this.setState({multiImages:this.state.multiImages})

      this.setState({LargeImage:this.state.multiImages[this.state.multiImages.length-1].key})

    }

  } else if (file.type === 'cancel') {
    console.warn('Select canceled');
  } else {
    console.warn(`Some other type ${file.type}`);

  }
}

uploadFromGallery = async () => {
  let result = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.All,
    allowsEditing: true,
    // aspect: [4, 3],
    base64: false

  });
  console.log(result);
  if (!result.cancelled) {
    this.setState({ image:result.uri,alertStatus:false });

    var pushToList = true

    console.log("multiImageURI :: ", multiImageURI );

    if (this.state.multiImages.length > 14) {
      alert("Cannot upload more than 15 images")
    }
    else {
      console.log('this.state.multiImages', this.state.multiImages);
      let extension = result.uri.substring(result.uri.lastIndexOf('.')+1)

      this.state.multiImages.push(
        extension === 'pdf' ?
        <TouchableOpacity key={result.uri} onPress={()=>{this.showActionSheet(result.uri)}}>
          <ImageBackground source={pdfImage} style={{width:50, height:60, backgroundColor:"gray", marginLeft:5, marginRight:5}}/>
        </TouchableOpacity>
        :
        <TouchableOpacity key={result.uri} onPress={()=>{this.showActionSheet(result.uri)}}>
          <ImageBackground source={{uri : result.uri}} style={{width:50, height:60, backgroundColor:"gray", marginLeft:5, marginRight:5}}/>
        </TouchableOpacity>
      )

      this.setState({multiImages:this.state.multiImages})

      this.setState({LargeImage:this.state.multiImages[this.state.multiImages.length-1].key})

    }
  }
}


uploadFromCamera = async () => {
  console.log('in uploadFromCamera');
  let result = await ImagePicker.launchCameraAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.All,
    allowsEditing: true,
    // aspect: [4, 3],
    base64: false

  });


  console.log(result);
  if (!result.cancelled) {
    this.setState({ image:result.uri,alertStatus:false });

    var pushToList = true

    console.log("multiImageURI :: ", multiImageURI );

    if (this.state.multiImages.length > 14) {
      alert("Cannot upload more than 15 images")
    }
    else {
      console.log('this.state.multiImages', this.state.multiImages);
      let extension = result.uri.substring(result.uri.lastIndexOf('.')+1)

      this.state.multiImages.push(
        extension === 'pdf' ?
        <TouchableOpacity key={result.uri} onPress={()=>{this.showActionSheet(result.uri)}}>
          <ImageBackground source={pdfImage} style={{width:50, height:60, backgroundColor:"gray", marginLeft:5, marginRight:5}}/>
        </TouchableOpacity>
        :
        <TouchableOpacity key={result.uri} onPress={()=>{this.showActionSheet(result.uri)}}>
          <ImageBackground source={{uri : result.uri}} style={{width:50, height:60, backgroundColor:"gray", marginLeft:5, marginRight:5}}/>
        </TouchableOpacity>
      )

      this.setState({multiImages:this.state.multiImages})

      this.setState({LargeImage:this.state.multiImages[this.state.multiImages.length-1].key})

    }
  }
}

deleteImageAlert = (e) =>{
  console.log("Image Data: ", e);
  if (Platform.OS == 'web') {
    var view = confirm("Are you sure you want to delete this image?")
    if (view) {
      this.deleteImage(e)
    }
  }
  else {
    Alert.alert(
      'Warning',
      'Want to delete this image?',
      [
        {
          text: 'No',
          onPress: () => console.log("dismiss"),
          style: 'cancel',
        },
        { text: 'Yes', onPress: () => this.deleteImage(e) },
      ],
      { cancelable: false }
    );
  }

}

deleteImage = (e) =>{
  console.log("Delete Image data :: ", e);

  var updatedArray = [];
  this.state.multiImages.forEach((i,idx,x)=>{
    console.log("Delete Image i :: ", i);

    if(i.key == e){
      console.log("Found Index to be deleted ::",i);
    }else{
      updatedArray[updatedArray.length] = i;
    }

    if(idx == x.length-1){
      if (updatedArray.length == 0) {
        var splitted = e.split(".com");
        console.log(splitted);
        this.props.navigation.goBack()

        if(splitted[0] === "http://res.cloudinary"){
          console.log('global.userData.userData.resource.recourceImages', global.userData);
          global.userData.userData.resource.recourceImages.map((x, idx, z)=>{
            if(x === e){
              global.userData.userData.resource.recourceImages.splice(idx, 1);
              console.log('global.userData.userData.resource.recourceImages after splice', global.userData.userData.resource.recourceImages);
              AsyncStorage.removeItem("@AcdImages")
              AsyncStorage.setItem("@unSyncedUserData", JSON.stringify(global.userData) )

              this.props.navigation.goBack()

            }
          })
        }
      }
      else {
        var splitted = e.split(".com");
        console.log(splitted);
        if(splitted[0] === "http://res.cloudinary"){
          console.log('global.userData.userData.resource.recourceImages', global.userData);
          global.userData.userData.resource.recourceImages.map((x, idx, z)=>{
            if(x === e){
              global.userData.userData.resource.recourceImages.splice(idx, 1);
              console.log('global.userData.userData.resource.recourceImages after splice', global.userData.userData.resource.recourceImages);

            }
          })
        }
        this.setState({multiImages:updatedArray})
        this.setState({LargeImage:updatedArray[updatedArray.length-1].key})
      }

    }
  })

}

componentWillMount(){
  Dimensions.addEventListener("change", this.handler);
}

componentWillUnmount(){
  Dimensions.removeEventListener("change", this.handler);
}
handler =(e) =>{
  console.log('eee', e);
  this.setState({winHeight: e.window.height})
}

onActionPress = (e) => {
  console.log("Action pressed :: ", e);
  if (e == 0) {
    this.setState({LargeImage:this.state.ImageUri})
  }
  else if(e == 1) {
    console.log("Delete Image Uri :: ", this.state.ImageUri);
    this.deleteImageAlert(this.state.ImageUri)
  }


}

showActionSheet = (e) =>{
  console.log('showActionSheet', e);
  this.setState({ImageUri:e})
  if (Platform.OS == "web") {
    this.setState({showImageOptions:true})
    // var view = confirm("View this Image")
    // console.log("View Image :: ", view, e);
    // if (view) {
    //   this.setState({LargeImage:e})
    // }
    // else {
    //   var deleteImage = confirm("Delete this Image")
    //   if (deleteImage) {
    //     this.deleteImageAlert(e)
    //   }
    // }
  }
  else {
    this.ActionSheet.show()
  }
}

render() {

  // console.log("Image uri : ", this.props.navigation.state.params.image);
  let extension = this.state.LargeImage.substring(this.state.LargeImage.lastIndexOf('.')+1)


  return (
    <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>
      <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'75%',width:'100%'}]:[{height:'70%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
        <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>

          {this.state.UserComponent}

          <View style={{width:"100%", alignItems: 'center', justifyContent: 'center'}}>

            {
              Platform.OS != 'web'
              ?
              <ScrollView
              horizontal={true}
              contentContainerStyle={{marginTop: 20, marginBottom: 20, flexWrap:'wrap', flexDirection: 'row', justifyContent: 'space-between'}}
              >
              <View style={{display:"flex", width:"90%", flexDirection:"row"}}>

              {this.state.multiImages}
            </View>
          </ScrollView>

            :
            (Platform.OS == "web" && this.state.showImageOptions)
            ?<View style = {{display:"flex",height:60, width:"90%", alignItems:"center" , justifyContent:"space-around", flexDirection:"row", width:"100%"}}>
            <TouchableOpacity style={[styles.ImageOptions,{backgroundColor:"#0e9fe8"}]}
              onPress={()=>{
                this.setState({showImageOptions:false});
                this.onActionPress(0)
              }}>
              <Text style={styles.ImageOptionsText}>View</Text>
            </TouchableOpacity>

            <TouchableOpacity style={[styles.ImageOptions,{backgroundColor:"#c2004a"}]}
              onPress={()=>{
                this.setState({showImageOptions:false});
                this.onActionPress(1)
              }}>
              <Text style={styles.ImageOptionsText}>Delete</Text>
            </TouchableOpacity>

            <TouchableOpacity style={styles.ImageOptions} onPress={()=>{this.setState({showImageOptions:false})}}>
              <Text style={styles.ImageOptionsText}>Cancel</Text>
            </TouchableOpacity>
          </View>
          :
          <ScrollView
            horizontal={true}
            contentContainerStyle={{marginTop: 20, marginBottom: 20, flexWrap:'wrap', flexDirection: 'row', justifyContent: 'space-between'}}
            >
            <View style={{display:"flex", width:"90%", flexDirection:"row"}}>
              {this.state.multiImages}
            </View>
          </ScrollView>


        }
        <CustomButton
          title = "Add one more page"
          type = "multiples"
          backgroundColor = "#F9F1FF"
          border = {true}
          color = "#1D2195"
          onPress={() => this.pickImage()}
          navigation= {this.props.navigation}
          />

        <View style={{width:"80%", marginBottom:10, marginTop:13}}>
          {
            extension === 'pdf' ?
            <ImageBackground source={pdfImage} style={{width:"100%", height:280, backgroundColor:"gray"}}/>
            :
            <ImageBackground source={{uri : this.state.LargeImage}} style={{width:"100%", height:280, backgroundColor:"gray"}}/>

          }
        </View>

        <TouchableOpacity onPress={() => this.pickImage()}>
          <ImageBackground
            source={{uri: this.state.imageDefault}}
            style={{height:40, width:50}}/>
        </TouchableOpacity>

        {/*}      <Modal
        animationType="slide"
        transparent={false}
        visible={this.state.modalVisible}
        onRequestClose={() => {
        Alert.alert('Modal has been closed.');
        }}>
        <View style={{ marginTop: 22 }}>
        <View>
        <Text>Hello World!</Text>

        <TouchableHighlight
        onPress={() => {
        this.setModalVisible(!this.state.modalVisible);
        }}>
        <Text>Hide Modal</Text>
        </TouchableHighlight>
        </View>
        </View>
        </Modal> */}


        {
          (Platform.OS !== "web")
          ?<ActionSheet
          ref={o => this.ActionSheet = o}
          title={'Which one do you like ?'}
          options={['View Image', 'Delete Image', 'Cancel']}
          cancelButtonIndex={2}
          destructiveButtonIndex={1}
          onPress={(index) => { this.onActionPress(index) }}
          />
        :null

      }

    </View>
  </View>

</ScrollView>
<ScrollView scrollEnabled={false} style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'25%', width:'100%'}]:[{height:'30%', width:'100%', maxWidth:800, minWidth:800}]}
  contentContainerStyle={[global.bottom, {width:'100%', alignItems:'center', justifyContent:'center'}]}>

  {
    (this.state.pictureUploading)
    ?
    <ActivityIndicator size="small" color="#9126fb" />
    :
    <CustomButton title = "DONE" navigation= {this.props.navigation} onPress = {this.handleNext}/>
  }
</ScrollView>

        {/* <AlertComponent

        visible={this.state.alertStatus}
        onCameraPress={() => this.uploadFromCamera()}
        onGalleryPress={() => this.uploadFromGallery()}
        onPDFPress={() => this.uploadpdfFile()}
        onCancelPress={()=>{this.setState({alertStatus:false})}}
        /> */}
</View>
);

}
}

const styles = StyleSheet.create({
  ImageOptions:{
    width: "18%",
    height: 30,
    display: "flex",
    justifyContent: "center",
    borderRadius: 4,
    backgroundColor:"gray"
  },
  ImageOptionsText:{
    textAlign:"center",
    color:"white"
  },
  buttonContainer:{
    alignItems:'center',
    marginTop:50,
    marginBottom:10,
    width:'90%',
    // justifyContent:'center',

  },
  loginButton:{
    borderRadius:5,

  },
  arrow:{
    fontSize: 20,
  },
  inputField:{
    width:'100%',
    flexDirection:'row',
    height:50,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    marginBottom:8,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'
  },
  textInputStyle:{
    height: 40,
    width:'78%',
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',
  },
  textstyle:{
    alignItems:'center',
    justifyContent:'center',
    color:'#1D2195',
    fontSize:12,
    textAlign:'center'
  },
  CheckBoxTitle:{
    color:"#707070",
    fontSize:14,
    fontWeight:"bold",
    width:"90%"
  },
  CheckBoxContainerStyle:{
    backgroundColor:"transparent",
    borderWidth:0,
    width:"100%"
  }

})
