import React, { Component } from 'react';
import { View,Text,StyleSheet, AsyncStorage,ScrollView, TextInput, Platform, ActivityIndicator, TouchableOpacity, Dimensions } from 'react-native';
import global from "../../Styles/global";
import UserComponent from '../../Components/UserComponent'
import {Button, Icon} from 'react-native-elements'
import CustomMainScreenItem from '../../Components/CustomMainScreenItem';
import PurpleCircle from '../../Components/PurpleCircle';
import CustomButton from '../../Components/CustomButton';
import { NavigationEvents } from 'react-navigation';


import axios from 'axios';

export default class AcdSignUp extends Component {
  constructor(){
    super()
    this.state = {
      search:'',
      winHeight:Dimensions.get("window").height,

      UserComponent:<ActivityIndicator size="small" color="#9126fb" />,
      existingLoader:false
    }

    this.getLocalKeys()
  }

  getLocalKeys = async () =>{
    const value = await AsyncStorage.getItem('@UserData');
    if (value !== null) {
      var data = JSON.parse(value)
      console.log("UserData :: ", data);


        this.setState({userName:data.name})
        this.setState({userAddress:data.address})
 console.log('====================================');
 console.log("Data::::::", data);
 console.log('====================================');
        this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})

        console.log('====================================');
        console.log("Data:::::: after setstate", data);
        console.log('====================================');

      }
    }
    onWillFocus = ()=>{
      this.getLocalKeys()
    }


  handlelogout = () =>{
    AsyncStorage.clear()
    this.props.navigation.navigate("Login")
  }
  handleacd= () =>{
  }
  handleAcdSignUp=()=>{
          this.props.navigation.navigate('AcdOverview')
  }

  componentWillMount(){
    Dimensions.addEventListener("change", this.handler);
  }

  componentWillUnmount(){
    Dimensions.removeEventListener("change", this.handler);
  }
  handler =(e) =>{
  console.log('eee', e);
  this.setState({winHeight: e.window.height})
  }
  
  async existingACDPress(){

    this.setState({existingLoader:true})
    this.token = await AsyncStorage.getItem('@token');
    console.log('in get resourcesComplete');
        let optionsAxios = ''
          optionsAxios = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': JSON.parse(this.token)},
            url: global.url+'/api/createDefaultAcd',
          }

        console.log(" resourcesComplete Options axios ", optionsAxios );
        axios(optionsAxios)
        .then((response)=> {
          console.log("checking existing response::",response)
          if(response.data.success === true){
            global.userData = response.data.acd
            console.log("checking ACD DAATA :: ",global.userData)
            AsyncStorage.setItem("@unSyncedUserData", JSON.stringify(global.userData))
            this.setState({existingLoader:false})
            this.props.navigation.navigate("ACDoverview4")
          }
        })
        .catch((error)=>{
          console.log("error", error);
          alert("Sorry you can`t add right now!")
        })

  }

  render() {
    return (


      <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>
        <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'75%',width:'100%'}]:[{height:'70%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
          <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>
            <NavigationEvents
              onWillFocus={() => this.onWillFocus()}
              onDidFocus={payload => console.log('did focus', payload)}
              onWillBlur={payload => console.log('will blur', payload)}
              onDidBlur={payload => console.log('did blur', payload)}
              />

            <View style = {global.mainInsideContainer}>

              <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleacd()}>
                {this.state.UserComponent}
              </TouchableOpacity>


              <View style={{width:'80%', marginTop:10,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
                <Text style={{width:'100%',fontSize:14, fontWeight:'bold', color:'#1D2195' }}>By completing this Advance Care Directive you can choose to:</Text>
              </View>
              <View style={{width:'80%', paddingTop:10,paddingBottom:10, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
                <Text style={styles.textSmall}>1. Appoint one or more Substitute Decision-Makers and/or</Text>
                <Text style={styles.textSmall}>2. Write down your values and wishes to guide decisions about your future health care, end of life, living arrangements and other personal matters and/or</Text>
                <Text style={styles.textSmall}>3. Write down health care you do not want in particular circumstances.</Text>
              </View>
              <View style={styles.buttonContainer}>
                <Text style={[styles.textStyle,{marginBottom:30}]}>If you already have signed and witnessed Advance Care Directive you can upload that.</Text>
                <TouchableOpacity style={styles.buttonStyle} onPress={this.existingACDPress.bind(this)}>
                  {
                    this.state.existingLoader ?
                    <ActivityIndicator size="small" color="#FFFFFF" />
                    :
                  <Text style={styles.btnTextStyle}>Add an existing Advance Care Directive</Text>
                  }
                </TouchableOpacity>
              </View>
              <Text style={{fontSize:10,width:'90%', textAlign:'center',color:'#1D2195', marginTop:30, marginBottom:30}}>The following pages will guide you through the process of filling out the ACD and get it verified by a GP</Text>
            </View>


          </View>
        </ScrollView>

        <ScrollView style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'25%', width:'100%'}]:[{height:'30%', width:'100%', maxWidth:800, minWidth:800}]}
          contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
          <CustomButton title = "➔" navigation = {this.props.navigation} onPress= {this.handleAcdSignUp}/>
        </ScrollView>

      </View>
    );
  }
}



const styles = StyleSheet.create({
  container: {
    flex:1,
    width:'100%',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor:'#501D95',
//     paddingTop:50,
  },


  inputField:{
    width:'90%',
    flexDirection:'row',
    height:50,
//     marginTop:16,
    marginBottom:16,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'


  },
  textInputStyle:{
    height: 40,
    width:'78%',
    marginBottom:5,
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',

  },
  textSmall:{
          width:'100%',
          alignItems:'flex-start',
          marginTop:50,
          color:'#1D2195',
  },
  textStyle:{
    width:'100%',
    alignItems:'center',
    color:'#1D2195',
    fontWeight:'bold',
    textAlign:'center',
    marginLeft:12
  },
  buttonContainer:{
    width:'80%', 
    paddingVertical:10, 
    alignItems:'center', 
    justifyContent:'center', 
    alignSelf:'center',
  },
  buttonStyle:{
    paddingVertical:20,
    width:'100%',
    backgroundColor:'#9126FB',
    borderRadius:5,
    alignItems:'center'
  },
  btnTextStyle:{
    color:'#FFFFFF'
  }

})
