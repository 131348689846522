import React, { Component } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  TextInput,
  ImageBackground,
  TouchableOpacity,
  AsyncStorage,
  ActivityIndicator,
  ScrollView,
  Linking,
  Dimensions,
} from 'react-native';
import {  Button, CheckBox } from 'react-native-elements'
import UserComponent from '../../Components/UserComponent'
import UserDetail from '../../Components/UserDetail'
import CustomButton from '../../Components/CustomButton'
import ACDfooter from '../../Components/ACDfooter'
import global from '../../Styles/global';
import DatePicker from 'react-native-datepicker'
import * as FileSystem from "expo-file-system";
import * as Print from 'expo-print';
import * as WebBrowser from 'expo-web-browser';
import axios from 'axios'

export default class ResourcePdf extends Component {
  constructor(props) {
    super(props);
    this.state={
      UserComponent:<ActivityIndicator size="small" color="#9126fb" />,
    data:'',
    winHeight:Dimensions.get("window").height,

    pdfUri: require('../../../assets/resourcePdf.png'),
    name: '',
    email: '',
    address: '',
    birthday:'1999-05-15',
    downloading:false,
    mainSubstitute:false,
    skipSubstitute:false,
    onScreenDidSubmit:false
  }

  this.getLocalKeys()
  this.jsonUser = global.userData.userData
  this.jsonUserMain = global.userData
  console.log('this.jsonUser', this.jsonUser);
  console.log('this.jsonUserMain', this.jsonUserMain);
}

getLocalKeys = async () =>{

  const value = await AsyncStorage.getItem('@UserData');
  this.token = await AsyncStorage.getItem('@token');
  this.user = await AsyncStorage.getItem('@UserData');

  if (value !== null) {
    var data = JSON.parse(value)
    console.log("UserData :: ", data);
    if (data.accountStatus == "pending") {
      this.props.navigation.navigate('SignedUp', {comingFrom:"login", data:data});
    }
    else {
      this.setState({data:data})

      this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})
    }
  }
}

generatePdf =(e) =>{

  this.props.navigation.popToTop()

  // this.props.navigation.navigate("AcdHome")
  // NetInfo.fetch().then(connectionInfo => {
  //   console.log(
  //     'Initial, type: ' + connectionInfo.type + ', effectiveType: ' + connectionInfo.effectiveType
  //   );
  //
  //   if(this.state.onScreenDidSubmit){
  //     this.props.navigation.navigate("ACDoverview4")
  //
  //   }else{
  //     if (connectionInfo.type == "none") {
  //       alert("Your need internet access to generate pdf")
  //       global.url = 'https://palcare-backend-dev.herokuapp.com'
  //       // this.getLocalData()
  //       console.log("OS :: ", Platform.OS);
  //       this.setState({downloading:false})
  //       this.props.navigation.navigate("ACDoverview4")
  //     }
  //     else {
  //       this.setState({downloading: true})
  //       console.log("In getLocalData", this.jsonUser);
  //       this.jsonUser.acd.isComplete = true
  //       let optionsAxios = ''
  //       let user = JSON.parse(this.user)
  //       if(this.jsonUserMain.user_id){
  //         optionsAxios = {
  //           method: 'POST',
  //           headers: {'Content-Type': 'application/json', 'Authorization': JSON.parse(this.token) },
  //           url: global.url+'/api/updateUserAcd',
  //           data: {userData: this.jsonUserMain.userData, acd_images:this.jsonUserMain.acd_images, _id:global.userData._id}
  //         }
  //       }else{
  //         optionsAxios = {
  //           method: 'POST',
  //           headers: {'Content-Type': 'application/json', 'Authorization': JSON.parse(this.token)},
  //           url: global.url+'/api/submitUserAcd',
  //           data: {userData: this.jsonUser, acd_images:this.jsonUserMain.acd_images, user_id:user._id}
  //         }
  //       }
  //
  //       console.log(" submitUserAcd Options axios ", optionsAxios );
  //       axios(optionsAxios)
  //       .then((response)=> {
  //         console.log("/submit pdf Response recieved ", response)
  //         this.setState({downloading:false})
  //
  //         console.log("/submit Acd Response recieved ", response);
  //         if(response.data.success){
  //           // global.userData.userData.acd.isComplete = true
  //
  //           global.userData = response.data.result
  //           this.jsonUser = global.userData.userData
  //           AsyncStorage.setItem("@SyncedUserData", JSON.stringify(global.userData) )
  //           AsyncStorage.removeItem("@unSyncedUserData")
  //
  //           this.getNewUserAcd();
  //           // AsyncStorage.setItem("@timePeriod", JSON.stringify(response.data.result[0].timePeriod));
  //
  //           // this.setState({UserComponent:<UserComponent name={user.name} address={user.address} profileImg = {user.profileImg}/>})
  //
  //           this.acdId = response.data.result._id;
  //           // WebBrowser.openBrowserAsync(response.data.pdf)
  //           if(e === 'forward'){
  //             this.props.navigation.navigate("ACDoverview4")
  //
  //           }else{
  //
  //             response.data.result.acdPdfCreated.map((x,idx,z)=>{
  //               console.log('x outside', x, idx);
  //
  //               if(idx == z.length-1){
  //                 console.log('x inside', x, idx);
  //                 this.handleDownload(x.url)
  //                 this.setState({onScreenDidSubmit: true})
  //                 // this.props.navigation.navigate("ACDoverview4")
  //
  //               }
  //             })
  //
  //           }
  //
  //         }else{
  //           console.log(response.data.message);
  //         }
  //       })
  //       .catch((error)=>{
  //         console.log("error", error);
  //       })
  //     }
  //   }
  //
  // });

}

handlelogout = () =>{
  AsyncStorage.clear()
  this.props.navigation.navigate("Login")
}

handleNext = (e) =>{
  this.props.navigation.navigate("ACDoverview4")
}

handleHelpText =() =>{
  console.log('in handleinf');
}

handleDownload=(pdf)=>{
  console.log("downloading ", FileSystem.documentDirectory);

  if (Platform.OS == 'web' ) {
    WebBrowser.openBrowserAsync(this.props.navigation.state.params.pdf)

    // Linking.openURL('https://res.cloudinary.com/qmr-cloudinary/image/upload/v1570443564/ACD_PDF_isyvyx.pdf')
  }
  else {

    WebBrowser.openBrowserAsync(this.props.navigation.state.params.pdf)

  }
}

handlePrint =async (e) => {
  console.log("print");

  if(Platform.OS === 'ios' || Platform.OS ==='android'){
    console.log("print mobile inactive");

  }else{
      var options = {uri: this.props.navigation.state.params.pdf}
      const response = await Print.printToFileAsync(options);
        console.log("Printing response: ",response);
  }

}

componentWillMount(){
  Dimensions.addEventListener("change", this.handler);
}

componentWillUnmount(){
  Dimensions.removeEventListener("change", this.handler);
}

handler =(e) =>{
  console.log('eee', e);
  this.setState({winHeight: e.window.height})
}

render() {
  return (
    <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>

        <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'75%',width:'100%'}]:[{height:'70%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
          <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>

            {this.state.UserComponent}

            <View style={{width:"95%", alignItems: 'center', justifyContent: 'center'}}>

              <View style={{display:"flex", justifyContent:"space-between", flexDirection:"row",backgroundColor:"#F9F1FF", width:"100%", borderWidth:2, borderColor:"#1D2195" , borderRadius:4 , padding:8}}>
                <View>
                  <Text style={{fontSize:14, color:"#1D2195"}} onPress={this.handleDownload}>Download</Text>
                  <Text style={{fontSize:14, color:"#1D2195"}} onPress={this.handlePrint}>Print</Text>
                </View>

                <View style={{display:"flex", flexDirection:"row"}}>
                  <TouchableOpacity onPress={this.handleDownload}>
                    <ImageBackground source={require('../../../assets/download.png')} style={{width: 43, height:35, marginRight:20}}/>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={this.handlePrint}>
                    <ImageBackground source={require('../../../assets/printer.png')} style={{width: 37, height:35, marginLeft:5 ,marginRight:15}}/>
                  </TouchableOpacity>
                </View>
              </View>

              {
                (this.state.downloading)
                ?
                <View style={{marginTop:50,
                  marginBottom:10,
                  width:"100%",
                  height:48,
                  display:"flex",
                  justifyContent:"center",
                  alignItems:"center"}}>
                  <ActivityIndicator size="large" color="#9126fb" />
                </View>
                :
                <View style={{paddingTop:10}}>
                    <ImageBackground source={this.state.pdfUri} style={{width: 300, height:400, marginRight:20}}/>
                </View>
              }



            </View>
          </View>

        </ScrollView>

      <ScrollView scrollEnabled={false} style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'25%', width:'100%'}]:[{height:'30%', width:'100%', maxWidth:800, minWidth:800}]}
        contentContainerStyle={[global.bottom1, {width:'100%', alignItems:'center', justifyContent:'center'}]}>

        <CustomButton title="Home" type="multiples" color='#F9F1FF' navigation= {this.props.navigation} onPress = {this.generatePdf}/>
      </ScrollView>
    </View>
  );

}
}

const styles = StyleSheet.create({
  buttonContainer:{
    alignItems:'center',
    marginTop:50,
    marginBottom:10,
    width:'90%',
    // justifyContent:'center',

  },
  loginButton:{
    borderRadius:5,

  },
  arrow:{
    fontSize: 20,
  },
  inputField:{
    width:'100%',
    flexDirection:'row',
    height:50,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    marginBottom:8,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'
  },
  textInputStyle:{
    height: 40,
    width:'78%',
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',
  },
  textstyle:{
    alignItems:'center',
    justifyContent:'center',
    color:'#1D2195',
    fontSize:12,
    textAlign:'center'
  },
  CheckBoxTitle:{
    color:"#707070",
    fontSize:14,
    fontWeight:"bold",
    width:"90%"
  },
  CheckBoxContainerStyle:{
    backgroundColor:"transparent",
    borderWidth:0,
    width:"100%"
  },
  descriptionText:{
    marginBottom:10,
    color:'#1D2195',
  },
  buttonContainer:{
    flexDirection:'row',
    alignItems:'center',
    marginBottom:30,
    width:'85%',
    // justifyContent:'center',

  },

})
